import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import Loader from 'Components/Loader'
import Validation from 'Utils/Validation'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'
import { ROUTE_STRINGS, SUBSCRIPTION_PARAMETERS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'

const EditSubscription = ({ setLoading, mockSubscriptionData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const {
    index,
    index: { id }
  } = location.state

  const [subscriptionNameData, setSubscriptionNameData] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [isSubscriptionNameExists, setIsSubscriptionNameExists] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [selectedProcess, setSelectedProcess] = useState('')
  const [showDialog, setShowDialog] = useState({ showModal: false, softAction: '', hardAction: '', modalType: '', modalCancel: false, message: '', errorMessagePostApiFails: false, onHide: () => {}, onSuccess: () => {}, onDismiss: () => {}, onRetry: () => {} })
  const [editSubscription, setEditSubscription] = useState({
    id: '',
    name: '',
    amount: 0,
    basePrice: '',
    idCountry: '102',
    subscriptionCode: '',
    inAppPurchaseId: '',
    amountInDollars: '',
    basePriceForIos: '',
    renewProcess: '',
    subscriptionColor: '',
    orderBy: ''
  })
  const renewProcessOption = [
    {
      processLabel: 'Renew'
    },
    {
      processLabel: 'Upgrade'
    }
  ]

  useEffect(() => {
    setEditSubscription(index)
    setSelectedProcess(renewProcessOption.find((ele) => ele.processLabel === index.renewProcess))

    // For testing purposes
    if (mockSubscriptionData) {
      updateSubscriptionData()
    }
  }, [])

  const getSubscriptionList = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getAllSubscriptionTypes()

      const {
        data: { returnLst: allSubscriptionData }
      } = response

      const subscriptionName = allSubscriptionData.map((item) => ({ id: item.id, name: item.name }))

      setSubscriptionNameData(subscriptionName)
    } catch {
      console.log('Error')
    }
  }

  const updateSubscriptionData = async () => {
    setLoading(true)
    const userDetails = getLocalUserDetails()
    const { id, name, amount, currencyType, subscriptionCode, basePriceForIos, inAppPurchaseId, amountInDollars, renewProcess, subscriptionColor, orderBy, idCountry, basePrice } = editSubscription
    const createdBy = userDetails.id
    const updatedBy = userDetails.id
    const { sgst, cgst, igst } = SUBSCRIPTION_PARAMETERS
    const Data = {
      id,
      name,
      basePrice: parseInt(basePrice, 10),
      renewType: 0,
      isActive: true,
      createdBy,
      createdOn: '2023-04-11T12:35:20.151Z',
      updatedBy,
      updatedOn: '2023-04-11T12:35:20.151Z',
      isTrader: true,
      amount,
      currencyType,
      idCountry,
      qrCodePaymentLink: 'string',
      sgst,
      cgst,
      igst,
      intltax: 0,
      subscriptionCode,
      basePriceForIos,
      inAppPurchaseId,
      taxableAmount: 0,
      amountInDollars,
      renewProcess,
      subscriptionColor,
      orderBy: Number(orderBy)
    }

    try {
      const {
        data: { statusCode }
      } = await apiAdapterCoffeeWeb.putSubscriptionType(Data)

      if (statusCode === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: 'SUBSCRIPTION_DATA_UPDATED_SUCCESSFULLY',
          onSuccess: handleSuccess,
          onHide: handleSuccess
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'SORRY_COULD_NOT_UPDATE_DATA_PLEASE_TRY_AGAIN_LATER',
        onDismiss: handleDismiss,
        onRetry: postRetry,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    setIsButtonDisabled(false)
    if (name === 'name' && (Validation.nameValidation(value) || value === '')) {
      const initialSubscriptionData = subscriptionNameData.filter(({ id: itemId }) => itemId !== id)

      const isSubmitButtonEnabled = initialSubscriptionData.some(({ name }) => name.toLowerCase() === value.toLowerCase())

      setIsSubscriptionNameExists(!isSubmitButtonEnabled)
      setErrorMessage(isSubmitButtonEnabled)
      setEditSubscription({ ...editSubscription, name: value })
    } else if (name === 'amount' && (Validation.phoneNumberValidation(value) || value === '')) {
      setEditSubscription({ ...editSubscription, amount: value })
    } else if (name === 'subscriptionCode' && (Validation.nameValidation(value) || value === '')) {
      setEditSubscription({ ...editSubscription, subscriptionCode: value })
    } else if (name === 'basePriceForIos' && (Validation.phoneNumberValidation(value) || value === '')) {
      setEditSubscription({ ...editSubscription, basePriceForIos: value })
    } else if (name === 'basePrice') {
      const basePriceValue = parseFloat(value)

      if (!Number.isNaN(basePriceValue) || value === '') {
        const basePrice = Number.isNaN(basePriceValue) ? 0 : basePriceValue
        const gstPercentage = 18

        const gstAmount = (basePrice * gstPercentage) / 100
        const totalAmount = basePrice + gstAmount

        setEditSubscription({
          ...editSubscription,
          basePrice: value,
          gst: gstPercentage.toFixed(2),
          amount: totalAmount.toFixed(2)
        })
      } else {
        setEditSubscription({
          ...editSubscription,
          basePrice: '',
          gst: '18.00',
          amount: ''
        })
      }
    } else if (name === 'inAppPurchaseId') {
      setEditSubscription({ ...editSubscription, inAppPurchaseId: value })
    } else if (name === 'amountInDollars' && (Validation.phoneNumberValidation(value) || value === '')) {
      setEditSubscription({ ...editSubscription, amountInDollars: value })
    } else if (name === 'renewProcess') {
      setSelectedProcess(value)
      setEditSubscription({ ...editSubscription, renewProcess: value.processLabel })
    } else if (name === 'orderBy' && (Validation.phoneNumberValidation(value) || value === '')) {
      setEditSubscription({ ...editSubscription, orderBy: value })
    } else if (name === 'subscriptionColor' || value === '') {
      setEditSubscription({ ...editSubscription, subscriptionColor: value })
    } else if (name === 'gst' || value === '') {
      setEditSubscription({ ...editSubscription, gst: value })
    } else if (name === 'currencyType') {
      setEditSubscription({ ...editSubscription, currencyType: value })
    }
  }

  const handleSaveButton = () => {
    updateSubscriptionData()
  }

  const postRetry = () => {
    setShowDialog((previousState) => ({ ...previousState, showModal: false }))
    updateSubscriptionData()
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.getSubscription)
  }

  const onSubmit = (data) => {
    setEditSubscription(data)
  }

  const handleDismiss = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  const handleCancel = () => {
    history.push(ROUTE_STRINGS.getSubscription)
    setEditSubscription(location.state)
  }

  const isButtonValidate = isSubscriptionNameExists === false || isButtonDisabled

  const { name, amount, basePrice, subscriptionCode, basePriceForIos, inAppPurchaseId, amountInDollars, renewProcess, subscriptionColor, orderBy } = editSubscription

  const { name: initialName, amount: initialAmount, basePrice: initialBasePrice, subscriptionCode: initialSubscriptionCode, basePriceForIos: initialBasePriceForIos, inAppPurchaseId: initialInAppPurchaseId, amountInDollars: initialAmountInDollars, renewProcess: initialRenewProcess, subscriptionColor: initialSubscriptionColor, orderBy: initialOrderBy } = index

  const isEqual = (initialValue, updatedValue) => Number(initialValue) === Number(updatedValue)

  const isFieldCheck = initialName === name && isEqual(initialBasePrice, basePrice) && isEqual(initialAmount, amount) && initialSubscriptionCode === subscriptionCode && isEqual(initialBasePriceForIos, basePriceForIos) && initialInAppPurchaseId === inAppPurchaseId && isEqual(initialAmountInDollars, amountInDollars) && initialRenewProcess === renewProcess && initialSubscriptionColor === subscriptionColor && isEqual(initialOrderBy, orderBy)

  const isSubmitButtonDisabled = isFieldCheck || !name || !amount || !basePrice || !subscriptionCode || !basePriceForIos || !inAppPurchaseId || !amountInDollars || !renewProcess || !subscriptionColor || !orderBy

  const validateEmptyCheck = (data) => {
    const errors = {}

    const { name, amount, basePrice, subscriptionCode, basePriceForIos, inAppPurchaseId, amountInDollars, renewProcess, subscriptionColor, orderBy } = data

    if (!name) {
      errors.name = t('SUBSCRIPTION_IS_REQUIRED')
    }
    if (amount !== 0 && !amount) {
      errors.amount = t('AMOUNT_IS_REQUIRED')
    }
    if (!subscriptionCode) {
      errors.subscriptionCode = t('SUBSCRIPTION_CODE_IS_REQUIRED')
    }
    if (basePriceForIos !== 0 && !basePriceForIos) {
      errors.basePriceForIos = t('BASE_PRICE_IS_REQUIRED')
    }
    if (!inAppPurchaseId) {
      errors.inAppPurchaseId = t('APP_PURCHASE_IS_REQUIRED')
    }
    if (amountInDollars !== 0 && !amountInDollars) {
      errors.amountInDollars = t('AMOUNT_IN_DOLLARS_IS_REQUIRED')
    }
    if (!renewProcess) {
      errors.renewProcess = t('RENEW_IS_REQUIRED')
    }
    if (!orderBy) {
      errors.orderBy = t('ORDER_BY_IS_REQUIRED')
    }
    if (!subscriptionColor) {
      errors.subscriptionColor = t('COLOR_IS_REQUIRED')
    }
    if (basePrice !== 0 && !basePrice) {
      errors.basePrice = t('BASE_PRICE_IS_REQUIRED')
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <LayoutContainer title={'EDIT_SUBSCRIPTION'} breadCrumbsTitle={'editSubscription'}>
      <div className="edit-subscription-container" data-testid="edit-subscription-form">
        <Form
          onSubmit={onSubmit}
          initialValues={editSubscription}
          validate={validateEmptyCheck}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="name"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="subscription-name" {...input} value={editSubscription?.name} onChange={handleChange} onFocus={subscriptionNameData.length === 0 ? getSubscriptionList : null} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) || errorMessage ? 'p-invalid-hover' : 'form-fields')} data-testid="subscription" />
                      <label htmlFor="subscription-name" className={classNames(`${isFormFieldValid(meta) || errorMessage ? 'p-error' : 'subscription-label'}`)}>
                        {t('SUBSCRIPTION')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <span>{errorMessage ? <small className="error-message">{t('DATA_ALREADY_EXISTS')}</small> : <div className="error-message">{getFormErrorMessage(meta)}</div>}</span>
                  </div>
                )}
              />

              <Field
                name="subscriptionCode"
                render={({ input, meta }) => (
                  <div className="field">
                    <div>
                      <span className="p-float-label">
                        <InputText id="subscription-code" {...input} value={editSubscription.subscriptionCode} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="subscription-code" />
                        <label htmlFor="subscription-code" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('SUBSCRIPTION_CODE')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  </div>
                )}
              />

              <Field
                name="basePrice"
                render={({ input, meta }) => (
                  <div className="field">
                    <div>
                      <span className="p-float-label">
                        <InputText id="base-price-field" {...input} value={editSubscription.basePrice} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="base-price" />
                        <label htmlFor="base-price-field" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('BASE_PRICE_WITHOUT_GST')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  </div>
                )}
              />

              <Field
                name="amount"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="amount-field" {...input} value={editSubscription.amount} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} disabled data-testid="amount" />
                      <label htmlFor="amount-field" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                        {t('AMOUNT_INCLUDING_GST')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="basePriceForIos"
                render={({ input, meta }) => (
                  <div className="field">
                    <div>
                      <span className="p-float-label">
                        <InputText id="base-price-field" {...input} value={editSubscription.basePriceForIos} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="base-price-ios" />
                        <label htmlFor="base-price-field" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('BASE_PRICE_FOR_IOS')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  </div>
                )}
              />

              <Field
                name="inAppPurchaseId"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="app-purchase-id" {...input} value={editSubscription.inAppPurchaseId} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="app-purchase" />
                      <label htmlFor="app-purchase-id" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                        {t('IN_APP_PURCHASE_ID')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="amountInDollars"
                render={({ input, meta }) => (
                  <div className="field">
                    <div>
                      <span className="p-float-label">
                        <InputText id="amount-in-dollars-id" {...input} value={editSubscription.amountInDollars} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="amount-dollars" />
                        <label htmlFor="amount-in-dollars-id" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('AMOUNT_IN_DOLLARS')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  </div>
                )}
              />

              <Field
                name="renewProcess"
                render={({ input, meta }) => (
                  <div className="field">
                    <div>
                      <span className="p-float-label">
                        <Dropdown id="renew-process" {...input} value={selectedProcess} options={renewProcessOption} optionLabel="processLabel" onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="renew-process" />
                        <label htmlFor="renew-process" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('RENEW_PROCESS')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  </div>
                )}
              />

              <Field
                name="subscriptionColor"
                render={({ input, meta }) => (
                  <div className="field">
                    <div>
                      <span className="p-float-label">
                        <InputText id="subscription-color" {...input} value={editSubscription.subscriptionColor} onChange={handleChange} placeholder={t('#ffffff, #4f4f4f, #f26356')} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="subscription-color" />
                        <label htmlFor="subscription-color" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('SUBSCRIPTION_COLOR')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  </div>
                )}
              />

              <Field
                name="orderBy"
                render={({ input, meta }) => (
                  <div className="field">
                    <div>
                      <span className="p-float-label">
                        <InputText id="order-by" {...input} value={editSubscription.orderBy} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="order-by" />
                        <label htmlFor="order-by" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('ORDER_BY')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  </div>
                )}
              />

              <div className="button-container">
                <ButtonComponent type={'cancel'} onClick={handleCancel} />
                <ButtonComponent type={'submit'} disabled={isButtonValidate || isButtonDisabled || isSubmitButtonDisabled} onClick={handleSaveButton} />
              </div>
            </form>
          )}
        />
      </div>

      <DialogModal {...showDialog}></DialogModal>
    </LayoutContainer>
  )
}

export default Loader(EditSubscription)
