import { ROUTE_STRINGS } from './RouteStrings'

const breadcrumbData = {
  dashBoard: [{ label: 'DASHBOARD' }],
  listMobile: [{ label: 'DASHBOARD', link: '/' }, { label: 'SETTINGS' }, { label: 'MOBILE APP VERSION' }],
  addMobileVersion: [{ label: 'Dashboard', link: '/' }, { label: 'SETTINGS' }, { label: 'MOBILE APP VERSION', link: '/admin/listmobile' }, { label: 'ADD MOBILE VERSION' }],
  listAppSettings: [{ label: 'Dashboard', link: '/' }, { label: 'SETTINGS' }, { label: 'APP CONFIG' }],
  addMapping: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'SCREEN CONTROL MAPPING', link: '/admin/listmapping' }, { label: 'ADD MAPPING' }],
  listMapping: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'SCREEN CONTROL MAPPING' }],
  editMapping: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'SCREEN CONTROL MAPPING', link: '/admin/listmapping' }, { label: 'EDIT MAPPING' }],
  listScreenLanguage: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'SCREEN LANGUAGE MAPPING' }],
  coffeePriceUpload: [{ label: 'Dashboard', link: '/' }, { label: 'UPLOAD PRICE PROCESS' }, { label: 'COFFEE PRICE UPLOAD' }],
  companyApproved: [{ label: 'Dashboard', link: '/' }, { label: 'UPLOAD PRICE PROCESS' }, { label: 'COMPANY APPROVED' }],
  listPaymentMapping: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'PAYMENT COUNTRY MAPPING' }],
  addPaymentMapping: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'PAYMENT COUNTRY MAPPING', link: '/admin/listpaymentcountry' }, { label: 'ADD PAYMENT COUNTRY' }],
  fireBase: [{ label: 'Dashboard', link: '/' }, { label: 'SETTINGS' }, { label: 'FIREBASE CONFIG' }],
  listNewsFeed: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'NEWS FEED' }],
  addNewsFeed: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'NEWS FEED', link: '/admin/listnewsfeed' }, { label: 'ADD NEWS FEED' }],
  editNewsFeed: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'NEWS FEED', link: '/admin/listnewsfeed' }, { label: 'EDIT NEWS FEED' }],
  newsFeedRemark: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'NEWS FEED', link: '/admin/listnewsfeed' }, { label: 'REJECT REMARK PREVIEW' }],
  adminPreview: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'NEWS FEED', link: '/admin/listnewsfeed' }, { label: 'ADMIN PREVIEW' }],
  userPreview: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'NEWS FEED', link: '/admin/listnewsfeed' }, { label: 'USER PREVIEW' }],
  addUser: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'USERS', link: '/admin/listusers' }, { label: 'ADD USERS' }],
  listUser: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'USERS' }],
  editUser: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'USERS', link: '/admin/listusers' }, { label: 'EDIT USERS' }],
  viewUser: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'USERS', link: '/admin/listusers' }, { label: 'VIEW USER DETAILS' }],
  userStats: [{ label: 'Dashboard', link: '/' }, { label: 'REPORTS' }, { label: 'USER STATS' }],
  listLanguage: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'LANGUAGE CONFIG' }],
  editLanguage: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'LANGUAGE CONFIG', link: '/admin/listlanguage' }, { label: 'EDIT LANGUAGE' }],
  addLanguage: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'LANGUAGE CONFIG', link: '/admin/listlanguage' }, { label: 'ADD LANGUAGE' }],
  listMenu: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'MENU CONFIG' }],
  addMenu: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'MENU CONFIG', link: '/admin/listmenu' }, { label: 'ADD ADMIN MENU' }],
  addClientMenu: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'MENU CONFIG', link: '/admin/listmenu' }, { label: 'ADD CLIENT MENU' }],
  editAdminMenu: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'MENU CONFIG', link: '/admin/listmenu' }, { label: 'EDIT ADMIN MENU' }],
  editClientMenu: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'MENU CONFIG', link: '/admin/listmenu' }, { label: 'EDIT CLIENT MENU' }],
  listScreen: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'SCREEN CONFIG' }],
  addScreen: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'SCREEN CONFIG', link: '/admin/listscreen' }, { label: 'ADD SCREEN' }],
  editScreen: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'SCREEN CONFIG', link: '/admin/listscreen' }, { label: 'EDIT SCREEN' }],
  subscriptionType: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'SUBSCRIPTION CONFIG' }],
  subscriptionFeature: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'SUBSCRIPTION FEATURE CONFIG' }],
  addSubscription: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'SUBSCRIPTION CONFIG', link: '/admin/getsubscription' }, { label: 'ADD SUBSCRIPTION' }],
  editSubscription: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'SUBSCRIPTION CONFIG', link: '/admin/getsubscription' }, { label: 'EDIT SUBSCRIPTION' }],
  mapMenu: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'SUBSCRIPTION CONFIG MENU' }],
  listRoles: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'ROLES CONFIG' }],
  addRoles: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'ROLES CONFIG', link: '/admin/listroles' }, { label: 'ADD ROLE' }],
  rolesFeature: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'ROLES CONFIG', link: '/admin/listroles' }, { label: 'ROLE FEATURES' }],
  addPayment: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'PAYMENT CONFIG', link: '/admin/listpayment' }, { label: 'ADD PAYMENT' }],
  editPayment: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'PAYMENT CONFIG', link: '/admin/listpayment' }, { label: 'EDIT PAYMENT' }],
  listPayment: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'PAYMENT CONFIG' }],
  xeData: [{ label: 'Dashboard', link: '/' }, { label: 'XL UPLOAD' }, { label: 'XE DATA' }],
  marketData: [{ label: 'Dashboard', link: '/' }, { label: 'XL UPLOAD' }, { label: 'ICE MARKET DATA' }],
  globalData: [{ label: 'Dashboard', link: '/' }, { label: 'XL UPLOAD' }, { label: 'GLOBAL DATA' }],
  approveGlobalData: [{ label: 'Dashboard', link: '/' }, { label: 'XL UPLOAD' }, { label: 'APPROVE GLOBAL DATA' }],
  approveRawPrice: [{ label: 'Dashboard', link: '/' }, { label: 'XL UPLOAD' }, { label: 'APPROVE GLOBAL DATA' }, { label: 'APPROVE RAW PRICE DATA' }],
  approveDifferentialprice: [{ label: 'Dashboard', link: '/' }, { label: 'XL UPLOAD' }, { label: 'APPROVE GLOBAL DATA' }, { label: 'APPROVE DIFFERENTIAL PRICE DATA' }],
  addSubscriptionPaymentType: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'SUBSCRIPTION CONFIG', link: '/admin/getsubscription' }, { label: 'ADD SUBSCRIPTION PAYMENT TYPE' }],
  listSubscriptionPaymentType: [{ label: 'Dashboard', link: '/' }, { label: 'MAPPING' }, { label: 'SUBSCRIPTION PAYMENT TYPE' }],
  editSubscriptionPaymentType: [{ label: 'Dashboard', link: '/' }, { label: 'MANAGE CONFIG' }, { label: 'SUBSCRIPTION CONFIG', link: '/admin/getsubscription' }, { label: 'EDIT SUBSCRIPTION PAYMENT TYPE' }],
  terminalData: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'TERMINAL DATA' }],
  listTerminalDetails: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'TERMINAL DETAILS' }],
  addTerminal: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'TERMINAL DETAILS', link: '/admin/list-terminal-details' }, { label: 'ADD TERMINAL DETAILS' }],
  editTerminal: [{ label: 'Dashboard', link: '/' }, { label: 'OPERATIONS' }, { label: 'TERMINAL DETAILS', link: '/admin/list-terminal-details' }, { label: 'EDIT TERMINAL DETAILS' }],
  profile: [{ label: 'Dashboard', link: '/' }, { label: 'USER PROFILE' }],
  paymentReport: [{ label: 'Dashboard', link: '/' }, { label: 'REPORTS' }, { label: 'PAYMENT REPORTS' }],
  coffeeQuotesIceMarket: [{ label: 'Dashboard', link: '/' }, { label: 'Coffee Quotes' }, { label: 'ICE MARKET' }],
  reconciledReport: [{ label: 'Dashboard', link: '/' }, { label: 'Reconciled Report' }],
  certifiedStockReport: [{ label: 'Dashboard', link: '/' }, { label: 'COFFEE REPORTS' }, { label: 'CERTIFIED STOCKS REPORTS' }],
  usdaCoffeeReport: [{ label: 'Dashboard', link: '/' }, { label: 'COFFEE REPORTS' }, { label: 'USDA COFFEE REPORT' }],
  addUSDACoffeeReport: [{ label: 'Dashboard', link: '/' }, { label: 'COFFEE REPORTS' }, { label: 'USDA COFFEE REPORT', link: ROUTE_STRINGS.listUSDACoffeeReport }, { label: 'ADD USDA COFFEE REPORT' }],
  editUSDACoffeeReport: [{ label: 'Dashboard', link: '/' }, { label: 'COFFEE REPORTS' }, { label: 'USDA COFFEE REPORT', link: ROUTE_STRINGS.listUSDACoffeeReport }, { label: 'EDIT USDA COFFEE REPORT' }]
}

export default breadcrumbData
