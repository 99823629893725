import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { TABLE_CONST } from 'Utils/Constants'
import coffeeReportJsonLinks from 'Utils/Constants/coffeeReportsJsonLink'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import AddTotalCertifiedStock from './AddTotalCertifiedStock'
import EditTotalCertifiedStock from './EditTotalCertifiedStock'

const TotalCertifiedStock = ({ setLoading }) => {
  const marketName = [
    {
      idMarket: 1,
      marketName: 'Robusta',
      marketCode: 'RC'
    },
    {
      idMarket: 2,
      marketName: 'Arabica',
      marketCode: 'KC'
    }
  ]

  const { t } = useTranslation()
  let rowCount = 8
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const { robustaTotalCertifiedStocks, arabicaTotalCertifiedStocks } = coffeeReportJsonLinks
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [selectedRowData, setSelectedRowData] = useState({})
  const [visible, setVisible] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [fetchedData, setFetchedData] = useState([])
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [selectedValue, setSelectedValue] = useState({
    idMarket: 1,
    marketName: 'Robusta',
    marketCode: 'RC'
  })

  const reportName = selectedValue.idMarket === 1 ? robustaTotalCertifiedStocks : arabicaTotalCertifiedStocks

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData)
    setOpenModal(true)
  }

  useEffect(() => {
    fetchData()
  }, [selectedValue])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const fetchData = async () => {
    handleDismiss()
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data?.details)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => fetchData(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const deleteReportData = async (rowData) => {
    setLoading(true)
    setShowDialog({ ...showDialog, showModal: false })
    const date = rowData.date || rowData.Date
    const { idMarket } = selectedValue

    try {
      const response = await apiAdapterCoffeeWeb.deleteTotalCertifiedStockReport({ fileName: reportName, date: date, MarketId: idMarket })

      if (response.status === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_DELETED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
        setFetchedData(response.data?.details)
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_DELETE_REPORT_DATA'),
        onRetry: () => deleteReportData(rowData),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const deleteConfirmation = (rowData) => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('DELETE_USDA_COFFEE_REPORT'),
      onConfirmation: () => deleteReportData(rowData),
      onHide: handleDismiss
    })
  }

  const actionTemplate = (rowData) => (
    <div className="action-button-wrapper">
      <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditClick(rowData)} />
      <Button type="button" icon="pi pi-trash" className="deleted-button" tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }} onClick={() => deleteConfirmation(rowData)} />
    </div>
  )

  const robustaColumns = [
    {
      dataField: 'date',
      text: t('DATE')
    },

    {
      dataField: 'certifiedStocks',
      text: t('CERTIFIED_STOCKS')
    },

    {
      dataField: 'ComparedToPreDay',
      text: t('COMPARE_TO_PRE_DAY')
    },

    {
      dataField: 'nonTenderable',
      text: t('NON_TENDERABLE')
    },
    {
      dataField: 'suspended',
      text: t('SUSPENDED')
    },
    {
      text: t('ACTIONS'),
      className: 'action-column',
      body: actionTemplate
    }
  ]

  const arabicaColumns = [
    {
      dataField: 'Date',
      text: t('DATE'),
      className: 'date-column'
    },

    {
      dataField: 'certifiedStocks',
      text: t('CERTIFIED_STOCKS'),
      className: 'certified-stock-column'
    },

    {
      dataField: 'ComparedToPreDay',
      text: t('COMPARE_TO_PRE_DAY'),
      className: 'compare-to-pre-column'
    },
    {
      dataField: 'PendingGradingReport',
      text: t('PENDING_GRADING_REPORT'),
      className: 'pending-report-column'
    },
    {
      dataField: 'grading',
      text: t('DAILY_GRADING_SUMMARY'),
      className: 'grading-column'
    },
    {
      text: t('ACTIONS'),
      className: 'actions-column',
      body: actionTemplate
    }
  ]

  const handleChange = (event) => {
    setFetchedData([])

    const value = event.target.value

    setSelectedValue(value)
  }

  const openDialogModal = () => {
    setSelectedRowData({})
    setVisible(true)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  return (
    <>
      <div className="total-certified-stock-report">
        <div className="header-wrapper">
          <div className="dropdown-wrapper">
            <Dropdown value={selectedValue} onChange={handleChange} options={marketName} optionLabel="marketName" placeholder="Select a Market" className="drop-down" />
          </div>
          <div className="add-button">
            <ButtonComponent type={'add'} onClick={openDialogModal} />
          </div>
        </div>

        <div className="table-wrapper">
          {selectedValue.idMarket === 1 ? (
            <DataTable value={fetchedData} paginator={fetchedData?.length} rows={rowCount} pageLinkSize={paginationButtons}>
              {robustaColumns.map((column, index) => (
                <Column key={index} field={column.dataField} header={column.text} className={column.className} body={column.body} />
              ))}
            </DataTable>
          ) : (
            <DataTable value={fetchedData} paginator={fetchedData?.length} rows={rowCount} pageLinkSize={paginationButtons}>
              {arabicaColumns.map((column, index) => (
                <Column key={index} field={column.dataField} header={column.text} className={column.className} body={column.body} />
              ))}
            </DataTable>
          )}
        </div>
        <DialogModal {...showDialog} onDismiss={handleDismiss} />
        <AddTotalCertifiedStock setVisible={setVisible} visible={visible} selectedValue={selectedValue} marketName={marketName} selectedRowData={selectedRowData} reportName={reportName} />

        <EditTotalCertifiedStock openModal={openModal} setOpenModal={setOpenModal} selectedValue={selectedValue} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} reportName={reportName} setFetchedData={setFetchedData} />
      </div>
    </>
  )
}

export default Loader(TotalCertifiedStock)
