import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabPanel, TabView } from 'primereact/tabview'
import LayoutContainer from 'Components/LayoutComponent'
import ProfileInformation from './ProfileInformation'
import AccountSettings from './AccountSettings'
import DeleteAccount from './DeleteAccount'

const EditUser = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const {
    state: {
      rowData: { userType },
      tabUrl
    }
  } = location

  return (
    <LayoutContainer title={tabUrl === 'clients' ? 'VIEW_USER_TITLE' : 'EDIT_USER_TITLE'} breadCrumbsTitle={tabUrl === 'clients' ? 'viewUser' : 'editUser'}>
      <div className="card" data-testid="edit-user-card">
        <div className="user-list-container tab-container">
          <TabView>
            <TabPanel header={t('PROFILE_INFORMATION_TAB')} className="edit-tab-view">
              <ProfileInformation />
            </TabPanel>

            {userType && (
              <TabPanel header={t('ACCOUNT_SETTINGS_TAB')}>
                <AccountSettings />
              </TabPanel>
            )}

            {/* TODO */}
            {/* {userType && (
              <TabPanel header={t('DELETE_ACCOUNT_TAB')}>
                <DeleteAccount />
              </TabPanel>
            )} */}
          </TabView>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default EditUser
