import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { InputSwitch } from 'primereact/inputswitch'
import { InputTextarea } from 'primereact/inputtextarea'
import CountrySelector from 'Components/UIComponents/CountrySelector'
import LayoutContainer from 'Components/LayoutComponent'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ROUTE_STRINGS } from 'Utils/Constants'

const AddUSDACoffeeReport = ({ setLoading, mockUSDACoffeeReportData }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const extraOption = { id: 0, name: t('USDA_COUNTRIES_LABEL'), nicename: t('USDA_COUNTRIES_LABEL'), flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/usda-logo.svg' }

  const [addUSDAReport, setAddUSDAReport] = useState({
    tags: '',
    title: '',
    content: '',
    reportLink: '',
    publishedDate: '',
    formattedDate: '',
    countryId: 102,
    isUpcoming: false
  })
  const [dialogState, setDialogState] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => postUSDACoffeeReportData(), onDismiss: () => handleDismiss(), onSuccess: () => handleSuccess() })
  const { tags, title, content, reportLink, publishedDate, countryId, isUpcoming, formattedDate } = addUSDAReport

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockUSDACoffeeReportData) {
      postUSDACoffeeReportData()
    }
  }, [])

  const isButtonDisabled = !tags || (!title && !isUpcoming) || (!reportLink && !isUpcoming) || !publishedDate || (!content && !isUpcoming)

  const initialFieldDetails = [
    { type: 'input-text', fieldName: 'tags', label: 'TAG', dataTestId: 'tags', errorMessage: 'TAG_IS_REQUIRED', disabled: false },
    { type: 'input-text', fieldName: 'title', label: 'TITLE', dataTestId: 'title', errorMessage: 'TITLE_IS_REQUIRED', disabled: isUpcoming },
    { type: 'input-text', fieldName: 'reportLink', label: 'REPORT_LINK', dataTestId: 'reportLink', errorMessage: 'REPORT_LINK_IS_REQUIRED', disabled: isUpcoming },
    { type: 'text-area', fieldName: 'content', label: 'CONTENT', dataTestId: 'content', errorMessage: 'CONTENT_IS_REQUIRED', disabled: isUpcoming },
    { type: 'calendar', fieldName: 'publishedDate', label: 'PUBLISH_DATE', dataTestId: 'published-date', errorMessage: 'DATE_IS_REQUIRED', disabled: false }
  ]

  const [fieldDetails, setFieldDetails] = useState(initialFieldDetails)

  const postUSDACoffeeReportData = async () => {
    setLoading(true)

    try {
      const { formattedDate, ...updatedPayload } = addUSDAReport
      const response = await apiAdapterCoffeeWeb.postUSDACoffeeReport(updatedPayload)

      if (response.data.isSuccess) {
        setDialogState({
          ...dialogState,
          showModal: true,
          modalType: 'success',
          message: 'POST_USDA_REPORT_DATA_SUCCESS',
          onHide: () => handleSuccess()
        })
      }
    } catch (error) {
      setDialogState({
        ...dialogState,
        showModal: true,
        modalType: 'error',
        message: 'SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER',
        onHide: () => handleDismiss()
      })
    } finally {
      setLoading(false)
    }
  }

  const toggleButton = () => {
    setAddUSDAReport((prev) => ({ ...prev, isUpcoming: !isUpcoming }))
    setFieldDetails((prevDetails) =>
      prevDetails.map((field) => {
        if (['title', 'reportLink', 'content'].includes(field.fieldName)) {
          return { ...field, disabled: !isUpcoming }
        }

        return field
      })
    )
  }

  const handleCountryChange = (countryId) => {
    setAddUSDAReport((previousData) => ({
      ...previousData,
      countryId
    }))
  }

  const validateFields = (name, value) => {
    const modifiedConfig = fieldDetails.map((ele) => (ele.fieldName === name ? { ...ele, error: value === '' } : ele))

    setFieldDetails(modifiedConfig)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setAddUSDAReport((prev) => ({ ...prev, [name]: value }))

    validateFields(name, value)
  }

  const handleDateChange = (event) => {
    const { name, value: currentDate } = event.target

    const date = new Date(currentDate)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    const utcDate = new Date(Date.UTC(year, month, day))

    const isoStringAdjusted = utcDate.toISOString()

    setAddUSDAReport((previousData) => ({
      ...previousData,
      formattedDate: currentDate,
      publishedDate: isoStringAdjusted
    }))

    validateFields(name, currentDate)
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    validateFields(name, value)
  }

  const handleSubmit = () => postUSDACoffeeReportData()

  const handleDismiss = () => setDialogState({ ...dialogState, showModal: false })

  const handleSuccess = () => history.push(ROUTE_STRINGS.listUSDACoffeeReport)

  return (
    <div>
      <LayoutContainer title={'ADD_USDA_COFFEE_REPORT'} breadCrumbsTitle={'addUSDACoffeeReport'}>
        <div className="add-usda-report-container" data-testid="add-usda-form">
          <div className="toggle-button-container">
            <label htmlFor="toggleButton" className="toggle-button">
              {t('IS_UPCOMING')}
            </label>
            <div className="input-switch-wrapper">
              <InputSwitch checked={isUpcoming} onChange={toggleButton} />
            </div>
          </div>
          <div className="field-container">
            <CountrySelector isFormComponent={true} countryId={countryId} selectCurrency={handleCountryChange} extraOption={extraOption} />
          </div>
          {fieldDetails.map(({ fieldName, error, errorMessage, disabled, dataTestId, type, label }, index) => (
            <div className="p-float-label field-container" key={index}>
              {type === 'input-text' && <InputText name={fieldName} id={fieldName} className={`${error && 'mandatory-field'} input-text`} value={addUSDAReport[fieldName]} disabled={disabled} onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} data-testid={dataTestId} />}
              {type === 'text-area' && <InputTextarea name={fieldName} id={fieldName} className={`${error && 'mandatory-field'} input-text-area`} value={addUSDAReport[fieldName]} disabled={disabled} onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} rows={3} placeholder={t('ENTER_REPORT_CONTENT')} data-testid={dataTestId} />}
              {type === 'calendar' && <Calendar name={fieldName} id={fieldName} inputClassName={`${error && 'mandatory-field'} calendar-input-text`} showIcon value={formattedDate} onChange={(e) => handleDateChange(e)} onBlur={(e) => handleBlur(e)} data-testid={dataTestId} />}
              <label className={`${error ? `${type} mandatory-field-label` : `${type} field-label`}`} htmlFor={fieldName}>
                {t(label)}
                <span className="required-field">*</span>
              </label>
              {error && <div className="error-message">{t(errorMessage)}</div>}
            </div>
          ))}

          <div className="button-container">
            <ButtonComponent type={'submit'} disabled={isButtonDisabled} onClick={handleSubmit} />
          </div>
        </div>

        <DialogModal {...dialogState} />
      </LayoutContainer>
    </div>
  )
}

export default Loader(AddUSDACoffeeReport)
