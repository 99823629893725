export const commonMethods = {
  utcToLocal(dateString) {
    const date = new Date(dateString)

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }

    return date.toLocaleString('en-US', options)
  },
  utcToLocalTime(dateString) {
    const date = new Date(dateString)

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }

    return date.toLocaleString('en-US', options)
  },
  utcToLocalOnlyTime(dateString) {
    const date = new Date(dateString)

    const options = {
      hour: 'numeric',
      minute: 'numeric'
    }

    return date.toLocaleString('en-US', options)
  },
  utcToLocalDate(dateString) {
    const date = new Date(dateString)

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }

    return date.toLocaleString('en-US', options)
  },
  utcToLocalShortDate(dateString) {
    const date = new Date(dateString)

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }

    return date.toLocaleString('en-US', options)
  },
  handleRetry() {
    window.location.reload()
  },

  getOptionExpiryColor(optionExpiryStatus) {
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  },

  getFirstNoticeDayColor(optionExpiryStatus) {
    if (optionExpiryStatus === 'boldAndBlack') return 'bold_and_black'
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  },

  parseCustomDateFormat(dateString) {
    const months = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11
    }

    const parts = dateString.match(/(\w+) (\d+), (\d+) at (\d+):(\d+) (\w+)/)

    if (parts) {
      const [, month, day, year, hour, minute, period] = parts

      const monthIndex = months[month]
      let hour24 = parseInt(hour, 10)

      if (period === 'PM' && hour24 < 12) hour24 += 12
      if (period === 'AM' && hour24 === 12) hour24 = 0

      return new Date(year, monthIndex, parseInt(day, 10), hour24, parseInt(minute, 10))
    }

    return new Date(dateString)
  }
}
