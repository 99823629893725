import './styles.scss'
import React, { useState, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { SelectButton } from 'primereact/selectbutton'
import { Checkbox } from 'primereact/checkbox'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import DialogModal from 'Components/UIComponents/DialogModal'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import { ButtonComponent } from 'Components/UIComponents'

const AccountSettings = ({ setLoading }) => {
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { id, userType } = location?.state?.rowData || {}

  const [accountState, setAccountState] = useState({
    dateFormat: [],
    timeFormat: [],
    landingPageData: [],
    languages: [],
    timeZone: [],
    userDateFormat: null,
    userTimeFormat: null,
    userLanguage: null,
    userLandingPageUrl: null,
    userTimeZone: null,
    updateFor: null,
    showMobileNotification: false,
    showWebNotification: false
  })

  const [modalState, setModalState] = useState({
    modalType: '',
    message: '',
    softAction: '',
    hardAction: '',
    showModal: false,
    onSuccess: () => {},
    onDismiss: () => {},
    onHide: () => {},
    onRetry: () => {}
  })

  const deviceArray = [
    {
      deviceType: 'Mobile'
    },
    {
      deviceType: 'Browser'
    }
  ]

  const { dateFormat, timeFormat, landingPageData, timeZone, languages, userDateFormat, userTimeFormat, userLanguage, userLandingPageUrl, userTimeZone, updateFor, showMobileNotification, showWebNotification } = accountState

  useEffect(() => {
    userSettingsData()
  }, [])

  const userSettingsData = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getProfileSettingMasters()
      const { landingpage, dateFormat, timeFormat, languages, timeZone } = response?.data || {}

      setAccountState((prevState) => ({
        ...prevState,
        landingPageData: landingpage,
        dateFormat,
        timeFormat,
        languages,
        timeZone
      }))

      fetchUserDataAndSettings(timeZone, dateFormat, timeFormat, landingpage, languages)
    } catch {
      setModalState((previousState) => ({ ...previousState, modalType: 'error', message: 'NO_DATA_FOUND', showModal: true, onHide: handleDismiss, onRetry: handleGetRetry }))
    } finally {
      setLoading(false)
    }
  }

  const fetchUserDataAndSettings = async (userTimeZoneArray, userDateFormatArray, userTimeFormatArray, userLandingPageArray, userLanguagesArray) => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getUserByUserId(id)

      let { userDateFormat, userTimeFormat, userLanguage, userLandingPageUrl, idtimezone: userTimeZone } = response.data
      const { showMobileNotification, showWebNotification } = response.data

      userDateFormat = userDateFormatArray.find(({ dateFormat }) => dateFormat === userDateFormat)
      userTimeFormat = userTimeFormatArray.find(({ timeFormat }) => timeFormat === userTimeFormat)
      userLanguage = userLanguagesArray.find(({ languageName }) => languageName === userLanguage)
      userLandingPageUrl = userLandingPageArray.find(({ webAppurl }) => webAppurl === userLandingPageUrl)
      userTimeZone = userTimeZoneArray.find(({ idTimeZone }) => idTimeZone === userTimeZone)
      setAccountState((prevState) => ({
        ...prevState,
        userDateFormat,
        userTimeFormat,
        userLanguage,
        userLandingPageUrl,
        showMobileNotification,
        showWebNotification,
        userTimeZone
      }))
    } catch {
      setModalState((previousState) => ({ ...previousState, modalType: 'error', message: 'NO_DATA_FOUND', showModal: true, onHide: handleDismiss, onRetry: handleGetRetry }))
    } finally {
      setLoading(false)
    }
  }

  const isSubmitButtonDisabled = !userDateFormat || !userLandingPageUrl || !userLanguage || !userTimeFormat || !userTimeZone || !updateFor

  const handleAccountSettingsChange = (e) => {
    const { name, value } = e.target

    setAccountState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSaveAction = async () => {
    setLoading(true)
    const postData = {
      userid: id,
      UserDateFormat: userDateFormat.dateFormat,
      UserTimeFormat: userTimeFormat.timeFormat,
      UserLanguage: userLanguage.idLanguage,
      UserLandingPageUrl: userLandingPageUrl.webAppurl,
      UserTimeZone: +userTimeZone.idTimeZone,
      UpdateFrom: updateFor.deviceType,
      ShowWebNotification: showWebNotification,
      ShowMobileNotification: showMobileNotification
    }

    try {
      const response = await apiAdapterCoffeeWeb.updateUserProfileSettings(postData)

      if (response.data) {
        setModalState((previousState) => ({ ...previousState, modalType: 'success', message: 'UPDATE_USER_ACCOUNT_SETTINGS_SUCCESS', showModal: true, softAction: 'OK', onHide: handleSuccess }))
      }
    } catch {
      setModalState((previousState) => ({ ...previousState, modalType: 'error', message: 'UPDATE_USER_ACCOUNT_SETTINGS_FAILED', showModal: true, onHide: handleDismiss, onRetry: handlePostRetry }))
    } finally {
      setLoading(false)
    }
  }

  const handleSuccess = () => {
    setModalState({ ...modalState, showModal: false })
    history.push(ROUTE_STRINGS.listUsers)
  }

  const handleDismiss = () => {
    setModalState((previousState) => ({ ...previousState, showModal: false }))
    if (modalState.modalType === 'success') {
      setModalState({ ...modalState, showModal: false })
      history.push(ROUTE_STRINGS.listUsers)
    }
  }

  const handleGetRetry = () => {
    commonMethods.handleRetry
  }

  const handlePostRetry = () => {
    setModalState((previousState) => ({ ...previousState, showModal: false }))
    setLoading(true)
    handleSaveAction()
  }
  const onSubmit = (form) => {
    form.restart()
  }

  const validate = () => {
    const errors = {}

    if (!accountState.userDateFormat) {
      errors.userDateFormat = t('DATE_FORMAT_IS_REQUIRED')
    }

    if (!accountState.userLanguage) {
      errors.userLanguage = t('LANGUAGE_IS_REQUIRED')
    }

    if (!accountState.userTimeZone) {
      errors.userTimeZone = t('TIME_ZONE_IS_REQUIRED')
    }
    if (!accountState.userLandingPageUrl) {
      errors.userLandingPageUrl = t('LANDING_PAGE_IS_REQUIRED')
    }

    return errors
  }
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error error-message">{meta.error}</small>

  return (
    <div className="account-settings-container" data-testid="account-settings-container">
      <div className="account-settings-card">
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="userDateFormat"
                render={({ input, meta }) => (
                  <div className="date-time-wrapper">
                    <div className={`date-format p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown disabled {...input} id="dateFormat" onChange={(event) => handleAccountSettingsChange(event)} value={userDateFormat} className={classNames('profile-information-fields', { 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : '')} placeholder={t('SELECT_DATE_FORMAT')} options={dateFormat} optionLabel="dateFormat" data-testid="date-format" />
                      <label htmlFor="dateFormat" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'account-settings-label'}`)}>
                        {t('DATE_TIME_LABEL')}
                        <span className="required-field">*</span>
                      </label>
                      <div>{getFormErrorMessage(meta)}</div>
                    </div>
                    <div className="select-button-container">
                      <SelectButton disabled name="userTimeFormat" unselectable={false} value={userTimeFormat} onChange={(e) => handleAccountSettingsChange(e)} options={timeFormat} optionLabel="timeFormat" data-testid="time-format" />
                    </div>
                  </div>
                )}
              />

              <Field
                name="userLanguage"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown disabled {...input} onChange={(event) => handleAccountSettingsChange(event)} value={userLanguage} className={`profile-information-fields ${isFormFieldValid(meta) ? 'p-invalid' : ''} ${isFormFieldValid(meta) ? 'p-invalid-hover' : ''}`} id="dateFormat" placeholder={t('SELECT_LANGUAGE')} options={languages} optionLabel="languageName" data-testid="language-dropdown" />
                      <label htmlFor="dateFormat" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'account-settings-label'}`)}>
                        {t('LANGUAGE')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <Field
                name="userLandingPageUrl"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown disabled {...input} onChange={(event) => handleAccountSettingsChange(event)} value={userLandingPageUrl} className={`profile-information-fields ${isFormFieldValid(meta) ? 'p-invalid' : ''} ${isFormFieldValid(meta) ? 'p-invalid-hover' : ''}`} id="dateFormat" placeholder={t('SELECT_LANDING_PAGE')} options={landingPageData} optionLabel="landingpage" data-testid="landing-dropdown" />
                      <label htmlFor="dateFormat" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'account-settings-label'}`)}>
                        {t('LANDING_PAGE')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
              <Field
                name="userTimeZone"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown disabled {...input} id="dateFormat" onChange={(event) => handleAccountSettingsChange(event)} value={userTimeZone} className={`profile-information-fields ${isFormFieldValid(meta) ? 'p-invalid' : ''} ${isFormFieldValid(meta) ? 'p-invalid-hover' : ''}`} placeholder={t('SELECT_TIME_ZONE')} options={timeZone} optionLabel="timeZone" data-testid="time-zone-dropdown" />
                      <label htmlFor="dateFormat" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'account-settings-label'}`)}>
                        {t('TIME_ZONE')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <div className="device-type-container ">
                <label className="device-type-label">
                  {t('DEVICE_DETAILS_MOBILE_BROWSER')}
                  <span className="required-field">*</span>
                </label>
                <SelectButton disabled name="updateFor" unselectable={false} value={updateFor} onChange={(e) => handleAccountSettingsChange(e)} options={deviceArray} optionLabel="deviceType" data-testid="device-type" />
              </div>
              <div className="notification-checkbox-wrapper">
                {t('ENABLE_NOTIFICATION_FOR_DEVICES')}
                <div>
                  <Checkbox disabled inputId="mobileNotification" name="showMobileNotification" value={!showMobileNotification} onChange={handleAccountSettingsChange} checked={showMobileNotification} />
                  <label htmlFor="mobileNotification">{t('MOBILE_LABEL')}</label>
                </div>
                <div>
                  <Checkbox disabled inputId="browserNotification" name="showWebNotification" value={!showWebNotification} onChange={handleAccountSettingsChange} checked={showWebNotification} />
                  <label htmlFor="browserNotification">{t('BROWSER_LABEL')}</label>
                </div>
              </div>
            </form>
          )}
        />

        {userType === 0 && (
          <div className="button-container">
            <ButtonComponent type="submit" onClick={handleSaveAction} disabled={isSubmitButtonDisabled} />
          </div>
        )}
      </div>
      <DialogModal {...modalState} onSuccess={handleSuccess} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(AccountSettings)
