import './styles.scss'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'primereact/calendar'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'

const ReconcileForm = (props) => {
  const { t } = useTranslation()
  const { setLoading, visible, setVisible, selectedRowData, onReconcileSuccess } = props
  const { invoiceNumber, totalAmount, transactionId, paymentDate, paymentSource } = selectedRowData

  const GstCalculationAmount = totalAmount

  const [formState, setFormState] = useState({
    tallyDate: null,
    utrNo: '',
    transactionDate: null,
    dateAndTime: null,
    settlementNo: '',
    grossAmount: GstCalculationAmount,
    deductions: 0,
    netAmount: '',
    fee: 0,
    tax: 0
  })

  const [reconcileOnSubmitDialog, setReconcileOnSubmitDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onConfirmation: () => {} })
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  const { tallyDate, utrNo, transactionDate, settlementNo, grossAmount, deductions, netAmount, dateAndTime, tax, fee } = formState

  const deductionsAmount = (parseFloat(fee) || 0) + (parseFloat(tax) || 0)
  const grossAmountCalculation = totalAmount - deductionsAmount
  const netAmountCalculation = grossAmountCalculation + deductionsAmount

  useEffect(() => {
    const gross = parseFloat(GstCalculationAmount) || 0
    const deduct = parseFloat(deductions) || 0

    setFormState((prevState) => ({
      ...prevState,
      netAmount: gross - deduct
    }))
  }, [GstCalculationAmount, deductionsAmount])

  useEffect(() => {
    if (selectedRowData) {
      setFormState({
        tallyDate: null,
        utrNo: '',
        transactionDate: null,
        settlementNo: '',
        grossAmount: GstCalculationAmount,
        deductions: 0,
        netAmount: '',
        fee: 0,
        tax: 0
      })
    }
  }, [selectedRowData])

  const formatDate = (dateStr) => {
    const dateObj = moment(dateStr, 'MMMM D, YYYY [at] h:mm A')

    return dateObj.toISOString()
  }

  const handleFieldChange = (name, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleFormSubmission = async () => {
    setReconcileOnSubmitDialog({ ...reconcileOnSubmitDialog, showModal: false })

    const postData = {
      invoiceNumber,
      amount: GstCalculationAmount,
      paymentId: transactionId,
      paymentDate: formatDate(paymentDate),
      paymentSource,
      tallyDate,
      utrNo,
      settlementNo,
      transactionDate,
      grossAmount: parseFloat(grossAmountCalculation),
      deductions: parseFloat(deductionsAmount),
      netAmount: parseFloat(netAmountCalculation),
      taxAmount: tax,
      feeAmount: fee,
      dateAndTime
    }

    try {
      const response = await apiAdapterCoffeeWeb.postReconcileFormData(postData)

      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'success',
        message: response?.data?.statusMessage,
        onDismiss: handleDismiss,
        onSuccess: handleDismiss
      })
      onReconcileSuccess(selectedRowData?.idOrder)
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: error?.response?.data?.statusMessage,
        onRetry: handleRetry
      })
    } finally {
      setLoading(false)
      setVisible(false)
      setFormState({
        tallyDate: null,
        utrNo: '',
        transactionDate: null,
        settlementNo: '',
        grossAmount: GstCalculationAmount,

        deductions: 0,
        netAmount: '',
        tax: 0,
        fee: 0
      })
    }
  }

  const handleDismissOnSubmit = () => setReconcileOnSubmitDialog((prevState) => ({ ...prevState, showModal: false }))

  const handleDismiss = () => setShowDialog({ ...showDialog, showModal: false })

  const handleRetry = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    handleFormSubmission()
  }

  const handleReconcileSubmission = () => {
    setReconcileOnSubmitDialog({
      ...reconcileOnSubmitDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onDismiss: handleDismissOnSubmit,
      onHide: handleDismissOnSubmit,
      onConfirmation: handleFormSubmission
    })
  }

  const handleCancel = () => {
    setFormState({
      tallyDate: null,
      utrNo: '',
      transactionDate: null,
      settlementNo: '',
      grossAmount: GstCalculationAmount,
      deductions: 0,
      netAmount: '',
      dateAndTime: null,
      tax: 0,
      fee: 0
    })
    setVisible(false)
  }

  const onSubmit = (data, form) => {
    setFormState(data)
    form.restart()
  }

  const validate = (values) => {
    const errors = {}

    if (!values.tallyDate) {
      errors.tallyDate = t('TALLY_DATE_IS_REQUIRED')
    }
    if (!values.utrNo) {
      errors.utrNo = t('UTR_NO_IS_REQUIRED')
    }
    if (!values.settlementNo) {
      errors.settlementNo = t('SETTLEMENT_NO_IS_REQUIRED')
    }
    if (!values.transactionDate) {
      errors.transactionDate = t('TRANSACTION_NO_IS_REQUIRED')
    }
    if (!values.grossAmount) {
      errors.grossAmount = t('GROSS_AMOUNT_IS_REQUIRED')
    }
    if (!values.dateAndTime) {
      errors.dateAndTime = t('DATE_AND_TIME_IS_REQUIRED')
    }
    if (!values.tax) {
      errors.tax = t('TAX_IS_REQUIRED')
    }
    if (!values.fee) {
      errors.fee = t('FEE_IS_REQUIRED')
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const isButtonDisabled = !tallyDate || !utrNo || !transactionDate || !settlementNo || !fee || !tax || !dateAndTime

  return (
    <>
      <Dialog header={t('RECONCILE_FORM')} visible={visible} className="reconciler-dialog-container" onHide={handleCancel}>
        <div className="flex justify-content-center">
          <div className="reconcile-form">
            <Form
              onSubmit={onSubmit}
              initialValues={formState}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="p-fluid">
                  <div className="form-input">
                    <Field
                      name="totalAmount"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="totalAmount" {...input} value={totalAmount} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} disabled />
                            <label htmlFor="totalAmount" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('AMOUNT')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="invoiceNumber"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="invoiceNumber" {...input} value={invoiceNumber} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} disabled />
                            <label htmlFor="invoiceNumber" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('INVOICE_NUMBER')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-input">
                    <Field
                      name="paymentId"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="paymentId" {...input} value={transactionId} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} disabled />
                            <label htmlFor="paymentId" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('PAYMENT_ID')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="paymentDate"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="paymentDate" {...input} value={paymentDate} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} disabled />
                            <label htmlFor="paymentDate" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('PAYMENT_DATE')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-input">
                    <Field
                      name="paymentSource"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="paymentSource" {...input} value={paymentSource} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} disabled />
                            <label htmlFor="paymentSource" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('PAYMENT_SOURCE')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="tallyDate"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <Calendar id="tallyDate" {...input} value={tallyDate} onChange={(e) => handleFieldChange('tallyDate', e.value)} showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                            <label htmlFor="tallyDate" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('TALLY_DATE')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-input">
                    <Field
                      name="utrNo"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="utrNo" {...input} value={utrNo} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} onChange={(e) => handleFieldChange('utrNo', e.target.value)} />
                            <label htmlFor="utrNo" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('UTR_NO')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="transactionDate"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <Calendar id="transactionDate" {...input} value={transactionDate} onChange={(e) => handleFieldChange('transactionDate', e.value)} showIcon showTime hourFormat="12" className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                            <label htmlFor="transactionDate" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('TRANSACTION_DATE')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>

                  <div className="form-input">
                    <Field
                      name="dateAndTime"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <Calendar id="dateAndTime" {...input} value={dateAndTime} onChange={(e) => handleFieldChange('dateAndTime', e.value)} showIcon showTime hourFormat="12" className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                            <label htmlFor="dateAndTime" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('DATE_AND_TIME')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="settlementNo"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="settlementNo" {...input} value={settlementNo} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} onChange={(e) => handleFieldChange('settlementNo', e.target.value)} />
                            <label htmlFor="settlementNo" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('SETTLEMENT_NO')}
                              <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-input">
                    <Field
                      name="tax"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="tax" {...input} keyfilter="num" value={tax} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} onChange={(e) => handleFieldChange('tax', e.target.value)} />
                            <label htmlFor="tax" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('TAX')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="fee"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="fee" {...input} keyfilter="num" value={fee} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} onChange={(e) => handleFieldChange('fee', e.target.value)} />
                            <label htmlFor="fee" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('FEE')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-input">
                    <Field
                      name="grossAmount"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="grossAmount" {...input} keyfilter="num" value={grossAmountCalculation} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} onChange={(e) => handleFieldChange('grossAmount', e.target.value)} />
                            <label htmlFor="grossAmount" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('GROSS_AMOUNT')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="deductions"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="deductions" {...input} keyfilter="num" value={deductionsAmount} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} onChange={(e) => handleFieldChange('deductions', e.target.value)} disabled />
                            <label htmlFor="deductions" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('DEDUCTIONS')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-input">
                    <Field
                      name="netAmount"
                      render={({ input, meta }) => (
                        <div className="field">
                          <span className="p-float-label">
                            <InputText id="netAmount" {...input} keyfilter="num" value={netAmountCalculation} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} onChange={(e) => handleFieldChange('netAmount', e.target.value)} disabled />
                            <label htmlFor="netAmount" className={classNames({ 'p-error': isFormFieldValid(meta), 'mapping-label': true })}>
                              {t('NET_AMOUNT')} <span className="required-field">*</span>
                            </label>
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>
                  <div className="button-container">
                    <ButtonComponent type={'Submit'} variant="action" onClick={handleReconcileSubmission} disabled={isButtonDisabled} />
                    <ButtonComponent type={'cancel'} onClick={handleCancel} />
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Dialog>
      <DialogModal {...reconcileOnSubmitDialog} onDismiss={handleDismissOnSubmit} onHide={handleDismissOnSubmit} />
      <DialogModal {...showDialog} onDismiss={handleDismiss} onHide={handleDismiss} />
    </>
  )
}

export default Loader(ReconcileForm)
