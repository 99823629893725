import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { commonMethods } from 'Utils/commonMethods'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const CountrySelector = ({ setLoading, selectCurrency, countryId, isFormComponent, extraOption }) => {
  const { t } = useTranslation()
  const [currentItem, setCurrentItem] = useState(null)
  const [currencyList, setCurrencyList] = useState([])
  const [countrySelectorError, setCountrySelectorError] = useState(false)
  const [onDismissError, setOnDismissError] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', modalCancel: '', onHide: () => {} })

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    const currentUrl = window.location.pathname.split('/', 3).pop()
    const formsList = ['approveglobaldata', 'addpaymentcountry', 'addlanguage', 'addsubscription', 'editsubscription', 'editlanguage', 'user-stats', 'add-usda-coffee-report', 'edit-usda-coffee-report']

    if (formsList.includes(currentUrl)) {
      getCountryAndCurrencyList()
    }
  }

  const getCountryAndCurrencyList = async () => {
    setLoading(true)
    try {
      const { data: countryResponse } = await apiAdapterCoffeeWeb.getAllCountries()

      const updatedCountryResponse = extraOption ? [extraOption, ...countryResponse] : countryResponse

      setCurrencyList(updatedCountryResponse)
      const item = countryResponse.find(({ id }) => id === countryId)

      setCurrentItem(item)

      setCountrySelectorError(false)
      setOnDismissError(false)
    } catch {
      setCountrySelectorError(true)

      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        modalCancel: false,
        onHide: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCurrencyChange = (id) => {
    const item = currencyList.find((element) => element?.id === id)

    selectCurrency(id, item)
    setCurrentItem(item)
  }

  const handleDismiss = () => {
    setOnDismissError(true)
    setShowDialog({ ...showDialog, showModal: false })
  }

  return (
    <div className="country-selector-dropdown-container">
      <span className="p-float-label">
        <Dropdown
          value={currentItem?.id}
          options={currencyList}
          optionLabel="name"
          optionValue="id"
          onChange={(e) => handleCurrencyChange(e.value)}
          id="countrySelector"
          className={isFormComponent ? 'form-component' : 'country-selector-dropdown'}
          filter
          itemTemplate={(option) => (
            <div className="dropdown-selector">
              <img src={option?.flagsUrl} alt="flag" className="flag-icon" />
              <div className="currency-details">
                <div className="currency-code">{option?.name}</div>
              </div>
            </div>
          )}
          valueTemplate={(selectedOption) => (
            <div className="dropdown-selector">
              <img src={selectedOption?.flagsUrl} alt="flag" className="flag-icon" />
              <div className="currency-details">
                <div className="currency-code">{selectedOption?.name}</div>
              </div>
            </div>
          )}
          disabled={countrySelectorError || onDismissError}
          data-testid="country-selector"
        />
        {countrySelectorError || onDismissError ? <div className="error-message-country">{t('COUNTRY_FETCH_FAILED')}</div> : null}
      </span>
      <DialogModal {...showDialog} onDismiss={handleDismiss} onRetry={commonMethods.handleRetry} />
    </div>
  )
}

export default Loader(CountrySelector)
