import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import AddPortWiseStockRobusta from './Components/AddPortWiseStockRobusta'
import AddPortWiseStockReportArabica from './Components/AddPortWiseStockArabica'

const AddPortWiseStockReport = ({ showDialog, setShowDialog, selectedMarket, portData, reportName, marketName, coffeeReportData }) => {
  const { t } = useTranslation()
  const { idMarket } = selectedMarket
  const [selectedDate, setSelectedDate] = useState(null)
  const [chosenDate, setChosenDate] = useState(null)
  const [selectedPort, setSelectedPort] = useState({})
  const [dateValue, setDateValue] = useState(null)
  const [selectedOrigin, setSelectedOrigin] = useState({})
  const [filteredOptionsCount, setFilteredOptionsCount] = useState(0)
  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [formData, setFormData] = useState([
    {
      valid: '',
      comparedToPreDay: '',
      nonTenderable: '',
      suspended: '',
      port: ''
    }
  ])

  const [validateField, setValidateField] = useState({
    valid: false,
    comparedToPreDay: false,
    nonTenderable: false,
    suspended: false,
    port: false
  })

  const [inputFields, setInputFields] = useState([
    {
      country: '',
      antwerp: '',
      barcelona: '',
      hamburg: '',
      houston: '',
      miami: '',
      newOrleans: '',
      newYork: '',
      virginia: '',
      compareToPreDay: ''
    }
  ])

  const [validatedInputs, setValidatedInputs] = useState({
    date: false,
    country: false,
    antwerp: false,
    barcelona: false,
    hamburg: false,
    houston: false,
    miami: false,
    newOrleans: false,
    newYork: false,
    virginia: false,
    compareToPreDay: false
  })

  useEffect(() => {
    if (showDialog) {
      setValidateField((prev) => ({
        ...prev,
        port: false
      }))
    }
  }, [showDialog])

  const insertPortWiseData = async () => {
    handleDismiss()

    const postDataArabica = inputFields.map((field) => ({
      country: field.country,
      filename: reportName,
      report: [
        {
          date: dateValue,
          antwerp: field.antwerp,
          barcelona: field.barcelona,
          hamburgBremen: field.hamburg,
          houston: field.houston,
          miami: field.miami,
          newOrleans: field.newOrleans,
          newYork: field.newYork,
          virginia: field.virginia,
          comparedPrevDay: field.compareToPreDay
        }
      ]
    }))

    const postDataRoubsta = {
      filename: reportName,
      date: chosenDate,
      reportDetails: formData
    }

    try {
      let response

      if (idMarket === 1) {
        response = await apiAdapterCoffeeWeb.postPortWiseStockReportRobusta(postDataRoubsta)
      } else {
        response = await apiAdapterCoffeeWeb.postPortWiseCertifiedDataForArabica(postDataArabica)
      }

      if (response.status === 200) {
        setErrorModal({
          ...errorModal,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_ADDED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
        setShowDialog(false)
        resetFromData()
      }
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: insertPortWiseData,
        onHide: handleDismiss
      })
    }
  }

  const closeDialog = () => {
    setShowDialog()
    resetFromData()
  }

  const resetFromData = () => {
    if (idMarket === 1) {
      setFormData([
        {
          valid: '',
          comparedToPreDay: '',
          nonTenderable: '',
          suspended: '',
          port: ''
        }
      ])

      setValidateField({
        valid: false,
        comparedToPreDay: false,
        nonTenderable: false,
        suspended: false,
        port: false
      })
      setSelectedPort({})
      setSelectedDate(null)
    } else {
      setInputFields([
        {
          slNo: '',
          country: '',
          antwerp: '',
          barcelona: '',
          hamburg: '',
          houston: '',
          miami: '',
          newOrleans: '',
          newYork: '',
          virginia: '',
          compareToPreDay: ''
        }
      ])
      setValidatedInputs({
        date: false,
        country: false,
        antwerp: false,
        barcelona: false,
        hamburg: false,
        houston: false,
        miami: false,
        newOrleans: false,
        newYork: false,
        virginia: false,
        compareToPreDay: false
      })
      setSelectedOrigin({})
      setSelectedDate(null)
    }
  }

  const handleDismiss = () => {
    setErrorModal({ ...errorModal, showModal: false })
  }

  const areFieldsValid = () => {
    let disable

    if (idMarket === 1) {
      disable = formData.every((item) => item.valid && item.comparedToPreDay && item.nonTenderable && item.suspended && item.port && selectedDate)
    } else {
      disable = inputFields.every((item) => item.country && item.antwerp && item.barcelona && item.hamburg && item.houston && item.miami && item.newOrleans && item.newYork && item.virginia && item.compareToPreDay && selectedDate)
    }

    return disable
  }

  const footerContent = () => (
    <div className="footer-button">
      <ButtonComponent type="cancel" onClick={closeDialog} />
      <ButtonComponent type="submit" onClick={insertPortWiseData} disabled={filteredOptionsCount !== 1 || !areFieldsValid()} />
    </div>
  )

  return (
    <div className="add-port-wise-data-container">
      <Dialog visible={showDialog} header={`${t('PORT_WISE_STOCK_REPORT')} - ${selectedMarket.marketName}`} footer={footerContent} onHide={closeDialog} className={`port-wise-dialog-modal ${idMarket === 1 ? 'robusta-form' : 'arabica-form'}`}>
        {idMarket === 1 && <AddPortWiseStockRobusta portData={portData} formData={formData} setFormData={setFormData} setValidateField={setValidateField} validateField={validateField} selectedDate={selectedDate} setSelectedDate={setSelectedDate} selectedPort={selectedPort} setSelectedPort={setSelectedPort} setFilteredOptionsCount={setFilteredOptionsCount} filteredOptionsCount={filteredOptionsCount} setChosenDate={setChosenDate} />}
        {idMarket === 2 && <AddPortWiseStockReportArabica selectedMarket={selectedMarket} marketName={marketName} setInputFields={setInputFields} inputFields={inputFields} setDateValue={setDateValue} validatedInputs={validatedInputs} setValidatedInputs={setValidatedInputs} setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedOrigin={setSelectedOrigin} selectedOrigin={selectedOrigin} setFilteredOptionsCount={setFilteredOptionsCount} filteredOptionsCount={filteredOptionsCount} coffeeReportData={coffeeReportData} />}
      </Dialog>

      <DialogModal {...errorModal} onDismiss={handleDismiss} />
    </div>
  )
}

export default AddPortWiseStockReport
