import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <div className="footer">
      <div className="footer-container">
        <span>
          {t('COPYRIGHT')} <b>{t('COFFEE_WEB')}</b>. {t('ALL_RIGHTS_RESERVED')}
        </span>
      </div>
    </div>
  )
}

export default Footer
