import './styles.scss'
import Variable from 'theme.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react'
import moment from 'moment'
import { InputTextarea } from 'primereact/inputtextarea'
import { MultiSelect } from 'primereact/multiselect'
import { FileUpload } from 'primereact/fileupload'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Image } from 'primereact/image'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { COLOR_CONSTANTS, REG_EX, ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import Validation from 'Utils/Validation'
import { ButtonComponent } from 'Components/UIComponents'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import ModalComponent from 'Screens/NewsFeed/Components/AddNewsFeedModal'

const AddNewsFeed = ({ setLoading, addNewsFeedMockApiFlag, mockSubscriptionType, mockSubscriptionLockType, mockModalOpen }) => {
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const tinyEditorRef = useRef(null)
  const imageDivHeight = useRef(null)
  const params = new URLSearchParams()
  const currentTimeInUserTimeZone = new Date()
  const currentTime = moment(currentTimeInUserTimeZone).format('YYYY-MM-DDTHH:mm:ss')
  const userDetails = getLocalUserDetails()
  const [imageContainerHeight, setImageContainerHeight] = useState(0)
  const [subscriptionLockType, setSubscriptionLockType] = useState([])
  const [subscriptionType, setSubscriptionType] = useState([])
  const [newsFeedMedia, setNewsFeedMedia] = useState([])
  const [language, setLanguage] = useState([])
  const [country, setCountry] = useState([])
  const [errorTrendingNewsTimeLimit, setErrorTrendingNewsTimeLimit] = useState('')
  const [selectedSubscriptionIndex, setSelectedSubscriptionIndex] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const [newsFeedErrorState, setNewsFeedErrorState] = useState(false)
  const [boldText, setBoldText] = useState(false)
  const [isFreeNewsChecked, setIsFreeNewsChecked] = useState(false)
  const [isFullNewsChecked, setIsFullNewsChecked] = useState(false)
  const [selectedCountries, setSelectedCountries] = useState(null)
  const [defaultImages, setDefaultImages] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [coffeeNewsInputs, setCoffeeNewsInputs] = useState({
    author: '',
    shortDescription: '',
    newsHtml: '',
    newsPlain: '',
    languageId: 0,
    subject: '',
    trendingNews: false,
    trendingNewsColor: '',
    searchTag: '',
    newsForSub: '',
    newsForCountries: '',
    trendingNewsTimeLimit: '',
    newsForLock: '',
    filePath: '',
    fileName: '',
    freeNews: false
  })
  const [errorState, setErrorState] = useState({
    authorState: false,
    headingState: false,
    descriptionState: false,
    trendingHours: false,
    tinyEditorState: false,
    languageState: false,
    countryState: false
  })
  const [textCount, setTextCount] = useState({
    authorCount: 0,
    headingCount: 0,
    descriptionCount: 0
  })
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    softAction: '',
    onRetry: null
  })
  const { author, newsHtml, newsPlain, subject, fileName, filePath, shortDescription, newsForSub, newsForCountries, newsForLock, trendingNews, trendingNewsTimeLimit } = coffeeNewsInputs
  const { authorCount, headingCount, descriptionCount } = textCount
  const { authorState, headingState, descriptionState, trendingHours, tinyEditorState, languageState, countryState } = errorState

  useEffect(() => {
    init()
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (addNewsFeedMockApiFlag) {
      setSubscriptionType(mockSubscriptionType)
      setSubscriptionLockType(mockSubscriptionLockType)
      setModalOpen(mockModalOpen)
      postCoffeeNewsData(addNewsFeedMockApiFlag)
    }
  }, [])

  useEffect(() => {
    const updateDivHeight = () => {
      const inputContainerHeight = parseInt(Variable.tinyEditorHeight, 10) + imageDivHeight.current.scrollHeight

      setImageContainerHeight(window.innerWidth >= parseInt(Variable.smallDevice, 10) ? inputContainerHeight : 'auto')
    }

    updateDivHeight()

    window.addEventListener('resize', updateDivHeight)

    return () => {
      window.removeEventListener('resize', updateDivHeight)
    }
  }, [newsFeedMedia])

  const init = async () => {
    setLoading(true)
    const fetchAllLanguages = apiAdapterCoffeeWeb.getAllLanguages()
    const fetchSubscriptionTypes = apiAdapterCoffeeWeb.getSubscriptionTypes()
    const fetchAllCountries = apiAdapterCoffeeWeb.getAllCountries()
    const fetchAllDefaultImages = apiAdapterCoffeeWeb.getAllDefaultImages()

    try {
      const [fetchAllLanguagesResponse, fetchSubscriptionTypesResponse, fetchAllCountriesResponse, fetchAllDefaultImagesResponse] = await Promise.all([fetchAllLanguages, fetchSubscriptionTypes, fetchAllCountries, fetchAllDefaultImages])

      setLanguage(fetchAllLanguagesResponse.data.returnLst)
      setSubscriptionType(fetchSubscriptionTypesResponse.data.returnLst)
      setSubscriptionLockType(fetchSubscriptionTypesResponse.data.returnLst)
      setCountry(fetchAllCountriesResponse.data)
      setDefaultImages(fetchAllDefaultImagesResponse.data)
    } catch {
      setModalState({
        showModal: true,
        message: t('NO_DATA_FOUND'),
        modalType: 'error',
        onRetry: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const uploadMediaToS3 = async (event) => {
    setLoading(true)
    const selectedFiles = event.files[0]
    const { name } = selectedFiles
    const fileData = new FormData()

    fileData.append('file', selectedFiles)

    try {
      const { data } = await apiAdapterCoffeeWeb.uploadFileToS3Bucket(fileData)

      if (data) {
        if (name.includes('pdf')) {
          setCoffeeNewsInputs({
            ...coffeeNewsInputs,
            fileType: 'pdf',
            fileName: name,
            filePath: data
          })
        } else if (name.includes('xlsx')) {
          setCoffeeNewsInputs({
            ...coffeeNewsInputs,
            fileType: 'xlsx',
            fileName: name,
            filePath: data
          })
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const imageUploadInTinyContainer = (blobInformation) =>
    new Promise((resolve) => {
      const processImageData = new FormData()

      processImageData.append('file', blobInformation.blob(), blobInformation.filename())

      apiAdapterCoffeeWeb.uploadFileToS3Bucket(processImageData).then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        }
      })
    })

  const postCoffeeNewsData = async () => {
    setLoading(true)
    const { idLanguage } = selectedLanguage
    const newsFeedPostData = {
      newsFeed: {
        id: 0,
        author,
        newsHtml,
        newsPlain,
        subject: boldText ? `<b>${subject}</b>` : subject,
        thumbnail: '',
        newsDate: currentTime,
        createdOn: currentTime,
        updatedOn: currentTime,
        createdBy: userDetails?.id,
        updatedBy: 0,
        isActive: true,
        views: 0,
        canDownload: true,
        fileName,
        filePath,
        fileSize: 0,
        newsSource: 0,
        reasonToDelete: '',
        shortDescription,
        newsForSub,
        newsForCountries,
        preferredlanguage: '',
        isAdmVerified: false,
        admIdUser: userDetails?.id === 2 ? userDetails?.id : 0,
        admApproveDtm: currentTime,
        admRejectRemark: '',
        isSAVerified: false,
        sAIdUser: userDetails?.id === 1 ? userDetails?.id : 0,
        saApproveDtm: currentTime,
        sadmRejectRemark: '',
        reportLink: 'NA',
        newsFavouriteCount: 0,
        languageId: idLanguage,
        newsForLock,
        publishedOn: currentTime,
        trendingNews,
        sendNotification: true,
        searchTag: shortDescription,
        trendingNewsColour: selectedColor,
        trendingNewsTimeLimit: trendingNewsTimeLimit !== '' ? trendingNewsTimeLimit : 0,
        freeNews: isFreeNewsChecked,
        showFullfreeNews: isFreeNewsChecked ? isFullNewsChecked : false
      },
      newsFeedMedia
    }

    try {
      const { data } = await apiAdapterCoffeeWeb.insertNewsFeed(newsFeedPostData)

      if (data.statusCode === 200) {
        setModalState({
          showModal: true,
          message: t('NEWS_FEED_ADDED_SUCCESSFULLY'),
          modalType: 'success',
          softAction: 'OK'
        })
      }
    } catch {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_UPDATE_NEWS_FEED'),
        modalType: 'error',
        onRetry: postCoffeeNewsData
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChangeCoffeeNewsFeedTinyInput = (event, editor) => {
    setButtonDisabled(false)
    const newsPlainText = editor.getContent().replace(REG_EX.TINY_REG, '')

    setCoffeeNewsInputs({
      ...coffeeNewsInputs,
      newsHtml: editor.getContent(),
      newsPlain: newsPlainText
    })
  }

  const handleTinyEditorBlur = (editor) => {
    setButtonDisabled(false)

    editor.on('blur', (e) => {
      setErrorState((previousState) => ({ ...previousState, tinyEditorState: true }))
    })
  }

  const handleChangeCoffeeNewsFeedInputs = (e) => {
    setButtonDisabled(false)
    const { name, value } = e.target

    if (name === 'news-author') {
      setCoffeeNewsInputs({
        ...coffeeNewsInputs,
        author: value
      })
      setTextCount({
        ...textCount,
        authorCount: value.length
      })
    } else if (name === 'news-heading') {
      setCoffeeNewsInputs({
        ...coffeeNewsInputs,
        subject: value
      })
      setTextCount({
        ...textCount,
        headingCount: value.length
      })
    } else if (name === 'short-description') {
      setCoffeeNewsInputs({
        ...coffeeNewsInputs,
        shortDescription: value
      })
      setTextCount({
        ...textCount,
        descriptionCount: value.length
      })
    } else if (name === 'trending-news-hours') {
      if (Validation.numberValidation(value) || value === '') {
        setErrorTrendingNewsTimeLimit(`${t('HOURS_LIMIT')}`)
        if ((value > 0 && value <= 200) || value === '') {
          setCoffeeNewsInputs({
            ...coffeeNewsInputs,
            trendingNewsTimeLimit: value
          })
        }
        if (value.length > 0) {
          setErrorTrendingNewsTimeLimit('')
        }
      } else {
        setErrorTrendingNewsTimeLimit(`${t('HOURS_IN_NUMBER')}`)
      }
    }
  }

  const handleBlurFunction = (e) => {
    const { name, value } = e.target

    const nameToStateMap = {
      'news-author': 'authorState',
      'news-heading': 'headingState',
      'short-description': 'descriptionState',
      'trending-news-hours': 'trendingHours',
      'language-dropdown': 'languageState',
      'country-multi-select': 'countryState'
    }

    const stateProperty = nameToStateMap[name]

    if (stateProperty && !value) {
      setErrorState((previousState) => ({ ...previousState, [stateProperty]: true }))
    }
  }

  const handleChangeSubscription = (item, index) => {
    setButtonDisabled(false)

    setSelectedSubscriptionIndex(index + 1)
    if (item.isChecked === true) {
      setSubscriptionType(subscriptionType.map((ele) => ({ ...ele, isChecked: false })))
      setCoffeeNewsInputs({ ...coffeeNewsInputs, newsForSub: '' })
      setSelectedSubscriptionIndex(0)
    } else {
      const modifiedSubList = subscriptionType.map((ele, ind) => ({
        ...ele,
        isChecked: (item.isChecked && ind <= index) || (!item.isChecked && ind >= index)
      }))

      setSubscriptionType(modifiedSubList)

      const selectedNewsId = modifiedSubList.filter((ele) => ele.isChecked).map((ele) => ele.id)

      setCoffeeNewsInputs({ ...coffeeNewsInputs, newsForSub: selectedNewsId.toString() })
    }
  }

  const handleChangeSubscriptionLock = (item) => {
    setButtonDisabled(false)

    const updatedSubscriptionLock = subscriptionLockType.map((ele) => (ele.id === item.id ? { ...ele, isChecked: !item.isChecked } : ele))

    const subscriptionLockId = updatedSubscriptionLock.filter((ele) => ele.isChecked).map((ele) => ele.id)

    setCoffeeNewsInputs({ ...coffeeNewsInputs, newsForLock: subscriptionLockId.toString() })
    setSubscriptionLockType(updatedSubscriptionLock)
  }

  const handleSelectedCountries = (e) => {
    setSelectedCountries(e.value)
    const selectedCountriesId = e.value.map(({ id }) => id).toString()

    setCoffeeNewsInputs({ ...coffeeNewsInputs, newsForCountries: selectedCountriesId })
  }

  const addNewsFeed = () => {
    if (!newsHtml || !author || !subject || !shortDescription || !selectedSubscriptionIndex || !newsForCountries || (trendingNews && !trendingNewsTimeLimit)) {
      setNewsFeedErrorState(true)

      return false
    }
    postCoffeeNewsData()
  }

  const handleColorSelect = (color) => {
    setSelectedColor((prevColor) => (prevColor === color ? '' : color))

    setCoffeeNewsInputs({
      ...coffeeNewsInputs,
      trendingNewsColour: coffeeNewsInputs.trendingNewsColor === color ? '' : color
    })
  }

  const makeTextFieldBold = () => {
    const textValue = document.getElementById('news-heading-text')

    if (boldText) {
      textValue.style.fontWeight = `${Variable.fontNormal}`
      setBoldText(false)
    } else {
      textValue.style.fontWeight = `${Variable.fontBold}`
      setBoldText(true)
    }
  }

  const handleCheckboxChange = () => {
    setIsFreeNewsChecked(!isFreeNewsChecked)
  }
  const handleFullNewsCheckboxChange = () => {
    setIsFullNewsChecked(!isFullNewsChecked)
  }

  const selectTrendingNews = () => {
    if (coffeeNewsInputs.trendingNews) {
      setCoffeeNewsInputs({
        ...coffeeNewsInputs,
        trendingNews: false,
        trendingNewsColor: '',
        trendingNewsTimeLimit: ''
      })
      setSelectedColor('')
    } else {
      setCoffeeNewsInputs({
        ...coffeeNewsInputs,
        trendingNews: true
      })
    }
  }

  const handleResetFunction = () => {
    setModalState({
      showModal: true,
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      modalType: 'info'
    })
  }

  const handleReset = () => {
    setModalState({ showModal: false })
    Object.keys(coffeeNewsInputs).forEach((key) => {
      coffeeNewsInputs[key] = ''
    })
    Object.keys(textCount).forEach((key) => {
      textCount[key] = 0
    })
    Object.keys(errorState).forEach((key) => {
      errorState[key] = false
    })
    setCountry((previousCountry) => previousCountry.map((country) => ({ ...country, isChecked: false })))
    setSubscriptionType(subscriptionType.map((ele) => ({ ...ele, isChecked: false })))
    setSubscriptionLockType(subscriptionLockType.map((ele) => ({ ...ele, isChecked: false })))
    setSelectedLanguage('')
    setSelectedCountries('')
    setSelectedSubscriptionIndex(0)
    setNewsFeedMedia([])
    setBoldText(false)
    setIsFreeNewsChecked(false)
    setIsFullNewsChecked(false)
    setSelectedColor('')
  }

  const onDismissFunction = () => {
    setModalState({ showModal: false })
    if (modalState.modalType === 'success') redirectPage()
  }

  const redirectPage = () => {
    params.set('language', selectedLanguage.idLanguage)
    history.push({
      pathname: ROUTE_STRINGS.listNewsFeed,
      search: params.toString()
    })
  }

  const selectedTrendingNewsColor = (selectedColor) => ({
    color: coffeeNewsInputs.trendingNews ? selectedColor : Variable.trendingNewsDefaultColor
  })

  const pickTrendingNewsColor = (color) => ({
    backgroundColor: color,
    border: color === selectedColor ? `2px solid ${Variable.trendingNewsDefaultColor}` : 'none'
  })

  const inputWrapper = {
    height: imageContainerHeight
  }

  const countryFlag = (option) => (
    <div className="country-flag">
      <Image alt={option.iso} src={option.flagsUrl} />
      <div className="add-news-country-name">{option.name}</div>
    </div>
  )

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <LayoutContainer title={'ADD_NEWS_FEED'} breadCrumbsTitle={'addNewsFeed'} data-testid="breadcrumbs-title">
      <div className="add-news-container">
        <div className="add-news-editor-wrapper">
          <div className={(newsFeedErrorState || tinyEditorState) && !newsHtml ? 'card-invalid' : ''} data-testid="editor">
            <Editor
              name="newsHtml"
              value={newsHtml}
              onEditorChange={handleChangeCoffeeNewsFeedTinyInput}
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              onInit={(event, editor) => {
                tinyEditorRef.current = editor
              }}
              init={{
                selector: 'textarea#paste-from-word',
                height: Variable.tinyEditorHeight,
                menubar: true,
                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'advcode', 'fullscreen', 'insertdatetime', 'media', 'table', 'powerpaste', 'code'],
                toolbar: 'undo redo | insert | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code',
                powerpaste_allow_local_images: true,
                powerpaste_word_import: 'prompt',
                powerpaste_html_import: 'prompt',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                images_upload_handler: imageUploadInTinyContainer,
                images_upload_credentials: true,
                init_instance_callback: handleTinyEditorBlur
              }}
            />
          </div>
          <div className="add-news-image-wrapper" ref={imageDivHeight}>
            <ModalComponent isModalOpen={isModalOpen} closeModal={closeModal} defaultImages={defaultImages} setNewsFeedMedia={setNewsFeedMedia} newsFeedMedia={newsFeedMedia} setButtonDisabled={setButtonDisabled} />
          </div>
        </div>
        <div className="add-news-input-wrapper">
          <div className="input-wrapper" style={inputWrapper}>
            <div className="add-news-author">
              <div className={!author && (newsFeedErrorState || authorState) ? 'mandatory-label' : ''}>
                <span className="card-label">{t('NEWS_AUTHOR')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card">
                <div className="author-text-limit">{authorCount}/50</div>
                <InputTextarea name="news-author" className={!author && (newsFeedErrorState || authorState) ? 'input-width p-invalid' : 'input-width'} placeholder={t('NEWS_AUTHOR')} value={author} onChange={handleChangeCoffeeNewsFeedInputs} onBlur={handleBlurFunction} rows={2} cols={30} maxLength={50} data-testId="newsAuthorField" />
                {!author && (newsFeedErrorState || authorState) && <span className="error-message">{t('PLEASE_FILL_THIS')}</span>}
              </div>
            </div>
            <div className="add-news-heading">
              <div className={!subject && (newsFeedErrorState || headingState) ? 'mandatory-label' : ''}>
                <span className="card-label">{t('NEWS_HEADING')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card">
                <div className="heading-option">
                  <div className="trending-checkbox">
                    <Checkbox onClick={selectTrendingNews} checked={trendingNews} />
                    <label htmlFor="ingredient1">{t('TRENDING_NEWS')}</label>
                  </div>
                  <div className="bold-checkbox">
                    <Checkbox onClick={makeTextFieldBold} checked={boldText} /> <span>{t('BOLD')}</span>
                  </div>
                  <div className="bold-checkbox">
                    <Checkbox checked={isFreeNewsChecked} onChange={handleCheckboxChange} /> <span>{t('FREE_NEWS')}</span>
                  </div>
                  {isFreeNewsChecked && (
                    <div className="bold-checkbox">
                      <Checkbox checked={isFullNewsChecked} onChange={handleFullNewsCheckboxChange} /> <span>{t('FULL_NEWS')}</span>
                    </div>
                  )}
                </div>
                <div className="heading-text-limit">{headingCount}/150</div>
                <InputTextarea name="news-heading" id="news-heading-text" className={!subject && (newsFeedErrorState || headingState) ? 'p-invalid input-width' : 'input-width'} placeholder={t('NEWS_HEADING')} value={subject} onChange={handleChangeCoffeeNewsFeedInputs} onBlur={handleBlurFunction} rows={3} cols={30} maxLength={150} style={selectedTrendingNewsColor(selectedColor)} data-testId="newsHeadingField" />
                {!subject && (newsFeedErrorState || headingState) && <span className="error-message">{t('PLEASE_FILL_THIS')}</span>}
                {trendingNews && (
                  <div className="trending-add-news-wrapper">
                    <span>{t('TRENDING_NEWS_COLORS')}:</span>
                    <div className="card">
                      <div>
                        {COLOR_CONSTANTS.TRENDING_NEWS_COLORS.map((color, index) => (
                          <Button key={index} onClick={() => handleColorSelect(color)} className="color-button" size="small" style={pickTrendingNewsColor(color)} data-testId="colorButton" />
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="add-news-trending">
                        <div>
                          <span className={(trendingNews && !trendingNewsTimeLimit && newsFeedErrorState) || trendingHours ? 'mandatory-label' : ''}>{t('TRENDING_NEWS_HOURS')}</span>
                          <span className="star-required"> *</span>
                        </div>
                        {errorTrendingNewsTimeLimit && <span className="error-message">{errorTrendingNewsTimeLimit} </span>}
                      </div>
                      <InputText name="trending-news-hours" id="integer" keyfilter="int" className={trendingNews && !trendingNewsTimeLimit && (newsFeedErrorState || trendingHours) ? 'p-invalid input-width' : 'input-width'} size="small" placeholder={t('TRENDING_NEWS_HOURS')} onChange={handleChangeCoffeeNewsFeedInputs} onBlur={handleBlurFunction} value={trendingNewsTimeLimit} data-testId="trendingNewsHours" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="add-news-description">
              <div className={!shortDescription && (newsFeedErrorState || descriptionState) ? 'mandatory-label' : ''}>
                <span className="card-label">{t('SHORT_DESCRIPTION')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card">
                <div className="description-text-limit">{descriptionCount}/150</div>
                <InputTextarea name="short-description" className={!shortDescription && (newsFeedErrorState || descriptionState) ? 'p-invalid input-width' : 'input-width'} placeholder={t('SHORT_DESCRIPTION')} value={shortDescription} onChange={handleChangeCoffeeNewsFeedInputs} onBlur={handleBlurFunction} rows={3} cols={30} maxLength={150} data-testId="shortDescriptionField" />
                {!shortDescription && (newsFeedErrorState || descriptionState) && <span className="error-message">{t('PLEASE_FILL_THIS')}</span>}
              </div>
            </div>
            <div className="add-news-language">
              <div className={!selectedLanguage && (newsFeedErrorState || languageState) ? 'mandatory-label' : ''}>
                <span className="card-label">{t('LANGUAGE')}</span>
                <span className="star-required"> *</span>
                <div className="card">
                  <Dropdown value={selectedLanguage} options={language} name="language-dropdown" optionLabel="languageName" placeholder={t('SELECT_LANGUAGE')} className={!selectedLanguage && (newsFeedErrorState || languageState) ? 'p-invalid input-width' : 'input-width'} onChange={(e) => setSelectedLanguage(e.value)} onBlur={handleBlurFunction} data-testId="languageDropdown" />
                  {!selectedLanguage && (newsFeedErrorState || languageState) && <span className="error-message">{t('LANGUAGE_IS_REQUIRED')}</span>}
                </div>
              </div>
            </div>
            <div className="add-news-subscription-type">
              <div className={!selectedSubscriptionIndex && newsFeedErrorState ? 'mandatory-label' : ''}>
                <span className="card-label">{t('SUBSCRIPTION_TYPE')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card subscription-checkbox" data-testId="subscriptionTypeContainer">
                {subscriptionType.map((ele, index) => (
                  <div key={ele.key} className="check-box-wrapper">
                    <Checkbox inputId={ele.key} name="subscription-type" value={ele} checked={ele.isChecked} onChange={(e) => handleChangeSubscription(ele, index)} className={!selectedSubscriptionIndex && newsFeedErrorState ? 'p-invalid' : ''} data-testId="subscriptionTypeCheckbox" />
                    <label htmlFor={ele.key} className="check-box-label" data-testId="subscriptionTypeLabel">
                      {ele.name}
                    </label>
                  </div>
                ))}
                {!selectedSubscriptionIndex && newsFeedErrorState && <span className="error-message">{t('PLEASE_SELECT_SUBSCRIPTION_TYPE')}</span>}
              </div>
            </div>
            <div className="add-news-subscription-lock">
              <span className="card-label">{t('SUBSCRIPTION_LOCK')}</span>
              <div className="card subscription-lock-checkbox" data-testId="subscriptionLockContainer">
                {subscriptionLockType.map((ele, index) => (
                  <div key={ele.key} className="check-box-wrapper">
                    <Checkbox inputId={ele.key} name="subscription-type" value={ele} checked={ele.isChecked} onChange={(e) => handleChangeSubscriptionLock(ele, index)} data-testId="subscriptionLockCheckbox" />
                    <label htmlFor={ele.key} className="check-box-label" data-testId="subscriptionLockLabel">
                      {ele.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="add-news-country-list">
              <div className={(newsFeedErrorState || countryState) && !newsForCountries ? 'mandatory-label' : ''}>
                <span className="card-label">{t('COUNTRY')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card">
                <MultiSelect value={selectedCountries} name="country-multi-select" onChange={(e) => handleSelectedCountries(e)} onBlur={handleBlurFunction} options={country} itemTemplate={countryFlag} optionLabel="nicename" filter placeholder={t('COUNTRY')} maxSelectedLabels={3} className={!selectedCountries && (newsFeedErrorState || countryState) ? 'p-invalid' : ''} data-testId="countryDropdown" />
                {!selectedCountries && (newsFeedErrorState || countryState) && <span className="error-message">{t('PLEASE_SELECT_COUNTRY_TYPE')}</span>}
              </div>
            </div>

            <div>
              <Button label="Default Thumbnail Images" className="default-button" icon="pi pi-external-link" onClick={openModal} data-testId="default-thumbnail-button" />
            </div>
            <div className="add-news-file-input">
              <span className="card-label">{t('UPLOAD_DOCUMENT')}</span>
              <div className="file-upload">
                <div className="p-inputgroup">
                  <FileUpload mode="basic" chooseLabel={t('CHOOSE')} accept=".xlsx,.pdf" auto onSelect={(e) => uploadMediaToS3(e)} className="file-upload-button" data-testid="fileUploadButton" />
                  <InputText disabled placeholder={t('NAME_OF_THE_DOCUMENT_FILE')} className="input-width" value={fileName} data-testId="documentFileField" />
                </div>
              </div>
            </div>
          </div>
          <div className="add-news-action-button">
            <ButtonComponent type="reset" onClick={handleResetFunction} />
            <ButtonComponent type="submit" onClick={addNewsFeed} disabled={isButtonDisabled} />
          </div>
        </div>
      </div>
      <DialogModal {...modalState} onDismiss={onDismissFunction} onSuccess={redirectPage} onConfirmation={handleReset} onHide={onDismissFunction} />
    </LayoutContainer>
  )
}

export default Loader(AddNewsFeed)
