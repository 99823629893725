import './styles.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const EditTotalCertifiedStock = ({ openModal, setOpenModal, selectedValue, selectedRowData, reportName, setFetchedData }) => {
  const { t } = useTranslation()
  const { marketName, idMarket } = selectedValue
  const [currentDate, setCurrentDate] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isFormEnabled, setIsFormEnabled] = useState({
    editMode: false,
    submitButton: false
  })
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [formFields, setFormFields] = useState({
    date: '',
    certifiedStocks: '',
    comparedToPreDay: '',
    nonTenderable: '',
    suspended: ''
  })

  const [validFields, setValidFields] = useState({
    certifiedStocks: false,
    comparedToPreDay: false,
    nonTenderable: false,
    suspended: false
  })

  const [inputFields, setInputFields] = useState({
    date: '',
    certifiedStocks: '',
    comparedToPreDay: '',
    pendingGradingReport: '',
    grading: ''
  })

  const [validationErrors, setValidationErrors] = useState({
    certifiedStocks: false,
    comparedToPreDay: false,
    pendingGradingReport: false,
    grading: false
  })
  const { date, certifiedStocks, comparedToPreDay, nonTenderable, suspended } = formFields
  const { date: reportDate, certifiedStocks: certifiedValue, comparedToPreDay: previousDay, pendingGradingReport, grading } = inputFields

  useEffect(() => {
    if (selectedRowData && openModal) {
      const getFormattedDate = (rowData) => {
        const dateValue = rowData.date || rowData.Date

        return dateValue ? moment(dateValue, 'DD-MMM-YYYY').toDate() : null
      }

      const formattedDate = getFormattedDate(selectedRowData)

      setCurrentDate(formattedDate)
      if (idMarket === 1) {
        setFormFields({
          date: selectedRowData.date,
          certifiedStocks: selectedRowData.certifiedStocks,
          comparedToPreDay: selectedRowData.ComparedToPreDay,
          nonTenderable: selectedRowData.nonTenderable,
          suspended: selectedRowData.suspended
        })
      } else {
        setInputFields({
          date: selectedRowData.Date,
          certifiedStocks: selectedRowData.certifiedStocks,
          comparedToPreDay: selectedRowData.ComparedToPreDay,
          pendingGradingReport: selectedRowData.PendingGradingReport,
          grading: selectedRowData.grading
        })
      }
    }
  }, [openModal, selectedRowData])

  const updateData = async () => {
    handleDismiss()

    try {
      let data
      let response

      if (idMarket === 1) {
        data = {
          fileName: reportName,
          date: date,
          certifiedStocks: certifiedStocks,
          comparedToPreDay: comparedToPreDay,
          nonTenderable: nonTenderable,
          suspended: suspended
        }
      } else {
        data = {
          filename: reportName,
          date: reportDate,
          certifiedStocks: certifiedValue,
          comparedToPreDay: previousDay,
          pendingGradingReport: pendingGradingReport,
          grading: grading
        }
      }

      if (idMarket === 1) {
        response = await apiAdapterCoffeeWeb.updateTotalCertifiedStockRobusta(data)
      } else {
        response = await apiAdapterCoffeeWeb.updateTotalCertifiedStockArabica(data)
      }

      if (response.status === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_UPDATED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
        setFetchedData(response?.data?.details)
        setOpenModal(false)
        setButtonDisabled(true)
        setIsFormEnabled({
          editMode: false,
          submitButton: false
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_UPDATE_REPORT_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: updateData,
        onHide: handleDismiss
      })
    }
  }

  const handleInputChange = (fieldName, value) => {
    setButtonDisabled(false)

    if (idMarket === 1) {
      setFormFields((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    } else {
      setInputFields((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    }
  }

  const handleBlur = (fieldName) => {
    if (idMarket === 1) {
      setValidFields((previousState) => ({
        ...previousState,
        [fieldName]: true
      }))
    } else {
      setValidationErrors((previousState) => ({
        ...previousState,
        [fieldName]: true
      }))
    }
  }

  const dismissModal = () => {
    setOpenModal(false)
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })

    if (idMarket === 1) {
      setValidFields({
        certifiedStocks: false,
        comparedToPreDay: false,
        nonTenderable: false,
        suspended: false
      })
    } else {
      setValidationErrors({
        certifiedStocks: false,
        comparedToPreDay: false,
        pendingGradingReport: false,
        grading: false
      })
    }
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const isDisable = () => {
    const fieldsToCheck = [comparedToPreDay, certifiedStocks, nonTenderable, suspended]
    const fieldValidationCheck = [previousDay, certifiedValue, pendingGradingReport, grading]

    const fieldsToValidate = idMarket === 1 ? fieldsToCheck : fieldValidationCheck

    return fieldsToValidate.some((field) => field === '' || field === null)
  }

  const isFieldInvalid = (fieldName) => {
    let errorMessage

    if (idMarket === 1) {
      errorMessage = validFields[fieldName] && (formFields[fieldName] === '' || formFields[fieldName] === null)
    } else {
      errorMessage = validationErrors[fieldName] && (inputFields[fieldName] === '' || inputFields[fieldName] === null)
    }

    return errorMessage
  }

  const getInputClassName = (fieldName) => {
    const className = isFieldInvalid(fieldName) ? 'error-fields' : ''

    return className
  }

  const confirmResetForm = () => {
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })
    setShowDialog({ ...showDialog, showModal: false })

    if (idMarket === 1) {
      setFormFields({
        certifiedStocks: selectedRowData.certifiedStocks,
        comparedToPreDay: selectedRowData.ComparedToPreDay,
        nonTenderable: selectedRowData.nonTenderable,
        suspended: selectedRowData.suspended
      })
    } else {
      setInputFields({
        date: selectedRowData.Date,
        certifiedStocks: selectedRowData.certifiedStocks,
        comparedToPreDay: selectedRowData.ComparedToPreDay,
        pendingGradingReport: selectedRowData.PendingGradingReport,
        grading: selectedRowData.grading
      })
    }
  }

  const enableFormEditing = () => {
    setIsFormEnabled({
      editMode: true,
      submitButton: true
    })
  }

  const showResetConfirmationDialog = () => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onConfirmation: confirmResetForm,
      onHide: handleDismiss
    })
  }

  const footerContent = () => (
    <div className="footer-button">
      {isFormEnabled.submitButton && (
        <>
          <ButtonComponent type="cancel" onClick={dismissModal} />
          <ButtonComponent type="submit" onClick={updateData} disabled={buttonDisabled || isDisable()} />
        </>
      )}
    </div>
  )

  return (
    <>
      <Dialog visible={openModal} onHide={dismissModal} className="certified-stock-edit-modal" header={`${t('EDIT_CERTIFIED_STOCK_REPORT')} - ${marketName}`} footer={footerContent}>
        <div className="edit-container">
          <div className="action-button">{isFormEnabled.editMode ? <ButtonComponent type={'reset'} onClick={showResetConfirmationDialog} /> : <ButtonComponent type={'edit'} onClick={enableFormEditing} />}</div>

          <div className="edit-form-wrapper">
            <div className="input-form-section">
              <span className="p-float-label">
                <Calendar id="date" name="date" value={currentDate} disabled={true} showIcon={true} className="calendar-input" />
                <label htmlFor="date">
                  {t('DATE')} <span className="required-text-field">*</span>
                </label>
              </span>
            </div>

            {idMarket === 1 && (
              <>
                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="certified-stock"
                      name="certifiedStocks"
                      value={certifiedStocks}
                      onChange={(event) => {
                        handleInputChange('certifiedStocks', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('certifiedStocks')
                      }}
                      className={`input-text ${getInputClassName('certifiedStocks')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="certified-stock" className={`input-label ${getInputClassName('certifiedStocks')}`}>
                      {t('CERTIFIED_STOCKS')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('certifiedStocks') && <small className="error-message">{t('CERTIFIED_STOCK_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="compare-to-pre-day"
                      name="comparedToPreDay"
                      value={comparedToPreDay}
                      onChange={(event) => {
                        handleInputChange('comparedToPreDay', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('comparedToPreDay')
                      }}
                      className={`input-text ${getInputClassName('comparedToPreDay')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="compare-to-pre-day" className={`input-label ${getInputClassName('comparedToPreDay')}`}>
                      {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('comparedToPreDay') && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="non-tenderable"
                      name="nonTenderable"
                      value={nonTenderable}
                      onChange={(event) => {
                        handleInputChange('nonTenderable', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('nonTenderable')
                      }}
                      className={`input-text ${getInputClassName('nonTenderable')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="non-tenderable" className={`input-label ${getInputClassName('nonTenderable')}`}>
                      {t('NON_TENDERABLE')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('nonTenderable') && <small className="error-message">{t('NON_TENDERABLE_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="suspended"
                      name="suspended"
                      value={suspended}
                      onChange={(event) => {
                        handleInputChange('suspended', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('suspended')
                      }}
                      className={`input-text ${getInputClassName('suspended')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="suspended" className={`input-label ${getInputClassName('suspended')}`}>
                      {t('SUSPENDED')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('suspended') && <small className="error-message">{t('SUSPENDED_ERROR_MESSAGE')}</small>}
                </div>
              </>
            )}

            {idMarket === 2 && (
              <>
                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="certified-stock"
                      name="certifiedStocks"
                      value={certifiedValue}
                      onChange={(event) => {
                        handleInputChange('certifiedStocks', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('certifiedStocks')
                      }}
                      className={`input-text ${getInputClassName('certifiedStocks')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="certified-stock" className={`input-label ${getInputClassName('certifiedStocks')}`}>
                      {t('CERTIFIED_STOCKS')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('certifiedStocks') && <small className="error-message">{t('CERTIFIED_STOCK_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="compare-to-pre-day"
                      name="comparedToPreDay"
                      value={previousDay}
                      onChange={(event) => {
                        handleInputChange('comparedToPreDay', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('comparedToPreDay')
                      }}
                      className={`input-text ${getInputClassName('comparedToPreDay')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="compare-to-pre-day" className={`input-label ${getInputClassName('comparedToPreDay')}`}>
                      {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('comparedToPreDay') && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="pending-grading"
                      name="pendingGradingReport"
                      value={pendingGradingReport}
                      onChange={(event) => {
                        handleInputChange('pendingGradingReport', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('pendingGradingReport')
                      }}
                      className={`input-text ${getInputClassName('pendingGradingReport')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="pending-grading" className={`input-label ${getInputClassName('pendingGradingReport')}`}>
                      {t('PENDING_GRADING_REPORT')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('pendingGradingReport') && <small className="error-message">{t('PENDING_GRADING_REPORT_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputTextarea
                      id="grading"
                      name="grading"
                      value={grading}
                      onChange={(event) => {
                        handleInputChange('grading', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('grading')
                      }}
                      className={`input-text ${getInputClassName('grading')}`}
                      disabled={!isFormEnabled.editMode}
                      rows={4}
                      cols={30}
                    />
                    <label htmlFor="grading" className={`input-label ${getInputClassName('grading')}`}>
                      {t('DAILY_GRADING_SUMMARY')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('grading') && <small className="error-message">{t('GRADING_ERROR_MESSAGE')}</small>}
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>

      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </>
  )
}

export default EditTotalCertifiedStock
