import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputSwitch } from 'primereact/inputswitch'
import { Field, Form } from 'react-final-form'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import Validation from 'Utils/Validation'
import { commonMethods } from 'Utils/commonMethods'
import { getUserRoleBasedFeature } from 'Utils/RolesPermission'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { AddEntry } from 'Assets/Icons'

const ListTerminalDetails = ({ setLoading, mockTerminalDetails, mockTerminalDetailsData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userRoleBasedFeature = getUserRoleBasedFeature()

  const showAddButton = userRoleBasedFeature?.functionality?.terminalDetails?.showAddButton ?? true
  const addTerminalDataButton = userRoleBasedFeature?.functionality?.terminalDetails?.addTerminalDataButton ?? false
  const editTerminalDetailsButton = userRoleBasedFeature?.functionality?.terminalDetails?.editTerminalDetailsButton ?? false
  const isActiveSwitchDisabled = userRoleBasedFeature?.functionality?.terminalDetails?.isActiveSwitchDisabled ?? false
  let rowCount = 6
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [terminalDetailsData, setTerminalDetailsData] = useState([])
  const [selectedDate, setSelectedDate] = useState('')
  const [visible, setVisible] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('all')
  const [marketNameToFilter, setMarketNameToFilter] = useState([])
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })
  const [terminalData, setTerminalData] = useState({
    idMarket: '',
    contractName: '',
    marketName: '',
    date: '',
    open: '',
    high: '',
    low: '',
    close: '',
    netChg: '',
    volume: '',
    comparedToPreDay: '',
    openInterest: '',
    comparedToPreDay1: ''
  })

  const columns = [
    {
      dataField: 'marketName',
      text: t('MARKET_NAME'),
      className: 'market-name-column'
    },
    {
      dataField: 'contractName',
      text: t('CONTRACT_NAME'),
      className: 'contract-name-column'
    },
    {
      dataField: 'optionExpiry',
      text: t('OPTION_EXPIRY'),
      className: 'option-expiry-column'
    },
    {
      dataField: 'firstNoticeDay',
      text: t('FIRST_NOTICE_DAY'),
      className: 'first-notice-column'
    },
    {
      dataField: '_52weeksHighRate',
      text: t('WEEK_HIGH_RATE'),
      className: 'week-high-rate-column'
    },
    {
      dataField: '_52weeksLowRate',
      text: t('WEEK_LOW_RATE'),
      className: 'week-low-rate-column'
    },

    {
      dataField: 'weekHighDate',
      text: t('WEEK_HIGH_DATE'),
      className: 'week-high-date-column'
    },
    {
      dataField: 'weekLowDate',
      text: t('WEEK_LOW_DATE'),
      className: 'week-low-date-column'
    }
  ]

  const filterOptions = [
    { label: t('ALL_MARKET'), value: 'all' },
    { label: t('ROBUSTA_MARKET'), value: 'robusta' },
    { label: t('ARABICA_MARKET'), value: 'arabica' }
  ]

  useEffect(() => {
    init()

    // Testing Code
    if (mockTerminalDetails) {
      setTerminalDetailsData(mockTerminalDetailsData)
      submitTerminalData()
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const init = async () => {
    setShowDialog({ ...showDialog, showModal: false })
    setLoading(true)
    setShowTableMessage(true)
    try {
      const terminalDetailsResponse = await apiAdapterCoffeeWeb.getAllTerminalDetails()
      const terminalDetailsDataResponse = terminalDetailsResponse.data.returnLst

      const modifiedTerminalDetailsList = terminalDetailsDataResponse.map((ele) => {
        const optionExpiry = commonMethods.utcToLocalDate(ele.optionExpiry)

        const firstNoticeDay = commonMethods.utcToLocalDate(ele.firstNoticeDay)

        /* eslint-disable-next-line no-underscore-dangle */
        const weekHighDate = commonMethods.utcToLocalDate(ele._52weeksHighDate)
        /* eslint-disable-next-line no-underscore-dangle */
        const weekLowDate = commonMethods.utcToLocalDate(ele._52weeksLowDate)

        return {
          ...ele,
          optionExpiry,
          firstNoticeDay,
          weekHighDate,
          weekLowDate
        }
      })

      setTerminalDetailsData(modifiedTerminalDetailsList)
      setMarketNameToFilter(modifiedTerminalDetailsList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => init(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const submitTerminalData = async () => {
    setVisible(false)
    setShowDialog(false)
    setLoading(true)

    const { contractName, marketName, date, open, high, low, close, netChg, volume, comparedToPreDay, openInterest, comparedToPreDay1 } = terminalData

    const data = [
      {
        idMarket: marketName === 'Robusta' ? 1 : 2,
        contractName: contractName,
        marketName: marketName,
        terminaldata: [
          {
            date: date,
            open: Number(open),
            high: Number(high),
            low: Number(low),
            close: Number(close),
            netChg: Number(netChg),
            volume: Number(volume),
            comparedToPreDay: Number(comparedToPreDay),
            openInterest: Number(openInterest),
            comparedToPreDay1: Number(comparedToPreDay1)
          }
        ]
      }
    ]

    try {
      const response = await apiAdapterCoffeeWeb.insertTerminalData(data)
      const { status: responseStatus } = response

      if (responseStatus === 200) {
        setLoading(false)
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('TERMINAL_DATA_ADDED_SUCCESSFULLY'),
          onHide: () => setShowDialog({ ...showDialog, showModal: false }),
          onSuccess: handleDismiss
        })
        resetData()
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('COULD_NOT_ADD_TERMINAL_DATA'),
        onRetry: () => submitTerminalData(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const deActiveTerminalDetails = async (options) => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setLoading(true)
    const { id } = options

    try {
      await apiAdapterCoffeeWeb.toggleTerminalDetailsStatus({ userId: id })
      setLoading(false)
      init()
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_ACTIVATE_INACTIVATE_TERMINAL_DETAILS_STATUS'),
        onRetry: () => deActiveTerminalDetails(options),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleAddButtonClick = () => {
    history.push({
      pathname: ROUTE_STRINGS.addTerminalDetails
    })
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }
  const editTerminalDetailsRedirect = (rowData) => {
    history.push(`${ROUTE_STRINGS.editTerminalDetails}/${rowData.id}`)
  }

  const openConfirmDialog = (options) => {
    if (options.isActive) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'info',
        message: t('CONFIRM_THE_DEACTIVATION_OF_TERMINAL_DETAILS_STATUS'),
        onConfirmation: () => deActiveTerminalDetails(options),
        onHide: handleDismiss
      })
    }
  }

  const handleTableAddButtonClick = (rowData) => {
    setVisible(true)
    setTerminalData({
      ...terminalData,
      marketName: rowData.marketName,
      contractName: rowData.contractName
    })
  }

  const handleChange = (fieldName, newValue) => {
    if (Validation.phoneCharValidation(newValue) || newValue === '') {
      setTerminalData((previousState) => ({
        ...previousState,
        [fieldName]: newValue
      }))
    }
  }

  const handleDateChange = (e) => {
    const selectedDate = e.value

    const formattedDate = selectedDate ? moment(selectedDate).format('DD-MMM-YY') : ''

    setSelectedDate(e.value)
    setTerminalData({ ...terminalData, date: formattedDate })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const requiredFields = {
    date: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Date' }),
    high: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'High Rate' }),
    low: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Low Rate' }),
    open: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Open Rate' }),
    close: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Close Rate' }),
    netChg: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Net Change' }),
    volume: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Volume' }),
    comparedToPreDay: t('COMPARE_TO_PREVIOUS_IS_REQUIRED', { dataField: 'Day' }),
    comparedToPreDay1: t('COMPARE_TO_PREVIOUS_IS_REQUIRED', { dataField: 'One Day' }),
    openInterest: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Open Interest' })
  }

  const validate = (data) => {
    const errors = {}

    Object.keys(requiredFields).forEach((field) => {
      if (!data[field]) {
        errors[field] = requiredFields[field]
      }
    })

    return errors
  }

  const { marketName, contractName, date, high, low, open, close, netChg, volume, comparedToPreDay, comparedToPreDay1, openInterest } = terminalData
  const isButtonDisabled = !marketName || !contractName || !date || !high || !low || !open || !close || !netChg || !volume || !comparedToPreDay || !comparedToPreDay1 || !openInterest

  const closeDialog = () => {
    resetData()
    setVisible(false)
  }

  const actionTemplate = (rowData) => (
    <div className="action-container">
      <Button type="button" icon="pi pi-pencil" className="pi-edit-icon" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => editTerminalDetailsRedirect(rowData)} disabled={editTerminalDetailsButton} data-testid="edit-button" />

      <Button type="button" className="add-button" tooltip={t('ADD_TERMINAL_DATA_TOOL_TIP')} tooltipOptions={{ position: 'top' }} onClick={() => handleTableAddButtonClick(rowData)} disabled={addTerminalDataButton} data-testid="add-terminal-data-action">
        <AddEntry fill="#50b3f6" className="add-entry-button" />
      </Button>
    </div>
  )

  const makeTerminalDetailsDeActive = (options) => (
    <div className="toggle-switch">
      <InputSwitch checked={options.isActive} onChange={() => openConfirmDialog(options)} disabled={isActiveSwitchDisabled} />
    </div>
  )

  const footerContent = (
    <div className="terminal-button-container">
      <ButtonComponent type="cancel" onClick={closeDialog} />
      <ButtonComponent onClick={submitTerminalData} type="submit" disabled={isButtonDisabled} />
    </div>
  )

  const onSubmit = (data) => {
    setTerminalData(data)
  }

  const handleDismiss = () => {
    resetData()
    setShowDialog({ ...showDialog, showModal: false })
  }

  const resetData = () => {
    setTerminalData({
      idMarket: '',
      contractName: '',
      marketName: '',
      date: '',
      open: '',
      high: '',
      low: '',
      close: '',
      netChg: '',
      volume: '',
      comparedToPreDay: '',
      openInterest: '',
      comparedToPreDay1: ''
    })

    setSelectedDate('')
  }

  const filterNewsBasedOnFilter = (e) => {
    const { value } = e.target

    setSelectedFilter(value)

    let filteredData = []

    if (value === 'all') {
      filteredData = marketNameToFilter
    } else if (value === 'robusta') {
      filteredData = marketNameToFilter.filter((name) => name.marketName === 'Robusta')
    } else if (value === 'arabica') {
      filteredData = marketNameToFilter.filter((name) => name.marketName === 'Arabica')
    }

    setTerminalDetailsData(filteredData)
  }

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <LayoutContainer title={'LIST_TERMINAL_DETAILS'} breadCrumbsTitle={'listTerminalDetails'} showAddButton={showAddButton} handleAddButtonClick={handleAddButtonClick}>
      <div className="terminal-details-container">
        <div className="status-filter-dropdown">
          <Dropdown placeholder={t('FILTER_MARKET_NAME')} value={selectedFilter} options={filterOptions} onChange={filterNewsBasedOnFilter} data-testid="filter-dropdown" />
        </div>

        <DataTable value={terminalDetailsData} paginator={terminalDetailsData.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="terminal-details-table">
          {columns.map((column, index) => (
            <Column key={index} field={column.dataField} header={column.text} className={column.className} />
          ))}
          <Column header={t('IS_ACTIVE')} className="active-column" body={makeTerminalDetailsDeActive}></Column>
          <Column body={actionTemplate} header={t('ACTIONS')} className="action-column" />
        </DataTable>
      </div>
      <Dialog header={t('ADD_TERMINAL_DATA')} visible={visible} closable={true} className="terminal-main-dialog" onHide={closeDialog} footer={footerContent}>
        <div className="terminal-data-container" data-testid="terminal-data-form">
          <Form
            onSubmit={onSubmit}
            initialValues={terminalData}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid-input">
                  <Field
                    name="marketName"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="market-name" {...input} disabled className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="market-name" />
                          <label htmlFor="market-name" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('MARKET_NAME')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                  <Field
                    name="contractName"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="contract-name" {...input} disabled className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="contract-name" />
                          <label htmlFor="contract-name" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('CONTRACT_NAME')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="date"
                    render={({ input, meta }) => (
                      <div className="date-field">
                        <span className="p-float-label">
                          <Calendar {...input} id="date" name="date" value={selectedDate} onChange={handleDateChange} maxDate={new Date()} showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="date" />
                          <label htmlFor="date" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('DATE')} <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="high"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="high-rate"
                            {...input}
                            value={terminalData.high}
                            onChange={(e) => {
                              handleChange('high', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="high-rate"
                          />
                          <label htmlFor="high-rate" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('HIGH_RATE')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="low"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="low-rate"
                            {...input}
                            value={terminalData.low}
                            onChange={(e) => {
                              handleChange('low', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="low-rate"
                          />
                          <label htmlFor="low-rate" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('LOW_RATE')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="open"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="open-rate"
                            {...input}
                            value={terminalData.open}
                            onChange={(e) => {
                              handleChange('open', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="open-rate"
                          />
                          <label htmlFor="open-rate" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('OPEN_RATE')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="close"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="close-rate"
                            {...input}
                            value={terminalData.close}
                            onChange={(e) => {
                              handleChange('close', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="close-rate"
                          />
                          <label htmlFor="close-rate" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('CLOSE_RATE')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="netChg"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="net-charge"
                            {...input}
                            value={terminalData.netChg}
                            onChange={(e) => {
                              handleChange('netChg', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="net-change"
                          />

                          <label htmlFor="net-charge" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('NET_CHANGE')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="volume"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="volume"
                            {...input}
                            value={terminalData.volume}
                            onChange={(e) => {
                              handleChange('volume', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="volume"
                          />
                          <label htmlFor="volume" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('VOLUME')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="comparedToPreDay"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="compared-to-previous-day"
                            {...input}
                            value={terminalData.comparedToPreDay}
                            onChange={(e) => {
                              handleChange('comparedToPreDay', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="compared-to-previous-day"
                          />
                          <label htmlFor="compared-to-previous-day" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('COMPARE_TO_PREVIOUS_DAY')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="comparedToPreDay1"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="compared-to-previous-one-day"
                            {...input}
                            value={terminalData.comparedToPreDay1}
                            onChange={(e) => {
                              handleChange('comparedToPreDay1', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="compared-to-previous-one-day"
                          />
                          <label htmlFor="compared-to-previous-one-day" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('COMPARE_TO_PREVIOUS_ONE_DAY')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="openInterest"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="open-interest"
                            {...input}
                            value={terminalData.openInterest}
                            onChange={(e) => {
                              handleChange('openInterest', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                            data-testid="open-interest"
                          />
                          <label htmlFor="open-interest" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'terminal-field-label'}`)}>
                            {t('OPEN_INTEREST')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>
              </form>
            )}
          />
        </div>
      </Dialog>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </LayoutContainer>
  )
}

export default Loader(ListTerminalDetails)
