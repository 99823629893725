import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TabView, TabPanel } from 'primereact/tabview'
import LayoutContainer from 'Components/LayoutComponent'
import TotalCertifiedStockReport from './TotalCertifiedStockReport'
import AllCountryStockReport from './AllCountryStockReport'
import PortWiseStockReport from './PortWiseStockReport'

const CertifiedStocksReport = () => {
  const { t } = useTranslation()

  return (
    <div className="coffee-reports-container">
      <LayoutContainer title={'COFFEE_REPORTS'} breadCrumbsTitle={'certifiedStockReport'}>
        <TabView>
          <TabPanel header={t('TOTAL_CERTIFIED_STOCK')}>
            <TotalCertifiedStockReport />
          </TabPanel>
          <TabPanel header={t('ALL_COUNTRY_CERTIFIED_STOCK')}>
            <AllCountryStockReport />
          </TabPanel>
          <TabPanel header={t('PORT_WISE_STOCK_REPORT')}>
            <PortWiseStockReport />
          </TabPanel>
        </TabView>
      </LayoutContainer>
    </div>
  )
}

export default CertifiedStocksReport
