import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'primereact/checkbox'
import moment from 'moment'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const MapMenu = ({ setLoading, MockListAllMenus, mockMapMenuDataFlag }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id: UserId } = getLocalUserDetails()
  const currentTimeInUserTimeZone = new Date()
  const currentTime = moment(currentTimeInUserTimeZone).format('YYYY-MM-DDTHH:mm:ss')
  const location = useLocation()

  const {
    state: { name, id: subscriptionId }
  } = location

  const [listAllMenus, setListAllMenus] = useState([])
  const [previousState, setPreviousState] = useState([])
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(true)
  const [menuIds, setMenuIds] = useState('')
  const [subscriptionTypeId, setSubscriptionTypeId] = useState('')
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(true)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onSuccess: () => {} })

  useEffect(() => {
    init()

    // Testing Code mock MapMenu API Flag
    if (mockMapMenuDataFlag) {
      setListAllMenus(MockListAllMenus)
      getSubscriptionDetailsForCheckBox()
      updateMenuData()
    }
  }, [])

  const init = async () => {
    handleDismiss()
    setLoading(true)

    try {
      const { data } = await apiAdapterCoffeeWeb.getAllMenu()
      const filteredMenuList = data.filter((menu) => menu.isadminConsoleMenu === false)
      const sortedMenuList = filteredMenuList.sort((a, b) => a.menuName.localeCompare(b.menuName))

      setListAllMenus(sortedMenuList.map((menu) => ({ ...menu, isChecked: false })))
      setPreviousState(sortedMenuList.map((menu) => ({ ...menu, isChecked: false })))
      getSubscriptionDetailsForCheckBox(subscriptionId, sortedMenuList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('ERROR_FETCHING_FOR_MAP_MENU'),
        onRetry: init,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const getSubscriptionDetailsForCheckBox = async (id, element) => {
    setLoading(true)
    try {
      const subscriptionDetailsResponse = await apiAdapterCoffeeWeb.getMenuGroupBySubscriptionType(id)

      setSubscriptionTypeId(subscriptionDetailsResponse.data.returnLst)

      const menuIds = subscriptionDetailsResponse.data.returnLst.menuItem.split(',').map(Number)

      const updatedMenus = element.map((menu) => ({
        ...menu,
        isChecked: menuIds.includes(menu.id)
      }))

      setListAllMenus(updatedMenus)
      setPreviousState(updatedMenus)
      setMenuIds(menuIds)
    } catch (error) {
      if (error.response?.data?.statusCode === 404) {
        setListAllMenus(element.map((menu) => ({ ...menu, isChecked: false })))
      } else {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('ERROR_FETCHING_FOR_MAP_MENU'),
          onRetry: init,
          onHide: handleDismiss
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const updateMenuData = async () => {
    handleDismiss()
    setLoading(true)
    const { id } = subscriptionTypeId

    try {
      const objectData = {
        id: id ?? 0,
        menuGroupName: name,
        menuItem: menuIds.toString(),
        createdOn: currentTime,
        createdBy: UserId,
        landingMenuId: 0,
        canDelete: true,
        menuItemWithParent: menuIds.toString(),
        roleid: 0,
        menuLock: '',
        subscriptionType: subscriptionId
      }

      const UpdatedMenuDataResponse = await apiAdapterCoffeeWeb.insertMenuGroup(objectData)

      if (UpdatedMenuDataResponse.status === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('MAP_DATA_ADDED_SUCCESSFULLY'),
          onHide: handleSuccess,
          onSuccess: handleSuccess
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: updateMenuData,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCheckboxChange = (selectedMenuId) => {
    const modifiedMenu = listAllMenus.map((element) => (element.id === selectedMenuId ? { ...element, isChecked: !element.isChecked } : element))
    const selectedIds = modifiedMenu.filter((menu) => menu.isChecked).map((menu) => menu.id)

    setListAllMenus(modifiedMenu)

    const isAnyCheckboxChecked = previousState.every((previousStateData, index) => previousStateData.isChecked === modifiedMenu[index]?.isChecked)

    setIsAnyCheckboxChecked(isAnyCheckboxChecked)
    setMenuIds(selectedIds.toString())
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.getSubscription)
  }

  const handleEditButton = () => {
    setCheckBoxDisabled(!checkBoxDisabled)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  return (
    <div className="subscription-map-menu-container" data-testid="map-menu">
      <div className="subscription-menu-container-head">
        <div className="menu-heading">{t('SUBSCRIPTION_MENU')}</div>
        <div className="menu-edit-button">
          {checkBoxDisabled && (
            <div>
              <ButtonComponent type="edit" onClick={handleEditButton} />
            </div>
          )}
        </div>
      </div>

      <div className="subscription-menu-wrapper" data-testid="map-menu-wrapper">
        {listAllMenus.map((element) => (
          <div key={element.key} className="subscription-menu-data">
            <div>
              <Checkbox disabled={checkBoxDisabled} className="menu-check-box" inputId={element.key} value={element.key} checked={element.isChecked} onChange={() => handleCheckboxChange(element.id)} />
            </div>
            <div className="map-menu-menus">{element.menuName}</div>
          </div>
        ))}
      </div>
      {!checkBoxDisabled && (
        <div className="submit-button">
          <ButtonComponent type="SUBMIT" onClick={updateMenuData} disabled={isAnyCheckboxChecked} />
        </div>
      )}
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(MapMenu)
