import './styles.scss'
import Variable from 'theme.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { Editor } from '@tinymce/tinymce-react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { FileUpload } from 'primereact/fileupload'
import { InputText } from 'primereact/inputtext'
import { Image } from 'primereact/image'
import { Calendar } from 'primereact/calendar'
import { Messages } from 'primereact/messages'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { COLOR_CONSTANTS, REG_EX, ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import Validation from 'Utils/Validation'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import { ButtonComponent } from 'Components/UIComponents'
import EditModalComponent from 'Screens/NewsFeed/Components/EditNewsFeedModal/index'

const EditNewsFeed = ({ setLoading, mockSetLoading, mockSubscriptionLockType, mockSubscriptionType, mockEditNewsFeedFlag }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const remarkMessage = useRef(null)
  const isInputDisable = location.state
  const { id: newsId } = useParams()
  const tinyEditorRef = useRef(null)
  const imageDivHeight = useRef(null)
  const params = new URLSearchParams()
  const currentTimeInUserTimeZone = new Date()
  const currentTime = moment(currentTimeInUserTimeZone).format('YYYY-MM-DDTHH:mm:ss')
  const userDetails = getLocalUserDetails()
  const [subscriptionLockType, setSubscriptionLockType] = useState([])
  const [subscriptionType, setSubscriptionType] = useState([])
  const [newsFeedMedia, setNewsFeedMedia] = useState([])
  const [country, setCountry] = useState([])
  const [language, setLanguage] = useState([])
  const [uploadedImages, setUploadedImages] = useState([])
  const [editNewsFeedData, setEditNewsFeed] = useState({})
  const [errorTrendingNewsTimeLimit, setErrorTrendingNewsTimeLimit] = useState('')
  const [selectedSubscriptionIndex, setSelectedSubscriptionIndex] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [newsFeedErrorState, setNewsFeedErrorState] = useState(false)
  const [boldText, setBoldText] = useState(false)
  const [isFreeNewsChecked, setIsFreeNewsChecked] = useState(null)
  const [isFullNewsChecked, setIsFullNewsChecked] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [selectedCountries, setSelectedCountries] = useState([])
  const [publishedDate, setPublishedDate] = useState(null)
  const [imageContainerHeight, setImageContainerHeight] = useState(0)
  const [defaultImages, setDefaultImages] = useState(null)
  const [selectedEditImages, setSelectedEditImages] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [titleNameData, setTitleNameData] = useState('')
  const [breadCrumbData, setBreadCrumbData] = useState('')
  const [textCount, setTextCount] = useState({
    authorCount: 0,
    headingCount: 0,
    descriptionCount: 0
  })
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    softAction: '',
    onRetry: null
  })
  const { id, author, newsHtml, newsPlain, subject, fileName, filePath, shortDescription, newsForSub, newsForCountries, languageId, newsForLock, trendingNews, trendingNewsColour, trendingNewsTimeLimit, searchTag, createdBy, publishedOn } = editNewsFeedData
  const { authorCount, headingCount, descriptionCount } = textCount

  useEffect(() => {
    loadNewsToEdit()

    const adminTitleData = localStorage.getItem('adminPreviewData') || localStorage.getItem('rejectRemarkData')

    if (adminTitleData) {
      const { titleName, breadCrumbTitle } = JSON.parse(adminTitleData)

      setTitleNameData(titleName)
      setBreadCrumbData(breadCrumbTitle)
    } else {
      setTitleNameData('EDIT_NEWS_FEED')
      setBreadCrumbData('editNewsFeed')
    }
  }, [])

  useEffect(() => {
    const updateDivHeight = () => {
      const inputContainerHeight = parseInt(Variable.tinyEditorHeight, 10) + imageDivHeight.current.scrollHeight

      setImageContainerHeight(window.innerWidth >= parseInt(Variable.smallDevice, 10) ? inputContainerHeight : 'auto')
    }

    updateDivHeight()
    window.addEventListener('resize', updateDivHeight)

    return () => {
      window.removeEventListener('resize', updateDivHeight)
    }
  }, [newsFeedMedia])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockEditNewsFeedFlag) {
      setLoading(mockSetLoading)
      setSubscriptionType(mockSubscriptionType)
      setSubscriptionLockType(mockSubscriptionLockType)
      loadInitialData()
      postCoffeeNewsData()
    }
  }, [])

  const loadNewsToEdit = async () => {
    setIsButtonDisabled(true)
    setLoading(true)
    setModalState({ showModal: false })
    try {
      const response = await apiAdapterCoffeeWeb.getNewsAndMediaById(newsId)
      const newsFeedData = response.data.returnLst[0]
      const { newsForSub, newsForLock, newsForCountries, nwsFeedMedia, trendingNewsColour, subject, author, shortDescription, publishedOn, languageId, isRejected, sadmRejectRemark, admRejectRemark, freeNews, showFullfreeNews } = newsFeedData

      loadInitialData(newsForSub, newsForLock, newsForCountries, languageId, nwsFeedMedia)
      setPublishedDate(new Date(publishedOn))
      setNewsFeedMedia(nwsFeedMedia)
      setUploadedImages(nwsFeedMedia.map((ele) => ele.nameOfMedia))
      setSelectedColor(trendingNewsColour)
      setIsFreeNewsChecked(freeNews)
      setIsFullNewsChecked(showFullfreeNews)
      setTextCount({ authorCount: author.length, headingCount: subject.length, descriptionCount: shortDescription.length })
      if (subject.includes('<b>')) {
        const textValue = document.getElementById('news-heading-text')
        const textBoldNews = `<b>${subject}</b>`.replace(REG_EX.TINY_REG, '')

        setEditNewsFeed({ ...newsFeedData, subject: textBoldNews })
        setBoldText(true)
        textValue.style.fontWeight = `${Variable.fontBold}`
        setTextCount({ authorCount: author.length, headingCount: subject.length - 7, descriptionCount: shortDescription.length })
      } else {
        setEditNewsFeed(newsFeedData)
      }
      rejectRemarkMessage(isRejected, sadmRejectRemark, admRejectRemark)
    } catch {
      setLoading(false)
      setModalState({
        showModal: true,
        message: t('NO_DATA_FOUND'),
        modalType: 'error',
        onRetry: commonMethods.handleRetry
      })
    }
  }
  const loadInitialData = async (newsForSub, newsForLock, newsForCountries, languageId, nwsFeedMedia) => {
    setLoading(true)
    const fetchAllLanguages = apiAdapterCoffeeWeb.getAllLanguages()
    const fetchSubscriptionTypes = apiAdapterCoffeeWeb.getSubscriptionTypes()
    const fetchAllCountries = apiAdapterCoffeeWeb.getAllCountries()
    const fetchAllDefaultImages = apiAdapterCoffeeWeb.getAllDefaultImages()

    try {
      const [fetchAllLanguagesResponse, fetchSubscriptionTypesResponse, fetchAllCountriesResponse, fetchAllDefaultImagesResponse] = await Promise.all([fetchAllLanguages, fetchSubscriptionTypes, fetchAllCountries, fetchAllDefaultImages])
      const selectedLanguageId = fetchAllLanguagesResponse.data.returnLst.find((ele) => ele.idLanguage === languageId)
      const subscriptionSelectedWithLockId = newsForLock.split(',').map(Number)
      const subscriptionTypeLock = fetchSubscriptionTypesResponse.data.returnLst.map((ele) => ({ ...ele, isChecked: subscriptionSelectedWithLockId.includes(ele.id) }))
      const countryIds = newsForCountries.split(',').map(Number)
      const countryData = fetchAllCountriesResponse.data.filter((ele) => countryIds.includes(ele.id))
      const subscriptionSelectedId = newsForSub.split(',').map(Number)
      const subscriptionType = fetchSubscriptionTypesResponse.data.returnLst.map((ele) => ({ ...ele, isChecked: subscriptionSelectedId.includes(ele.id) }))

      setSubscriptionLockType(subscriptionTypeLock)
      setSubscriptionType(subscriptionType)
      setSelectedSubscriptionIndex(subscriptionType.length)
      setSelectedLanguage(selectedLanguageId)
      setSelectedCountries(countryData)
      // setTinyApiKey(fetchAppSettingResponse.data.editerWebsiteUrl)
      setDefaultImages(fetchAllDefaultImagesResponse.data)
      setLanguage(fetchAllLanguagesResponse.data.returnLst)
      setCountry(fetchAllCountriesResponse.data)
    } catch {
      setModalState({
        showModal: true,
        message: t('NO_DATA_FOUND'),
        modalType: 'error',
        onRetry: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
    }
  }
  const uploadMediaToS3 = async (event) => {
    setLoading(true)
    setIsButtonDisabled(false)
    const selectedFile = event.files[0]
    const { type, name, size } = selectedFile
    const fileData = new FormData()

    fileData.append('file', selectedFile)
    try {
      const { data } = await apiAdapterCoffeeWeb.uploadFileToS3Bucket(fileData)

      if (data) {
        if (type.includes('image') || type.includes('video')) {
          const obj = {
            idNewsFeedMedia: 0,
            idNewsFeed: 0,
            createdOn: new Date(currentTime).toJSON().toString(),
            createdBy: userDetails.id,
            updateOn: new Date(currentTime).toJSON().toString(),
            updatedBy: 0,
            typeMedia: `${type.split('/')[0]}`,
            isActive: true,
            nameOfMedia: name,
            extensionOfMedia: `${type.split('/')[1]}`,
            pathOfMedia: data,
            sizeOfMedia: size,
            mediaData: `${type.split('/')[0]}`,
            uploadedFileName: data.split('/')[3]
          }

          setNewsFeedMedia([...newsFeedMedia, obj])
          setUploadedImages([...uploadedImages, name])
        } else if (name.includes('pdf')) {
          setEditNewsFeed({
            ...editNewsFeedData,
            fileType: 'pdf',
            fileName: name,
            filePath: data
          })
        } else if (name.includes('xlsx')) {
          setEditNewsFeed({
            ...editNewsFeedData,
            fileType: 'xlsx',
            fileName: name,
            filePath: data
          })
        }
      }
    } catch {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_UPLOAD'),
        modalType: 'error'
      })
    } finally {
      setLoading(false)
    }
  }
  const imageUploadInTinyContainer = (blobInformation) =>
    new Promise((resolve) => {
      const processImageData = new FormData()

      processImageData.append('file', blobInformation.blob(), blobInformation.filename())
      apiAdapterCoffeeWeb.uploadFileToS3Bucket(processImageData).then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        }
      })
    })
  const postCoffeeNewsData = async () => {
    setLoading(true)
    const newsFeedPostData = {
      newsFeed: {
        id,
        author,
        newsHtml,
        newsPlain,
        subject: boldText ? `<b>${subject}</b>` : subject,
        thumbnail: '',
        newsDate: currentTime,
        createdOn: currentTime,
        updatedOn: currentTime,
        createdBy,
        updatedBy: userDetails?.id,
        isActive: true,
        views: 0,
        canDownload: true,
        fileName,
        filePath,
        fileSize: 0,
        newsSource: 0,
        reasonToDelete: '',
        shortDescription,
        newsForSub,
        newsForCountries,
        preferredlanguage: '',
        isAdmVerified: true,
        admIdUser: userDetails?.id === 2 ? userDetails?.id : 0,
        admApproveDtm: currentTime,
        admRejectRemark: '',
        isSAVerified: true,
        sAIdUser: userDetails?.id === 1 ? userDetails?.id : 0,
        saApproveDtm: currentTime,
        sadmRejectRemark: '',
        reportLink: 'NA',
        newsFavouriteCount: 0,
        languageId,
        newsForLock,
        publishedOn,
        trendingNews,
        sendNotification: true,
        searchTag,
        trendingNewsColour,
        trendingNewsTimeLimit,
        freeNews: isFreeNewsChecked,
        showFullfreeNews: isFullNewsChecked
      },
      newsFeedMedia
    }

    try {
      const response = await apiAdapterCoffeeWeb.insertNewsFeed(newsFeedPostData)

      if (response.status === 200) {
        setModalState({
          showModal: true,
          message: t('NEWS_FEED_UPDATED_SUCCESSFULLY'),
          modalType: 'success',
          softAction: 'OK'
        })
      }
    } catch {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_UPDATE_NEWS_FEED'),
        modalType: 'error',
        onRetry: postCoffeeNewsData
      })
    } finally {
      setLoading(false)
    }
  }
  const deleteNewsMediaAsset = async (newsFeedMediaId) => {
    try {
      await apiAdapterCoffeeWeb.deleteNewsMedia(newsFeedMediaId)
    } catch {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_DELETE_IMAGE'),
        modalType: 'error',
        onRetry: deleteNewsMediaAsset
      })
    }
  }
  const handleChangeCoffeeNewsFeedTinyInput = (event, editor) => {
    setIsButtonDisabled(false)
    const newsPlainText = editor.getContent().replace(REG_EX.TINY_REG, '')

    setEditNewsFeed({
      ...editNewsFeedData,
      newsHtml: editor.getContent(),
      newsPlain: newsPlainText
    })
  }
  const handleChangeCoffeeNewsFeedInputs = (e) => {
    setIsButtonDisabled(false)
    const { name, value } = e.target

    if (name === 'news-author') {
      setEditNewsFeed({
        ...editNewsFeedData,
        author: value
      })
      setTextCount({
        ...textCount,
        authorCount: value.length
      })
    } else if (name === 'news-heading') {
      setEditNewsFeed({
        ...editNewsFeedData,
        subject: value
      })
      setTextCount({
        ...textCount,
        headingCount: value.length
      })
    } else if (name === 'short-description') {
      setEditNewsFeed({
        ...editNewsFeedData,
        shortDescription: value
      })
      setTextCount({
        ...textCount,
        descriptionCount: value.length
      })
    } else if (name === 'languageId') {
      setEditNewsFeed({
        ...editNewsFeedData,
        languageId: Number(value)
      })
    } else if (name === 'trending-news-hours') {
      if (Validation.numberValidation(value) || value === '') {
        setErrorTrendingNewsTimeLimit(`${t('HOURS_LIMIT')}`)
        if ((value > 0 && value <= 200) || value === '') {
          setEditNewsFeed({ ...editNewsFeedData, trendingNewsTimeLimit: value })
        }
        if (value.length > 0) {
          setErrorTrendingNewsTimeLimit('')
        }
      } else {
        setErrorTrendingNewsTimeLimit(`${t('HOURS_IN_NUMBER')}`)
      }
    } else if (name === 'default-image-dropdown') {
      const imageSelected = e.value
      const { imageUrl, nameOfMedia, typeOfMedia, uploadedFileName, extensionOfMedia, sizeOfMedia } = imageSelected
      const imageObject = {
        idNewsFeedMedia: 0,
        idNewsFeed: 0,
        createdOn: new Date(currentTime).toJSON().toString(),
        createdBy: userDetails.id,
        updateOn: new Date(currentTime).toJSON().toString(),
        updatedBy: 0,
        typeMedia: typeOfMedia,
        isActive: true,
        nameOfMedia: nameOfMedia,
        extensionOfMedia: extensionOfMedia,
        pathOfMedia: imageUrl,
        sizeOfMedia: sizeOfMedia,
        mediaData: nameOfMedia,
        uploadedFileName: uploadedFileName
      }

      setNewsFeedMedia([...newsFeedMedia, imageObject])
      setUploadedImages([...uploadedImages, name])
    }
  }
  const handleSelectedCountries = (e) => {
    setIsButtonDisabled(false)
    setSelectedCountries(e.value)
    const selectedCountriesId = e.value.map(({ id }) => id).toString()

    setEditNewsFeed({ ...editNewsFeedData, newsForCountries: selectedCountriesId })
  }
  const handleChangeSubscription = (item, index) => {
    setSelectedSubscriptionIndex(index + 1)
    setIsButtonDisabled(false)
    if (item.isChecked === true) {
      setSubscriptionType(subscriptionType.map((ele) => ({ ...ele, isChecked: false })))
      setEditNewsFeed({ ...editNewsFeedData, newsForSub: '' })
      setSelectedSubscriptionIndex(0)
    } else {
      const modifiedSubList = subscriptionType.map((ele, ind) => ({
        ...ele,
        isChecked: (item.isChecked && ind <= index) || (!item.isChecked && ind >= index)
      }))
      const selectedNewsId = modifiedSubList.filter((ele) => ele.isChecked).map((ele) => ele.id)

      setSubscriptionType(modifiedSubList)
      setEditNewsFeed({ ...editNewsFeedData, newsForSub: selectedNewsId.toString() })
    }
  }
  const handleChangeSubscriptionLock = (item) => {
    setIsButtonDisabled(false)
    const updatedSubscriptionLock = subscriptionLockType.map((ele) => (ele.id === item.id ? { ...ele, isChecked: !item.isChecked } : ele))
    const subscriptionLockId = updatedSubscriptionLock.filter((ele) => ele.isChecked).map((ele) => ele.id)

    setEditNewsFeed({ ...editNewsFeedData, newsForLock: subscriptionLockId.toString() })
    setSubscriptionLockType(updatedSubscriptionLock)
  }
  const updateNewsFeed = () => {
    if (!newsHtml || !author || !subject || !shortDescription || !languageId || !newsForCountries || !selectedSubscriptionIndex) {
      setNewsFeedErrorState(true)

      return false
    }
    postCoffeeNewsData()
  }
  const handleColorSelect = (color) => {
    setIsButtonDisabled(false)
    setSelectedColor((prevColor) => (prevColor === color ? '' : color))

    setEditNewsFeed({
      ...editNewsFeedData,
      trendingNewsColour: editNewsFeedData.trendingNewsColour === color ? '' : color
    })
  }
  const makeTextFieldBold = () => {
    setIsButtonDisabled(false)
    const textValue = document.getElementById('news-heading-text')

    if (boldText) {
      textValue.style.fontWeight = `${Variable.fontNormal}`
      setBoldText(false)
    } else {
      textValue.style.fontWeight = `${Variable.fontBold}`
      setBoldText(true)
    }
  }
  const selectTrendingNews = () => {
    if (editNewsFeedData.trendingNews) {
      setEditNewsFeed({
        ...editNewsFeedData,
        trendingNews: false
      })
    } else {
      setEditNewsFeed({
        ...editNewsFeedData,
        trendingNews: true,
        trendingNewsColour: editNewsFeedData.trendingNewsColour,
        trendingNewsTimeLimit: editNewsFeedData?.trendingNewsTimeLimit
      })
    }
  }
  const handleCheckboxChange = () => {
    setIsButtonDisabled(false)
    setIsFreeNewsChecked(!isFreeNewsChecked)
    if (isFreeNewsChecked) {
      setIsFullNewsChecked(false)
    }
  }
  const handleFullNewsCheckboxChange = () => {
    setIsButtonDisabled(false)
    setIsFullNewsChecked(!isFullNewsChecked)
  }
  const rejectRemarkMessage = (isRejected, sadmRejectRemark, admRejectRemark) => {
    if (remarkMessage.current) {
      remarkMessage.current.clear()
      const newsRemark = userDetails?.id === 1 ? sadmRejectRemark : admRejectRemark

      if (isRejected) {
        remarkMessage.current.show({
          severity: 'warn',
          sticky: true,
          closable: false,
          content: (
            <React.Fragment>
              <div>
                <div>{t('REJECT_REMARK')} :</div>
                <div>{newsRemark}</div>
              </div>
            </React.Fragment>
          )
        })
      }
    }
  }
  const onDismissFunction = () => {
    setModalState({ showModal: false })
    if (modalState.modalType === 'success') redirectPage()
  }

  const redirectPage = () => {
    params.set('language', languageId)
    history.push({
      pathname: ROUTE_STRINGS.listNewsFeed,
      search: params.toString()
    })
  }

  const handleLanguageDropdown = ({ value }) => {
    setSelectedLanguage(value)
    setEditNewsFeed({ ...editNewsFeedData, languageId: value.idLanguage })
    setIsButtonDisabled(false)
  }

  const changeDataForPublishedOn = (changedDateAndTime) => {
    setIsButtonDisabled(false)
    const selectedDate = changedDateAndTime
    const year = selectedDate.getFullYear()
    const month = selectedDate.getMonth() + 1
    const date = selectedDate.getDate()
    const hours = selectedDate.getHours()
    const minutes = selectedDate.getMinutes()
    const seconds = selectedDate.getSeconds()

    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

    setPublishedDate(new Date(formattedDate))
    setEditNewsFeed({ ...editNewsFeedData, publishedOn: formattedDate })
  }

  const selectedTrendingNewsColor = (selectedColor) => ({
    color: editNewsFeedData.trendingNews ? selectedColor : Variable.trendingNewsDefaultColor
  })

  const handleResetFunction = () => {
    setModalState({
      showModal: true,
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      modalType: 'info'
    })
  }

  const pickTrendingNewsColor = (color) => ({
    backgroundColor: color,
    border: color === selectedColor ? `2px solid ${Variable.trendingNewsDefaultColor} ` : 'none'
  })

  const countryFlag = (option) => (
    <div className="country-flag">
      <Image alt={option.iso} src={option.flagsUrl} />
      <div className="edit-news-country-name">{option.name}</div>
    </div>
  )
  const inputWrapper = {
    height: imageContainerHeight
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <LayoutContainer title={titleNameData} breadCrumbsTitle={breadCrumbData} data-testid="breadcrumbs-title">
      <div className="rejected-news-remark">
        <Messages ref={remarkMessage} />
      </div>
      <div className="edit-news-container">
        <div className="edit-news-editor-wrapper">
          <div data-testid="editor">
            <Editor
              name="newsHtml"
              value={newsHtml}
              onEditorChange={handleChangeCoffeeNewsFeedTinyInput}
              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
              disabled={isInputDisable}
              onInit={(event, editor) => {
                tinyEditorRef.current = editor
              }}
              init={{
                selector: 'textarea#paste-from-word',
                height: Variable.tinyEditorHeight,
                menubar: true,
                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'advcode', 'fullscreen', 'insertdatetime', 'media', 'table', 'powerpaste', 'code'],
                toolbar: 'undo redo | insert | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code',
                powerpaste_allow_local_images: true,
                powerpaste_word_import: 'prompt',
                powerpaste_html_import: 'prompt',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                images_upload_handler: imageUploadInTinyContainer,
                images_upload_credentials: true
              }}
            />
          </div>
          <div className="edit-news-image-wrapper" ref={imageDivHeight}>
            <EditModalComponent isModalOpen={isModalOpen} closeModal={closeModal} defaultImages={defaultImages} uploadMediaToS3={uploadMediaToS3} setNewsFeedMedia={setNewsFeedMedia} newsFeedMedia={newsFeedMedia} selectedEditImages={selectedEditImages} setIsButtonDisabled={setIsButtonDisabled} isInputDisable={isInputDisable} />
          </div>
        </div>
        <div className="edit-news-input-wrapper">
          <div className="input-wrapper" style={inputWrapper}>
            <div className="edit-news-author">
              <div className={!author && newsFeedErrorState ? 'mandatory-label' : ''}>
                <span className="card-label">{t('NEWS_AUTHOR')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card">
                <div className="author-text-limit">{authorCount}/50</div>
                <InputTextarea name="news-author" className={!author && newsFeedErrorState ? 'input-width p-invalid' : 'input-width'} placeholder={t('NEWS_AUTHOR')} value={author} onChange={handleChangeCoffeeNewsFeedInputs} rows={2} cols={30} maxLength={50} disabled={isInputDisable} data-testId="newsAuthorField" />
                {!author && newsFeedErrorState && <span className="error-message">{t('PLEASE_FILL_THIS')}</span>}
              </div>
            </div>
            <div className="edit-news-heading">
              <div className={!subject && newsFeedErrorState ? 'mandatory-label' : ''}>
                <span className="card-label">{t('NEWS_HEADING')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card">
                <div className="heading-option">
                  <div className="trending-checkbox">
                    <Checkbox onClick={selectTrendingNews} checked={trendingNews} disabled={isInputDisable} />
                    <label htmlFor="ingredient1">{t('TRENDING_NEWS')}</label>
                  </div>
                  <div className="bold-checkbox">
                    <Checkbox onClick={makeTextFieldBold} checked={boldText} disabled={isInputDisable} /> <span>{t('BOLD')}</span>
                  </div>
                  <div className="bold-checkbox">
                    <Checkbox checked={isFreeNewsChecked} disabled={isInputDisable} onChange={handleCheckboxChange} /> <span>{t('FREE_NEWS')}</span>
                  </div>
                  {isFreeNewsChecked && (
                    <div className="bold-checkbox">
                      <Checkbox checked={isFullNewsChecked} disabled={isInputDisable} onChange={handleFullNewsCheckboxChange} /> <span>{t('FULL_NEWS')}</span>
                    </div>
                  )}
                </div>
                <div className="heading-text-limit">{headingCount}/150</div>
                <InputTextarea name="news-heading" id="news-heading-text" className={!subject && newsFeedErrorState ? 'p-invalid input-width' : 'input-width'} placeholder={t('NEWS_HEADING')} value={subject} onChange={handleChangeCoffeeNewsFeedInputs} rows={3} cols={30} maxLength={150} style={selectedTrendingNewsColor(selectedColor)} disabled={isInputDisable} data-testId="newsHeadingField" />
                {!subject && newsFeedErrorState && <span className="error-message">{t('PLEASE_FILL_THIS')}</span>}
                {trendingNews && (
                  <div className="trending-edit-news-wrapper">
                    <span>{t('TRENDING_NEWS_COLORS')}:</span>
                    <div className="card">
                      <div>
                        {COLOR_CONSTANTS.TRENDING_NEWS_COLORS.map((color, index) => (
                          <Button key={index} onClick={() => handleColorSelect(color)} className="color-button" size="small" style={pickTrendingNewsColor(color)} disabled={isInputDisable} data-testId="colorButton" />
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="edit-news-trending">
                        <div>
                          <span className={trendingNews && !trendingNewsTimeLimit && newsFeedErrorState ? 'mandatory-label' : ''}>{t('TRENDING_NEWS_HOURS')}</span>
                          <span className="star-required"> *</span>
                        </div>
                        {errorTrendingNewsTimeLimit && <span className="error-message">{errorTrendingNewsTimeLimit} </span>}
                      </div>
                      <InputText name="trending-news-hours" id="integer" keyfilter="int" className={trendingNews && !trendingNewsTimeLimit && newsFeedErrorState ? 'p-invalid input-width' : 'input-width'} size="small" placeholder={t('TRENDING_NEWS_HOURS')} onChange={handleChangeCoffeeNewsFeedInputs} value={trendingNewsTimeLimit} disabled={isInputDisable} data-testId="trendingNewsHours" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="edit-news-description">
              <div className={!shortDescription && newsFeedErrorState ? 'mandatory-label' : ''}>
                <span className="card-label">{t('SHORT_DESCRIPTION')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card">
                <div className="description-text-limit">{descriptionCount}/150</div>
                <InputTextarea name="short-description" className={!shortDescription && newsFeedErrorState ? 'p-invalid input-width' : 'input-width'} placeholder={t('SHORT_DESCRIPTION')} value={shortDescription} onChange={handleChangeCoffeeNewsFeedInputs} rows={3} cols={30} maxLength={150} disabled={isInputDisable} data-testId="shortDescriptionField" />
                {!shortDescription && newsFeedErrorState && <span className="error-message">{t('PLEASE_FILL_THIS')}</span>}
              </div>
            </div>
            <div className="edit-news-language">
              <div className={!selectedLanguage && newsFeedErrorState ? 'mandatory-label' : ''}>
                <span className="card-label">{t('LANGUAGE')}</span>
                <span className="star-required"> *</span>
                <div className="card">
                  <Dropdown value={selectedLanguage} options={language} optionLabel="languageName" placeholder={t('SELECT_LANGUAGE')} className={!selectedLanguage && newsFeedErrorState ? 'p-invalid input-width' : 'input-width'} onChange={(e) => handleLanguageDropdown(e)} disabled={isInputDisable} data-testId="languageDropdown" />
                  {!selectedLanguage && newsFeedErrorState && <span className="error-message">{t('LANGUAGE_IS_REQUIRED')}</span>}
                </div>
              </div>
            </div>
            <div className="edit-news-subscription-type">
              <div className={!selectedSubscriptionIndex && newsFeedErrorState ? 'mandatory-label' : ''}>
                <span className="card-label">{t('SUBSCRIPTION_TYPE')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card subscription-lock-checkbox" data-testId="subscriptionTypeContainer">
                {subscriptionType.map((ele, index) => (
                  <div key={ele?.key} className="check-box-wrapper">
                    <Checkbox inputId={ele?.key} name="subscription-type" value={ele} checked={ele?.isChecked} onChange={(e) => handleChangeSubscription(ele, index)} className={!selectedSubscriptionIndex && newsFeedErrorState ? 'p-invalid' : ''} disabled={isInputDisable} data-testId="subscriptionLockCheckbox" />
                    <label htmlFor={ele?.key} className="check-box-label" data-testId="subscriptionLockLabel">
                      {ele?.name}
                    </label>
                  </div>
                ))}
                {!selectedSubscriptionIndex && newsFeedErrorState && <span className="error-message">{t('PLEASE_SELECT_SUBSCRIPTION_TYPE')}</span>}
              </div>
            </div>
            <div className="edit-news-subscription-lock">
              <span className="card-label">{t('SUBSCRIPTION_LOCK')}</span>
              <div className="card subscription-lock-checkbox " data-testId="subscriptionLockContainer">
                {subscriptionLockType.map((ele, index) => (
                  <div key={ele?.key} className="check-box-wrapper">
                    <Checkbox inputId={ele?.key} name="subscription-type" value={ele} checked={ele?.isChecked} onChange={(e) => handleChangeSubscriptionLock(ele)} disabled={isInputDisable} data-testId="subscriptionTypeCheckbox" />
                    <label htmlFor={ele?.key} className="check-box-label" data-testId="subscriptionTypeLabel">
                      {ele?.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="edit-news-country-list">
              <div className={newsFeedErrorState && !newsForCountries ? 'mandatory-label' : ''}>
                <span className="card-label">{t('COUNTRY')}</span>
                <span className="star-required"> *</span>
              </div>
              <div className="card">
                <MultiSelect value={selectedCountries} onChange={(e) => handleSelectedCountries(e)} options={country} itemTemplate={countryFlag} optionLabel="nicename" filter placeholder={t('COUNTRY')} maxSelectedLabels={3} className={!selectedCountries && newsFeedErrorState ? 'p-invalid' : ''} disabled={isInputDisable} data-testId="countryDropdown" />
                {!selectedCountries && newsFeedErrorState && <span className="error-message">{t('PLEASE_SELECT_COUNTRY_TYPE')}</span>}
              </div>
            </div>
            <div className="image-upload-button">
              <Button label="Default Thumbnail Images" className="default-button" icon="pi pi-external-link" onClick={openModal} disabled={isInputDisable} data-testId="default-thumbnail-button" />
            </div>

            <div className="edit-news-published-date">
              <span className="card-label">{t('PUBLISHED_DATA')}</span>
              <span className="star-required"> *</span>
              <div className="card">
                <Calendar id="calendar-12h" value={publishedDate} onChange={(e) => changeDataForPublishedOn(e.value)} showTime hourFormat="12" showIcon disabled={isInputDisable} data-testId="buildDateCalendar" />
              </div>
            </div>
            <div className="edit-news-file-input">
              <span className="card-label">{t('UPLOAD_DOCUMENT')}</span>
              <div className="file-upload">
                <div className="p-inputgroup">
                  <FileUpload mode="basic" chooseLabel={t('CHOOSE')} accept=".xlsx,.pdf" auto onSelect={(e) => uploadMediaToS3(e)} className="file-upload-button" disabled={isInputDisable} data-testid="fileUploadButton" />
                  <InputText disabled placeholder={t('NAME_OF_THE_DOCUMENT_FILE')} className="input-width" value={fileName} data-testId="documentFileField" />
                </div>
              </div>
            </div>
          </div>
          <div className="edit-news-action-button">
            <ButtonComponent type="reset" onClick={handleResetFunction} disabled={isButtonDisabled} />
            <ButtonComponent type="submit" onClick={updateNewsFeed} disabled={isButtonDisabled} />
          </div>
        </div>
      </div>
      <DialogModal {...modalState} onDismiss={onDismissFunction} onSuccess={redirectPage} onConfirmation={loadNewsToEdit} onHide={onDismissFunction} />
    </LayoutContainer>
  )
}

export default Loader(EditNewsFeed)
