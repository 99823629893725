import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import Loader from 'Components/Loader'
import { TABLE_CONST } from 'Utils/Constants'
import coffeeReportJsonLinks from 'Utils/Constants/coffeeReportsJsonLink'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import AddCountryStockReport from './AddCountryStockReport'
import EditAllCountryWiseStockReport from './EditAllCountryWiseStockReport'

const AllCountryStockReport = ({ setLoading }) => {
  const marketName = [
    {
      idMarket: 1,
      marketName: 'Robusta',
      marketCode: 'RC'
    },
    {
      idMarket: 2,
      marketName: 'Arabica',
      marketCode: 'KC'
    }
  ]

  const { t } = useTranslation()
  let rowCount = 8
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const { robustaAllCountryCertifiedStocks, arabicaAllCountryCertifiedStocks } = coffeeReportJsonLinks

  const [selectedDate, setSelectedDate] = useState('')
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [coffeeReportDate, setCoffeeReportDate] = useState([])
  const [coffeeReportData, setCoffeeReportData] = useState([])
  const [fullReportData, setFullReportData] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tableRowData, setTableRowData] = useState({})
  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [selectedItem, setSelectedItem] = useState({
    idMarket: 1,
    marketName: 'Robusta',
    marketCode: 'RC'
  })

  const reportName = selectedItem.idMarket === 1 ? robustaAllCountryCertifiedStocks : arabicaAllCountryCertifiedStocks

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    fetchReportData()
  }, [selectedItem])

  const fetchReportData = async () => {
    handleDismiss()
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })
      const { date, countryCertifiedData } = response.data[0]

      const reportDate = response?.data.map((ele) => ({ date: ele.date }))

      setFullReportData(response.data)
      setSelectedDate({ date: date })
      setCoffeeReportDate(reportDate)
      setCoffeeReportData(countryCertifiedData)
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => fetchReportData(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const deleteReportData = async (rowData) => {
    setLoading(true)
    setErrorModal({ ...errorModal, showModal: false })

    const { idMarket } = selectedItem
    const { origin } = rowData
    const { date } = selectedDate

    try {
      const response = await apiAdapterCoffeeWeb.deleteAllCountryWiseCertifiedStockReport({ fileName: reportName, date: date, origin: origin, MarketId: idMarket })
      const filteredData = response?.data.filter((data) => data?.date === date)

      if (response.status === 200) {
        setErrorModal({
          ...errorModal,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_DELETED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })

        setCoffeeReportData(filteredData[0].countryCertifiedData)
      }
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_DELETE_REPORT_DATA'),
        onRetry: () => deleteReportData(rowData),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const deleteConfirmation = (rowData) => {
    setErrorModal({
      ...errorModal,
      showModal: true,
      modalType: 'info',
      message: t('DELETE_USDA_COFFEE_REPORT'),
      onConfirmation: () => deleteReportData(rowData),
      onHide: handleDismiss
    })
  }

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleEditClick = (rowDetails, date) => {
    setTableRowData({ rowDetails, date })
    setIsModalOpen(true)
  }

  const actionTemplate = (rowData) => (
    <div className="action-button-wrapper">
      <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditClick(rowData, selectedDate)} />
      <Button type="button" icon="pi pi-trash" className="deleted-button" tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }} onClick={() => deleteConfirmation(rowData)} />
    </div>
  )

  const columns = [
    {
      dataField: 'origin',
      text: t('ORIGIN')
    },
    {
      dataField: 'valid',
      text: t('VALID')
    },
    {
      dataField: 'compareToPreDay',
      text: t('COMPARE_TO_PRE_DAY')
    },
    {
      dataField: 'NonTenderable',
      text: t('NON_TENDERABLE')
    },
    {
      dataField: 'suspend',
      text: t('SUSPENDED')
    },
    {
      text: t('ACTIONS'),
      body: actionTemplate,
      className: 'action-column'
    }
  ]

  const tableColumn = [
    {
      dataField: 'origin',
      text: t('ORIGIN')
    },
    {
      dataField: 'antwerp',
      text: t('ANTWERP')
    },
    {
      dataField: 'hamburg',
      text: t('HAMBURG')
    },
    {
      dataField: 'houston',
      text: t('HOUSTON')
    },
    {
      dataField: 'newOrleans',
      text: t('NEW_ORLEANS')
    },
    {
      dataField: 'newYork',
      text: t('NEW_YORK')
    },
    {
      dataField: 'virginia',
      text: t('VIRGINIA')
    },
    {
      dataField: 'compareToPreDay',
      text: t('COMPARE_TO_PRE_DAY')
    },
    {
      dataField: 'barcelona',
      text: t('BARCELONA')
    },
    {
      dataField: 'miami',
      text: t('MIAMI')
    },
    {
      text: t('ACTIONS'),
      body: actionTemplate,
      className: 'action-column'
    }
  ]

  const handleChange = (event) => {
    setCoffeeReportData([])
    const value = event.target.value

    setSelectedItem(value)
  }

  const handleDateSelection = (event) => {
    const dateValue = event.target.value

    setSelectedDate(dateValue)

    const coffeeReportByDate = fullReportData?.find((report) => report?.date === dateValue?.date)

    if (coffeeReportByDate) {
      setCoffeeReportData(coffeeReportByDate.countryCertifiedData)
    }
  }

  const handleAddButtonClick = () => {
    setShowDialog(true)
  }

  const handleDismiss = () => {
    setErrorModal({ ...errorModal, showModal: false })
  }

  return (
    <div className="country-wise-stock-report">
      <div className="header-section">
        <div className="dropdown-wrapper">
          <Dropdown value={selectedItem} options={marketName} optionLabel="marketName" onChange={handleChange} />
          <Dropdown value={selectedDate} options={coffeeReportDate} optionLabel="date" onChange={handleDateSelection} placeholder="Choose a Date" />
        </div>
        <div className="button-wrapper">
          <ButtonComponent type={'add'} onClick={handleAddButtonClick} />
        </div>
      </div>
      <div className="data-table-container">
        {selectedItem.idMarket === 1 ? (
          <DataTable value={coffeeReportData} paginator={coffeeReportData?.length} rows={rowCount} pageLinkSize={paginationButtons}>
            {columns.map((item, index) => (
              <Column key={index} field={item.dataField} header={item.text} body={item.body} className={item.className} />
            ))}
          </DataTable>
        ) : (
          <DataTable value={coffeeReportData} paginator={coffeeReportData?.length} rows={rowCount} pageLinkSize={paginationButtons}>
            {tableColumn.map((item, index) => (
              <Column key={index} field={item.dataField} header={item.text} body={item.body} className={item.className} />
            ))}
          </DataTable>
        )}
      </div>
      <DialogModal {...errorModal} onDismiss={handleDismiss} />
      <AddCountryStockReport showDialog={showDialog} setShowDialog={setShowDialog} selectedItem={selectedItem} marketName={marketName} coffeeReportData={coffeeReportData} reportName={reportName} setLoading={setLoading} />

      <EditAllCountryWiseStockReport selectedItem={selectedItem} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} tableRowData={tableRowData} reportName={reportName} setCoffeeReportData={setCoffeeReportData} />
    </div>
  )
}

export default Loader(AllCountryStockReport)
