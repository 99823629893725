import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'primereact/checkbox'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Loader from 'Components/Loader'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const SubscriptionFeature = ({ setLoading, mockSubscriptionFeature, mockSubscriptionFeatureAPIFlag }) => {
  const location = useLocation()

  const {
    state: { id: subscriptionId }
  } = location

  const { t } = useTranslation()
  const history = useHistory()

  const [subscriptionFeatures, setSubscriptionFeatures] = useState([])
  const [previousState, setPreviousState] = useState([])
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(true)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onSuccess: () => {} })

  useEffect(() => {
    init()

    // Testing Code mock SubscriptionFeature API Flag
    if (mockSubscriptionFeatureAPIFlag) {
      setSubscriptionFeatures(mockSubscriptionFeature)
      getSubscriptionDetailsId()
      addSubscriptionFeature()
    }
  }, [])

  const init = async () => {
    handleDismiss()

    setLoading(true)

    try {
      const { data } = await apiAdapterCoffeeWeb.getSubscriptionFeatureMaster()
      const sortedFeatureList = data.returnLst.sort((a, b) => a.subscriptionFeatureName.localeCompare(b.subscriptionFeatureName))

      setSubscriptionFeatures(sortedFeatureList)

      getSubscriptionDetailsId(subscriptionId)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('ERROR_FETCHING_FOR_SUBSCRIPTION_FEATURE'),
        onRetry: init,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const getSubscriptionDetailsId = async (id) => {
    handleDismiss()
    setLoading(true)
    try {
      const getSubscriptionDetailsResponse = await apiAdapterCoffeeWeb.getSubscriptionDetailsId(id)
      const sortedFeatureList = getSubscriptionDetailsResponse.data[0].subscriptionFeatures.sort((a, b) => a.feature.localeCompare(b.feature))

      setSubscriptionFeatures(sortedFeatureList)
      setPreviousState(sortedFeatureList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('ERROR_FETCHING_FOR_SUBSCRIPTION_FEATURE'),
        onRetry: init,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const addSubscriptionFeature = async () => {
    handleDismiss()
    setLoading(true)
    try {
      const addSubscriptionResponse = await apiAdapterCoffeeWeb.postSubscriptionFeatures(subscriptionFeatures)

      if (addSubscriptionResponse.status === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('SUBSCRIPTION_FEATURE_ADDED_SUCCESSFULLY'),
          onSuccess: handleSuccess,
          onHide: handleSuccess
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: addSubscriptionFeature,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCheckboxChange = (index, checked) => {
    const updatedFeatures = subscriptionFeatures.map((feature, i) => (i === index ? { ...feature, isAvailable: checked } : feature))

    const isAnyCheckboxChecked = previousState.every((previousStateData, i) => previousStateData.isAvailable === updatedFeatures[i]?.isAvailable)

    setSubmitButtonDisabled(isAnyCheckboxChecked)
    setSubscriptionFeatures(updatedFeatures)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.getSubscription)
  }

  const handleEditButton = () => {
    setIsCheckBoxDisabled(!isCheckBoxDisabled)
  }

  return (
    <>
      <div className="subscription-feature-container" data-testid="subscription-feature">
        <div className="subscription-feature-container-head">
          <div className="feature-heading">{t('SUBSCRIPTION_FEATURES')}</div>
          <div className="feature-edit-button">
            {isCheckBoxDisabled && (
              <div>
                <ButtonComponent type="edit" onClick={handleEditButton} />
              </div>
            )}
          </div>
        </div>

        <div className="subscription-feature-wrapper" data-testid="subscription-feature-wrapper">
          {subscriptionFeatures.length > 0 ? (
            subscriptionFeatures.map((e, index) => (
              <div className="feature-content-data" key={e.key}>
                <div className="check-box-container">
                  <Checkbox disabled={isCheckBoxDisabled} className="feature-check-box" inputId={e.key} value={e.key} checked={e.isAvailable} onChange={(e) => handleCheckboxChange(index, e.checked)} />
                </div>
                <div className="subscription-features">{e.subscriptionFeatureName || e.feature}</div>
              </div>
            ))
          ) : (
            <div>{t('NO_SCREENS_FOR_THIS_SUBSCRIPTION')}</div>
          )}
        </div>

        {!isCheckBoxDisabled && (
          <div className="feature-button-container">
            <ButtonComponent type="SUBMIT" onClick={addSubscriptionFeature} disabled={submitButtonDisabled} />
          </div>
        )}

        <DialogModal {...showDialog} onDismiss={handleDismiss} />
      </div>
    </>
  )
}

export default Loader(SubscriptionFeature)
