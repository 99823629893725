import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import Validation from 'Utils/Validation'
import Loader from 'Components/Loader'
import CountryCallingCodeSelector from 'Components/UIComponents/CountryCallingCodeSelector'
import { ButtonComponent } from 'Components/UIComponents'
import DialogModal from 'Components/UIComponents/DialogModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ProfileInformation = ({ setLoading, mockProfileInformation, mockAddUserData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userDetails = getLocalUserDetails()

  const [occupationDetails, setOccupationDetails] = useState([])
  const [userRoleTypesInfo, setUserRoleTypesInfo] = useState([])
  const [stateDropDown, setStateDropDown] = useState([])
  const [userRole, setUserRole] = useState('')
  const [stateDropDownId, setStateDropDownId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [emailExist, setEmailExist] = useState('')
  const [isEmailExist, setIsEmailExist] = useState(false)
  const [isPhoneExist, setIsPhoneExist] = useState(false)
  const [showDialog, setShowDialog] = useState({
    showModal: false,
    modalType: '',
    message: '',
    onRetry: '',
    onHide: () => {}
  })

  const [errorState, setErrorState] = useState({
    firstName: '',
    lastName: '',
    country: '',
    phone: '',
    email: '',
    occupationName: '',
    occupationId: '',
    password: '',
    confirmPassword: '',
    uerRoleName: '',
    userRoleId: '',
    userStateId: '',
    userStateName: ''
  })

  const [addUserData, setAddUserData] = useState({
    firstName: '',
    lastName: '',
    countryId: '102',
    countryPhoneCode: '91',
    phone: '',
    email: '',
    occupationName: '',
    occupationId: '',
    password: '',
    confirmPassword: '',
    uerRoleName: '',
    userRoleId: '',
    userStateId: '',
    userStateName: ''
  })

  useEffect(() => {
    init()

    // Testing purpose
    if (mockProfileInformation) {
      setAddUserData(mockAddUserData)
    }
  }, [])

  const init = async () => {
    handleDismiss()
    setLoading(true)
    const fetchAllOccupationType = apiAdapterCoffeeWeb.occupationType()
    const fetchAllUserRolesType = apiAdapterCoffeeWeb.getAllRoles()
    const fetchAllStateList = apiAdapterCoffeeWeb.getStateList()

    try {
      const [fetchAllOccupationTypeResponse, fetchAllUserRolesTypeResponse, fetchAllStateListResponse] = await Promise.all([fetchAllOccupationType, fetchAllUserRolesType, fetchAllStateList])

      setOccupationDetails(fetchAllOccupationTypeResponse.data.returnLst)
      setUserRoleTypesInfo(fetchAllUserRolesTypeResponse.data.returnLst)
      setStateDropDown(fetchAllStateListResponse.data)
      setStateDropDownId(fetchAllStateListResponse.data.idState)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        onHide: handleDismiss,
        onRetry: init
      })
    } finally {
      setLoading(false)
    }
  }

  const createNewUser = async () => {
    handleDismiss()

    const { firstName, lastName, email, phone, occupationId, countryId, countryPhoneCode } = addUserData
    const { occupationTypeName } = addUserData.occupationName
    const { registerSource, deviceId, fbToken } = userDetails
    const { idState } = stateDropDownId
    const trimmedFirstName = firstName.trim()

    const postData = {
      userType: 0,
      firstName: trimmedFirstName,
      lastName,
      email,
      phone,
      occupationId,
      occupationName: occupationTypeName,
      countryId: countryId,
      password: 'NA',
      createdBy: 0,
      includeMenu: true,
      countryPhoneCode: countryPhoneCode,
      idtimezone: 0,
      idState,
      showMobileNo: true,
      showEmailId: true,
      isAllowedToConnect: true,
      userRole,
      registerSource,
      deviceId,
      fbToken
    }

    try {
      setLoading(true)
      const createUserResponse = await apiAdapterCoffeeWeb.postCreateUser(postData)

      if (createUserResponse?.status === 200) {
        setShowDialog({
          showModal: true,
          modalType: 'success',
          message: t('USER_ADDED_SUCCESSFULLY'),
          onHide: handleSuccess
        })
      }
    } catch {
      setShowDialog({
        showModal: true,
        modalType: 'error',
        message: t('USERS_WERE_NOT_ADDED_PLEASE_TRY_AGAIN_LATER'),
        onHide: handleDismiss,
        onRetry: postRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const checkEmailExist = async (email) => {
    setLoading(true)
    try {
      if (email) {
        const { data } = await apiAdapterCoffeeWeb.isEmailExist({ email })

        if (data === true) {
          setEmailExist(t('EMAIL_EXIST'))
          setIsEmailExist(true)
        } else {
          setEmailExist(null)
          setIsEmailExist(false)
        }
      } else {
        setEmailExist(null)
        setIsEmailExist(false)
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        onHide: handleDismiss,
        onRetry: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const checkPhoneNumber = async () => {
    const { phone, countryPhoneCode } = addUserData

    if (phone.length <= 9) {
      setErrorMessage(t('INVALID_PHONE_NUMBER'))
      setIsPhoneExist(false)
    } else {
      setLoading(true)
      try {
        if (phone && phone.length >= 7) {
          const { data } = await apiAdapterCoffeeWeb.isPhoneExist({ phoneNo: phone, countryPhoneCode })

          if (data === true) {
            setErrorMessage(t('PHONE_NUMBER_EXIST'))
            setIsPhoneExist(true)
          } else {
            setErrorMessage(null)
            setIsPhoneExist(false)
          }
        }
      } catch {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: 'NO_DATA_FOUND',
          onHide: handleDismiss,
          onRetry: commonMethods.handleRetry
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const onReceivingPhoneCode = (PhoneCode, countryCode) => {
    setAddUserData({ ...addUserData, countryPhoneCode: PhoneCode, countryId: countryCode })
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    const updateUserDataAndErrState = (field, newValue, validationFn = null) => {
      setAddUserData((prevData) => ({ ...prevData, [field]: newValue }))
      setErrorState((prevErrState) => ({ ...prevErrState, [field]: newValue === '' || (validationFn && typeof validationFn === 'function' && !validationFn(newValue)) }))
    }

    if (name === 'email') {
      updateUserDataAndErrState('email', value)
      setEmailExist('')
      if (value.trim() === '') {
        setErrorState((prevErrState) => ({ ...prevErrState, email: true, errorInEmail: false }))
      } else {
        updateUserDataAndErrState(name, value, Validation.emailValidation)
        setErrorState((prevErrState) => ({ ...prevErrState, email: !Validation.emailValidation(value), errorInEmail: false }))
      }
    }

    if (name === 'firstName' && (Validation.nameValidation(value) || value === '')) {
      setAddUserData({ ...addUserData, firstName: value })
    } else if (name === 'lastName' && (Validation.nameValidation(value) || value === '')) {
      setAddUserData({ ...addUserData, lastName: value })
    } else if (name === 'occupationName') {
      setAddUserData({ ...addUserData, occupationName: value, occupationId: value.idOccupationType })
    } else if (name === 'country') {
      updateUserDataAndErrState('country', value === '0' ? '' : value)
    }
    if (name === 'phone') {
      updateUserDataAndErrState('phone', value)
      setErrorMessage()
      if (value.trim() !== '') {
        setErrorState((prevErrState) => ({ ...prevErrState, phone: false, errorInPhone: false }))
      }
    } else if (name === 'uerRoleName') {
      setUserRole(value)
      updateUserDataAndErrState('uerRoleName', value)
    } else if (name === 'userStateName') {
      setAddUserData({ ...addUserData, userStateName: value })
      setErrorState({ ...errorState, userStateName: value === '' })
      updateUserDataAndErrState('uerRoleName', value)
      setStateDropDownId({ ...stateDropDownId, idState: value })
    }
  }

  const handleSaveButton = () => {
    createNewUser()
  }

  const onSubmit = (data, form) => {
    setAddUserData(data)
    form.restart()
  }

  const handleSuccess = () => {
    history.push(`${ROUTE_STRINGS.listUsers}?tab=contributors`)
  }

  const postRetry = () => {
    createNewUser()
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const validate = (data) => {
    const errors = {}

    const { firstName, lastName, email, occupationName, uerRoleName, userStateName } = data

    if (!firstName) {
      errors.firstName = t('FIRST_NAME_IS_REQUIRED')
    }

    if (!lastName) {
      errors.lastName = t('LAST_NAME_IS_REQUIRED')
    }

    if (!email) {
      errors.email = t('EMAIL_IS_REQUIRED')
    }

    if (!occupationName) {
      errors.occupationName = t('OCCUPATION_IS_REQUIRED')
    }

    if (!uerRoleName) {
      errors.uerRoleName = t('USER_ROLE_IS_REQUIRED')
    }
    if (!userStateName) {
      errors.userStateName = t('STATE_IS_REQUIRED')
    }

    return errors
  }

  const { firstName, lastName, email, occupationName, uerRoleName, userStateName, countryId, phone } = addUserData

  const isSubmitButtonDisabled = !firstName || !lastName || !email || !occupationName || !uerRoleName || !userStateName || !countryId || !phone || isEmailExist || isPhoneExist || errorMessage || errorState.email

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)

  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <div className="profileInformation" data-testid="profile-information-container">
      <div className="profileInformation-card">
        <Form
          onSubmit={onSubmit}
          initialValues={addUserData}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="firstName"
                render={({ input, meta }) => (
                  <div className="field firstName-label">
                    <span className="p-float-label">
                      <InputText id="name" {...input} value={addUserData.firstName} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid' : '')} data-testid="first-name" />
                      <label htmlFor="name" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('FIRST_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <Field
                name="lastName"
                render={({ input, meta }) => (
                  <div className="field last-name-field">
                    <span className="p-float-label">
                      <InputText id="lastName" {...input} value={addUserData.lastName} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid' : '')} data-testid="last-name" />
                      <label htmlFor="lastName" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('LAST_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <Field
                name="country"
                render={({ input }) => (
                  <div className="field country-phone-field">
                    <span className="p-float-label">
                      <CountryCallingCodeSelector id="country" {...input} inputName={'phone'} value={addUserData.phone} onValueChange={handleChange} onGetPhoneCode={onReceivingPhoneCode} secondaryInputName={'country'} secondaryValue={addUserData.countryId} onSecondaryValueChange={handleChange} onPhoneInputBlur={checkPhoneNumber} errorCountry={errorState.country} phoneError={errorState.phone} phoneExist={errorMessage} onResetCountryPhoneCode={addUserData.countryPhoneCode} />
                    </span>
                  </div>
                )}
              />

              <Field
                name="email"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-envelope" />
                      <InputText
                        id="email"
                        {...input}
                        onChange={handleChange}
                        value={addUserData.email}
                        data-testid="email-field"
                        onBlur={(e) => {
                          input.onBlur(e)
                          checkEmailExist(e.target.value)
                        }}
                        className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid' : '')}
                      />
                      <label htmlFor="email" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('EMAIL')}
                        <span className="required-field">*</span>
                      </label>
                    </span>

                    {emailExist && !meta.error && <small className="p-error">{emailExist}</small>}
                    {errorState.email && !meta.error ? (
                      <div className="error-message">
                        <span className="error-message">{t('PLEASE_ENTER_A_VALID_EMAIL')}</span>
                      </div>
                    ) : (
                      meta.error && meta.touched && <small className="p-error">{meta.error}</small>
                    )}
                  </div>
                )}
              />

              <Field
                name="occupationName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown
                        {...input}
                        filter
                        resetFilterOnHide
                        data-testid="occupation-field"
                        filterPlaceholder={t('SEARCH_OCCUPATION')}
                        onChange={(event) => handleChange(event)}
                        value={addUserData.occupationName}
                        className={`profile-information-fields ${isFormFieldValid(meta) ? 'p-invalid' : ''} ${isFormFieldValid(meta) ? 'p-invalid-hover' : ''}`}
                        id="occupation"
                        placeholder={t('SELECT_OCCUPATION')}
                        options={occupationDetails.map((ele) => ({
                          label: ele.occupationTypeName,
                          value: ele
                        }))}
                      />
                      <label htmlFor="occupation" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('OCCUPATION')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <Field
                name="uerRoleName"
                render={({ input, meta }) => (
                  <div className="field userRole-input-field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown
                        {...input}
                        filter
                        resetFilterOnHide
                        data-testid="role-field"
                        filterPlaceholder={t('SEARCH_USER_ROLE')}
                        value={userRole}
                        className={`profile-information-fields ${isFormFieldValid(meta) ? 'p-invalid' : ''} ${isFormFieldValid(meta) ? 'p-invalid-hover' : ''}`}
                        id="userRole"
                        placeholder={t('SELECT_USER_ROLE')}
                        options={userRoleTypesInfo.map((element) => ({
                          label: element.roleName,
                          value: element.idRole
                        }))}
                        onChange={(e) => handleChange(e)}
                      />
                      <label htmlFor="userRole" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('USER_ROLE')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <Field
                name="userStateName"
                render={({ input, meta }) => (
                  <div className="field userState-input-field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown
                        {...input}
                        filter
                        resetFilterOnHide
                        data-testid="state-field"
                        filterPlaceholder={t('SEARCH_STATE')}
                        className={classNames('profile-information-fields', { 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : '')}
                        id="stateInput"
                        placeholder={t('SELECT_STATE_NAME')}
                        value={addUserData.userStateName}
                        options={stateDropDown.map((element) => ({
                          label: element.stateName,
                          value: element.idState
                        }))}
                        onChange={(e) => handleChange(e)}
                      />
                      <label htmlFor="stateInput" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('STATE_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <div className="add-user-button">
                <ButtonComponent type="submit" onClick={handleSaveButton} disabled={isSubmitButtonDisabled} />
              </div>
            </form>
          )}
        />
        <DialogModal {...showDialog} onDismiss={handleDismiss} onSuccess={handleSuccess} />
      </div>
    </div>
  )
}

export default Loader(ProfileInformation)
