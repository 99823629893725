import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Galleria } from 'primereact/galleria'
import { FileUpload } from 'primereact/fileupload'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { ButtonComponent } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'

const ModalComponent = ({ isModalOpen, closeModal, defaultImages, setNewsFeedMedia, newsFeedMedia, setLoading, setButtonDisabled, mockAddNewsFeedModal, mockAddNewsFeedModalApiFlag, mockUploadedFiles }) => {
  const galleria = useRef(null)
  const currentTimeInUserTimeZone = new Date()
  const { t } = useTranslation()
  const currentTime = moment(currentTimeInUserTimeZone).format('YYYY-MM-DDTHH:mm:ss')
  const userDetails = getLocalUserDetails()

  const [selectedImages, setSelectedImages] = useState([])
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false)
  const [selectedFile, setSelectedFile] = useState([])
  const [activeIndex, setActiveIndex] = useState(defaultImages?.length > 0 ? 0 : -1)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const thumbnailTemplate = (image) => <img src={image.imageUrl} alt={image.alt} className="thumbnail-image" />

  useEffect(() => {
    if (newsFeedMedia.length === 0) {
      setSelectedImages([])
    }
  }, [newsFeedMedia])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockAddNewsFeedModal) {
      setUploadedFiles(mockUploadedFiles)
    } else if (mockAddNewsFeedModalApiFlag) {
      uploadMediaToS3(mockAddNewsFeedModalApiFlag)
    }
  }, [])

  const handleFileSelect = (event) => {
    const selectedMedia = event.files && event.files[0]

    if (selectedMedia && !uploadedFiles.some((file) => file.name === selectedMedia.name)) {
      setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, selectedMedia])
      setSelectedFile((prevSelectedFile) => [...prevSelectedFile, selectedMedia])
    }
    setSubmitButtonDisable(true)
  }

  const handleSubmit = async () => {
    closeModal()
    setButtonDisabled(false)
    const selectedImageObjects = selectedImages.map((selectedIndex) => {
      const { imageUrl, nameOfMedia, typeOfMedia, uploadedFileName, extensionOfMedia, sizeOfMedia } = defaultImages[selectedIndex]

      return {
        idNewsFeedMedia: 0,
        idNewsFeed: 0,
        createdOn: new Date(currentTime).toJSON().toString(),
        createdBy: userDetails.id,
        updateOn: new Date(currentTime).toJSON().toString(),
        updatedBy: 0,
        typeMedia: typeOfMedia,
        isActive: true,
        nameOfMedia: nameOfMedia,
        extensionOfMedia: extensionOfMedia,
        pathOfMedia: imageUrl,
        sizeOfMedia: sizeOfMedia,
        mediaData: nameOfMedia,
        uploadedFileName: uploadedFileName,
        defaultImages: true,
        selectedIndex
      }
    })
    const s3Images = []

    if (selectedFile.length > 0) {
      await Promise.all(
        selectedFile.map(async (file, i) => {
          const response = await uploadMediaToS3(file)
          const responseModify = {
            ...response,
            defaultImages: false,
            selectedIndex: i
          }

          s3Images.push(responseModify)
        })
      )
    }
    const allImages = [...selectedImageObjects, ...s3Images]

    setNewsFeedMedia(allImages)
  }

  const uploadMediaToS3 = async (event) => {
    setLoading(true)
    const { type, name, size } = event
    const fileData = new FormData()

    fileData.append('file', event)

    try {
      const { data } = await apiAdapterCoffeeWeb.uploadFileToS3Bucket(fileData)

      if (data) {
        if (type.includes('image') || type.includes('video')) {
          const obj = {
            idNewsFeedMedia: 0,
            idNewsFeed: 0,
            createdOn: new Date(currentTime).toJSON().toString(),
            createdBy: userDetails.id,
            updateOn: new Date(currentTime).toJSON().toString(),
            updatedBy: 0,
            typeMedia: `${type.split('/')[0]}`,
            isActive: true,
            nameOfMedia: name,
            extensionOfMedia: `${type.split('/')[1]}`,
            pathOfMedia: data,
            sizeOfMedia: size,
            mediaData: `${type.split('/')[0]}`,
            uploadedFileName: data.split('/')[3]
          }

          return obj
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const removeImage = (index, nameOfMedia) => {
    setSubmitButtonDisable(false)
    setButtonDisabled(false)
    const updatedNewsFeedMedia = [...newsFeedMedia]
    const removedImage = updatedNewsFeedMedia[index]

    if (removedImage) {
      updatedNewsFeedMedia.splice(index, 1)
      setNewsFeedMedia(updatedNewsFeedMedia)

      if (removedImage.defaultImages) {
        setSelectedImages((prevSelectedIndices) => prevSelectedIndices.filter((selectedIndex, i) => i !== index && selectedIndex))
      } else {
        const updatedUploadedFiles = uploadedFiles.filter((file) => file.name !== nameOfMedia)
        const updatedSelectedFiles = selectedFile.filter((file) => file.name !== nameOfMedia)

        setUploadedFiles(updatedUploadedFiles)
        setSelectedFile(updatedSelectedFiles)
      }
    }
  }

  const handleImageClick = (index) => {
    const isSelected = selectedImages.includes(index)
    let updatedSelectedImages

    if (!isSelected) {
      updatedSelectedImages = [...selectedImages, index]
    } else {
      updatedSelectedImages = selectedImages.filter((selectedIndex) => selectedIndex !== index)
    }

    setSelectedImages(updatedSelectedImages)
    setSubmitButtonDisable(true)
  }

  const handleCancelClick = (index) => {
    selectedFile.splice(index, 1)
    setSelectedFile((previousImages) => [...previousImages])
    uploadedFiles.splice(index, 1)
    setUploadedFiles((previousImages) => [...previousImages])
    setSubmitButtonDisable(true)
  }

  return (
    <>
      <div className="image-display">
        {newsFeedMedia.map((ele, index) => (
          <div key={index} className="added-image">
            <div className="uploaded-image-close-button">
              <Button icon="pi pi-times" className="p-button-outlined p-button-secondary close-button" onClick={() => removeImage(index, ele.nameOfMedia)} />
            </div>
            <div className="image-size">{(ele.typeMedia === 'image' || ele.typeMedia === 'video') && ele.typeMedia === 'video' ? <video src={ele.pathOfMedia} type={'video/mp4'} autoPlay={false} controls disablePictureInPicture controlsList="nodownload noplaybackrate" /> : <img src={ele.pathOfMedia} alt="thumbnail" />}</div>
          </div>
        ))}
      </div>
      <Dialog visible={isModalOpen} onHide={closeModal} header={<div className="modal-main-header">{t('ADD_THUMBNAIL_IMG')}</div>} className="dialog-image-component" data-testid="add-news-dialog">
        <div className="thumbnail-modal">
          <div className="section-header">
            <span className="section-header-text">{t('SELECT_FROM_BELOW_IMAGES')}</span>
          </div>
          {defaultImages?.length > 0 && (
            <div className="card flex justify-content-center" data-testid="image-gallery">
              <Galleria key={galleria} value={defaultImages} activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)} showThumbnails={false} thumbnail={thumbnailTemplate} />
              <div className="grid">
                {defaultImages.map((image, index) => (
                  <div key={index} className={`default-img ${selectedImages.includes(index) ? 'selected-image' : 'default-image'}`} onClick={() => handleImageClick(index)}>
                    <img src={image.imageUrl} alt="default" />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="or-text">{t('OR')}</div>
          <div className="second-half-container">
            <div className="file-upload-header">
              <span className="file-upload-text">{t('UPLOAD_YOUR_OWN')}</span>
            </div>
            <FileUpload
              className="choose-file-button"
              chooseLabel="Choose Files"
              accept="image/*,video/*,.pdf"
              data-testid="file-upload-button"
              auto={true}
              multiple={true}
              onSelect={(event) => handleFileSelect(event)}
              emptyTemplate={
                <div>
                  {uploadedFiles.length > 0 ? (
                    uploadedFiles.map((file, index) => {
                      const content = (
                        <div key={index} className="add-file-upload">
                          <div className="image-file">
                            <div className="add-image-container" data-testid="images-container">
                              <div className="add-image-container">{file.type.startsWith('image/') ? <img src={URL.createObjectURL(file)} alt={`uploaded-file-${index}`} /> : <video src={URL.createObjectURL(file)} type={file.type} autoPlay={false} controls disablePictureInPicture controlsList="nodownload noplaybackrate" />}</div>
                            </div>
                            <div className="cancel-action" data-testid="cancel-button">
                              <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => handleCancelClick(index)} />
                            </div>
                          </div>
                        </div>
                      )

                      return content
                    })
                  ) : (
                    <div className="drag-drop">
                      <p className="choose-title">{t('DRAG_AND_DROP_IMAGES')}</p>
                    </div>
                  )}
                </div>
              }
            />
          </div>
        </div>

        <div className="default-button-container">
          <ButtonComponent type="submit" onClick={handleSubmit} disabled={!submitButtonDisable} />
        </div>
      </Dialog>
    </>
  )
}

export default Loader(ModalComponent)
