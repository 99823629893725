import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import AddArabicaData from './Components/AddArabicaData'
import AddRobustaData from './Components/AddRobustaData'

const AddCountryStockReport = ({ showDialog, setShowDialog, selectedItem, coffeeReportData, reportName }) => {
  const { t } = useTranslation()
  const { idMarket } = selectedItem
  const [dateValue, setDateValue] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedData, setSelectedData] = useState({})
  const [selectedOrigin, setSelectedOrigin] = useState({})
  const [filteredOptionsCount, setFilteredOptionsCount] = useState(0)

  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [fields, setFields] = useState([
    {
      slNo: '',
      origin: '',
      valid: '',
      compareToPreDay: '',
      NonTenderable: '',
      suspend: ''
    }
  ])

  const [inputFields, setInputFields] = useState([
    {
      slNo: '',
      origin: '',
      antwerp: '',
      barcelona: '',
      hamburg: '',
      houston: '',
      miami: '',
      newOrleans: '',
      newYork: '',
      virginia: '',
      compareToPreDay: ''
    }
  ])

  const [touchedFields, setTouchedFields] = useState({
    origin: false,
    date: false,
    valid: false,
    compareToPreDay: false,
    NonTenderable: false,
    suspend: false
  })

  const [validatedInputs, setValidatedInputs] = useState({
    date: false,
    origin: false,
    antwerp: false,
    barcelona: false,
    hamburg: false,
    houston: false,
    miami: false,
    newOrleans: false,
    newYork: false,
    virginia: false,
    compareToPreDay: false
  })

  useEffect(() => {
    if (showDialog) {
      setTouchedFields((prev) => ({
        ...prev,
        origin: false
      }))
      setValidatedInputs((prev) => ({
        ...prev,
        origin: false
      }))
    }
  }, [showDialog])

  const insertCountryWiseData = async () => {
    handleDismiss()
    try {
      const data = {
        date: dateValue,
        filename: reportName,
        countryCertifiedData: idMarket === 1 ? fields : inputFields
      }

      let response

      if (idMarket === 1) {
        response = await apiAdapterCoffeeWeb.postCountryWiseDataRobusta(data)
      } else {
        response = await apiAdapterCoffeeWeb.postCountryWiseDataArabica(data)
      }

      if (response.status === 200) {
        setErrorModal({
          ...errorModal,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_ADDED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
        setShowDialog(false)
        resetData()
      }
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: insertCountryWiseData,
        onHide: handleDismiss
      })
    }
  }

  const closeDialog = () => {
    setShowDialog(false)
    resetData()
  }

  const resetData = () => {
    if (idMarket === 1) {
      setFields([
        {
          slNo: '',
          origin: '',
          valid: '',
          compareToPreDay: '',
          NonTenderable: '',
          suspend: ''
        }
      ])

      setTouchedFields({
        origin: false,
        date: false,
        valid: false,
        compareToPreDay: false,
        NonTenderable: false,
        suspend: false
      })

      setSelectedData({})
    } else {
      setInputFields([
        {
          slNo: '',
          origin: '',
          antwerp: '',
          barcelona: '',
          hamburg: '',
          houston: '',
          miami: '',
          newOrleans: '',
          newYork: '',
          virginia: '',
          compareToPreDay: ''
        }
      ])
      setValidatedInputs({
        date: false,
        origin: false,
        antwerp: false,
        barcelona: false,
        hamburg: false,
        houston: false,
        miami: false,
        newOrleans: false,
        newYork: false,
        virginia: false,
        compareToPreDay: false
      })
      setSelectedOrigin({})
    }
    setDateValue(null)
    setSelectedDate(null)
  }

  const handleDismiss = () => {
    setErrorModal({ ...errorModal, showModal: false })
  }

  const areFieldsValid = () => {
    let disable

    if (idMarket === 1) {
      disable = fields.every((item) => item.valid && item.compareToPreDay && item.NonTenderable && item.suspend && item.origin && selectedDate)
    } else {
      disable = inputFields.every((item) => item.origin && item.antwerp && item.barcelona && item.hamburg && item.houston && item.miami && item.newOrleans && item.newYork && item.virginia && item.compareToPreDay && selectedDate)
    }

    return disable
  }

  const footerContent = () => (
    <div className="button-container">
      <ButtonComponent type="cancel" onClick={closeDialog} />
      <ButtonComponent type="submit" onClick={insertCountryWiseData} disabled={filteredOptionsCount !== 1 || !areFieldsValid()} />
    </div>
  )

  return (
    <div className="country-wise-dialog-container">
      <Dialog visible={showDialog} header={`${t('ADD_COUNTRY_STOCK_REPORT')} - ${selectedItem.marketName}`} onHide={closeDialog} className={`country-wise-dialog-modal ${idMarket === 1 ? 'robusta-form' : 'arabica-form'}`} footer={footerContent}>
        {idMarket === 1 && (
          <>
            <AddRobustaData setFields={setFields} fields={fields} coffeeReportData={coffeeReportData} setDateValue={setDateValue} setTouchedFields={setTouchedFields} touchedFields={touchedFields} setSelectedData={setSelectedData} selectedData={selectedData} setFilteredOptionsCount={setFilteredOptionsCount} filteredOptionsCount={filteredOptionsCount} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
          </>
        )}

        {idMarket === 2 && (
          <>
            <AddArabicaData coffeeReportData={coffeeReportData} setInputFields={setInputFields} inputFields={inputFields} setDateValue={setDateValue} validatedInputs={validatedInputs} setValidatedInputs={setValidatedInputs} selectedOrigin={selectedOrigin} setSelectedOrigin={setSelectedOrigin} setFilteredOptionsCount={setFilteredOptionsCount} filteredOptionsCount={filteredOptionsCount} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
          </>
        )}
      </Dialog>
      <DialogModal {...errorModal} onDismiss={handleDismiss} />
    </div>
  )
}

export default AddCountryStockReport
