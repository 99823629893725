import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Loader from 'Components/Loader'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'

const RoleFeature = ({ setLoading, mockRoleFeatureAPIFlag, mockRoleFeature }) => {
  const { t } = useTranslation()
  const history = useHistory()
  let modalCancel

  const [checkboxStates, setCheckboxStates] = useState({})
  const [rolesMaster, setRolesMaster] = useState([])
  const [roleFeatures, setRoleFeatures] = useState([])
  const [allMenus, setAllMenus] = useState([])
  const [roleFeaturesId, setRoleFeaturesId] = useState(null)
  const [error, setError] = useState(false)
  const [getError, setGetError] = useState(false)
  const [isInputDisabled, setIsInputDisabled] = useState(false)
  const [isDropdownFocused, setDropdownFocused] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalCancel: false, modalType: '', message: '', errorMessagePostApiFails: false, onDismiss: () => {}, onRetry: () => {} })

  useEffect(() => {
    init()
    // Testing Code
    if (mockRoleFeatureAPIFlag) {
      setRoleFeatures(mockRoleFeature)
      GetRoleFeaturesForCheckBox()
      postRolesMenuData()
    }
  }, [])

  const init = async () => {
    setLoading(true)
    const fetchRoleMaster = apiAdapterCoffeeWeb.getAllRoles()
    const fetchCoffeeMenuApi = apiAdapterCoffeeWeb.getCoffeeMenu()

    try {
      const [fetchRoleMasterResponse, fetchCoffeeMenuApiResponse] = await Promise.all([fetchRoleMaster, fetchCoffeeMenuApi])

      setRolesMaster(fetchRoleMasterResponse.data.returnLst)
      setRoleFeatures(fetchCoffeeMenuApiResponse.data)
      setCheckboxStates({})
    } catch {
      setShowDialog({
        showModal: true,
        modalType: 'error',
        message: 'ERROR_FETCHING_ROLE_TYPE_PLEASE_TRY_AGAIN_LATER',
        modalCancel: true,
        onDismiss: handleGetDismiss,
        onRetry: handleGetRetry
      })
      setGetError(true)
      setIsInputDisabled(true)
    } finally {
      setLoading(false)
    }
  }

  const GetRoleFeaturesForCheckBox = async (id) => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getMenuOnRoleId(id)

      setRoleFeatures(response.data.returnLst)
      setAllMenus(response.data.returnLst)

      const updatedCheckboxStates = {}

      response.data.returnLst.forEach((item) => {
        updatedCheckboxStates[item.menuid] = item.isApplied
      })
      setCheckboxStates(updatedCheckboxStates)
      if (response.data.returnLst.length === 0) {
        setRoleFeatures(allMenus)
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'ERROR_FETCHING_Role_TYPE_PLEASE_TRY_AGAIN_LATER',
        modalCancel: true,
        onDismiss: handleGetDismiss,
        onRetry: handleGetRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const postRolesMenuData = async () => {
    setLoading(true)
    if (roleFeaturesId === null) {
      setError(true)
    }
    const postRoleFeature = []

    for (let i = 0; i < roleFeatures.length; i++) {
      const menu = roleFeatures[i]
      const isApplied = checkboxStates[menu.menuid] || false

      const object = {
        roleid: roleFeaturesId,
        menuid: menu.menuid,
        isApplied
      }

      postRoleFeature.push(object)
    }
    try {
      const roleFeatureData = await apiAdapterCoffeeWeb.postRoleMenuData(postRoleFeature)

      if (roleFeatureData?.data?.statusCode === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: 'ROLE_ADDED_SUCCESSFUL',
          modalCancel: false,
          errorMessagePostApiFails: true
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER',
        modalCancel: false,
        errorMessagePostApiFails: false,
        onDismiss: handleDismiss,
        onRetry: postRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listRoles)
  }

  const handleDismiss = () => {
    setShowDialog(false)
  }

  const handleGetDismiss = () => {
    setGetError(true)
    setIsInputDisabled(true)
    setShowDialog({ ...showDialog, showModal: false })
  }

  const postRetry = () => {
    setShowDialog({ ...showDialog, showModal: false })
    setLoading(true)
    postRolesMenuData()
    setLoading(false)
  }

  const handleSubmitButton = () => {
    postRolesMenuData()
  }

  const handleGetRetry = () => {
    window.location.reload()
    setGetError(true)
  }

  const handleChange = (e) => {
    setRoleFeaturesId(e.value)
    if (e.value === null) {
      setCheckboxStates({})
    } else {
      GetRoleFeaturesForCheckBox(e.value)
      setError(false)
    }
  }

  const handleDropdownBlur = () => {
    if (roleFeaturesId === null) {
      setError(true)
    }
    setDropdownFocused(false)
  }

  const dropdownClass = `dropdown-field ${isDropdownFocused || (error && roleFeaturesId === null) ? 'error-border' : ''}`
  const placeholderClass = isDropdownFocused || (error && roleFeaturesId === null) ? 'error-text' : ''

  const isSubmitDisabled = roleFeaturesId === null
  const isCheckboxDisabled = roleFeaturesId === null

  if (showDialog.modalCancel) {
    modalCancel = () => handleGetDismiss()
  } else if (showDialog.errorMessagePostApiFails) {
    modalCancel = () => history.push(ROUTE_STRINGS.listRoles)
  } else {
    modalCancel = () => handleDismiss()
  }

  // const layoutObject = {
  //   title:'ROLE_FEATURE',
  //   breadCrumbsTitle:'rolesFeature',
  //   label:
  // }

  return (
    <div className="role-features-master">
      <LayoutContainer title={'ROLE_FEATURE'} breadCrumbsTitle={'rolesFeature'}>
        <div className="role-features-content shadow">
          <div className="role-features-subcontinent">
            <div className="role-features-dropdown-role">
              <span className="p-float-label">
                <Dropdown
                  className={dropdownClass}
                  id="role-name"
                  value={roleFeaturesId}
                  options={rolesMaster.map(({ roleName, idRole }) => ({
                    label: roleName,
                    value: idRole
                  }))}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleDropdownBlur}
                  disabled={isInputDisabled}
                  data-testid="role-type"
                />
                <label htmlFor="role-name" className={placeholderClass}>
                  {t('ROLE_TYPE')}
                  <span className="required-field">*</span>
                </label>
              </span>
              {roleFeaturesId === null && error && <small className="role-feature-error-message">{t('PLEASE_SELECT_A_ROLE_TYPE')}</small>}
              {getError && <small className="p-error">{t('ERROR_WHILE_FETCHING_ROLE_TYPE')}</small>}
            </div>
            <div className="role-screen-list">
              {t('SCREENS')}
              <span class="required-field">*</span>
            </div>
            <div className="role-features-role-screen">
              <div className="role-features-role-data" data-testid="role-feature-data">
                {roleFeatures?.map((menu) => (
                  <div key={menu.menuid} className="role-features-menu-names">
                    <Checkbox
                      disabled={isCheckboxDisabled}
                      className="check-box"
                      inputId={`menu-${menu.menuid}`}
                      checked={checkboxStates[menu.menuid] || false}
                      onChange={(e) => {
                        const { checked } = e.target

                        setCheckboxStates({
                          ...checkboxStates,
                          [menu.menuid]: checked
                        })
                      }}
                    />
                    <div>{menu.menuName}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="button-container">
            <ButtonComponent type="submit" onClick={handleSubmitButton} disabled={isSubmitDisabled} />
          </div>

          <DialogModal {...showDialog} onSuccess={handleSuccess} onHide={modalCancel}></DialogModal>
        </div>
      </LayoutContainer>
    </div>
  )
}

export default Loader(RoleFeature)
