import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { TABLE_CONST } from 'Utils/Constants'
import coffeeReportJsonLinks from 'Utils/Constants/coffeeReportsJsonLink'
import Loader from 'Components/Loader'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import AddPortWiseStockReport from './AddPortWiseStockReport'
import EditPortWiseCertifiedReport from './EditPortWiseStockReport'

const PortWiseStockReport = ({ setLoading }) => {
  const { t } = useTranslation()
  let rowCount = 8
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const { robustaPortWiseCertifiedStocks, arabicaCountryWiseCertifiedStocks } = coffeeReportJsonLinks

  const marketName = [
    {
      idMarket: 1,
      marketName: 'Robusta',
      marketCode: 'RC'
    },
    {
      idMarket: 2,
      marketName: 'Arabica',
      marketCode: 'KC'
    }
  ]

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [showDialog, setShowDialog] = useState(false)
  const [portData, setPortData] = useState([])
  const [coffeeReportsCollection, setCoffeeReportsCollection] = useState([])
  const [portWiseReportData, setPortWiseReportData] = useState([])
  const [portCountries, setPortCountries] = useState([])
  const [selectedPort, setSelectedPort] = useState({})
  const [selectedCountry, setSelectedCountry] = useState({})
  const [rowDetails, setRowDetails] = useState({})
  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [selectedMarket, setSelectedMarket] = useState({
    idMarket: 1,
    marketName: 'Robusta',
    marketCode: 'RC'
  })
  const [openModal, setOpenModal] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState({})

  const reportName = selectedMarket.idMarket === 1 ? robustaPortWiseCertifiedStocks : arabicaCountryWiseCertifiedStocks

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    fetchReportData()
  }, [selectedMarket])

  const fetchReportData = async () => {
    handleDismiss()
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setCoffeeReportsCollection(response.data)

      const countryList = response.data.map((item) => ({ country: item.country }))
      const portList = response?.data.map((ele) => ({ port: ele.port }))
      const { port, country, report } = response.data[0]

      setSelectedPort({ port: port })
      setSelectedCountry({ country: country })
      setPortWiseReportData(report)
      setPortCountries(countryList)
      setPortData(portList)
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => fetchReportData(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const deleteReportData = async (rowData) => {
    setLoading(true)
    setErrorModal({ ...errorModal, showModal: false })

    const { idMarket } = selectedMarket
    const { date, Date } = rowData
    const { port } = selectedPort
    const { country } = selectedCountry

    try {
      let response

      if (idMarket === 1) {
        response = await apiAdapterCoffeeWeb.deletePortWiseStockReportRobusta({ fileName: reportName, port: port, date: date })
      } else {
        response = await apiAdapterCoffeeWeb.deletePortWiseStockReportArabica({ fileName: reportName, country: country, date: Date })
      }

      if (response.status === 200) {
        setErrorModal({
          ...errorModal,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_DELETED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })

        const filteredData = response?.data.filter((data) => (idMarket === 1 ? data?.port === port : data?.country === country))

        setPortWiseReportData(filteredData[0]?.report)
      }
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_DELETE_REPORT_DATA'),
        onRetry: () => deleteReportData(rowData),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleEditButtonClick = (rowData) => {
    setRowDetails(rowData)
    setSelectedRowData(rowData)
    setOpenModal(true)
  }

  const deleteConfirmation = (rowData) => {
    setErrorModal({
      ...errorModal,
      showModal: true,
      modalType: 'info',
      message: t('DELETE_USDA_COFFEE_REPORT'),
      onConfirmation: () => deleteReportData(rowData),
      onHide: handleDismiss
    })
  }

  const actionTemplate = (rowData) => (
    <div className="action-button-wrapper">
      <Button
        type="button"
        icon="pi pi-pencil"
        className="edit-button"
        tooltip={t('EDIT')}
        tooltipOptions={{ position: 'top' }}
        onClick={() => {
          handleEditButtonClick(rowData)
        }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        className="deleted-button"
        tooltip={t('DELETE')}
        tooltipOptions={{ position: 'top' }}
        onClick={() => {
          deleteConfirmation(rowData)
        }}
      />
    </div>
  )

  const robustaPortColumn = [
    {
      dataField: 'date',
      text: t('DATE'),
      className: 'date-column'
    },
    {
      dataField: 'valid',
      text: t('VALID'),
      className: 'valid-column'
    },
    {
      dataField: 'comparedToPreDay',
      text: t('COMPARE_TO_PRE_DAY'),
      className: 'compared-to-pre-day-column'
    },
    {
      dataField: 'nonTenderable',
      text: t('NON_TENDERABLE'),
      className: 'non-tenderable-column'
    },
    {
      dataField: 'suspended',
      text: t('SUSPENDED'),
      className: 'suspended-column'
    },
    {
      text: t('ACTIONS'),
      body: actionTemplate,
      className: 'actions-column'
    }
  ]

  const arabicaPortColumn = [
    {
      dataField: 'Date',
      text: t('DATE'),
      className: 'date-column'
    },
    {
      dataField: 'Antwerp',
      text: t('ANTWERP'),
      className: 'antwerp-column'
    },
    {
      dataField: 'HamburgBremen',
      text: t('HAMBURG'),
      className: 'hamburg-column'
    },
    {
      dataField: 'Houston',
      text: t('HOUSTON'),
      className: 'houston-column'
    },
    {
      dataField: 'NewOrleans',
      text: t('NEW_ORLEANS'),
      className: 'new-orleans-column'
    },
    {
      dataField: 'NewYork',
      text: t('NEW_YORK'),
      className: 'new-york-column'
    },
    {
      dataField: 'Virginia',
      text: t('VIRGINIA'),
      className: 'virginia-column'
    },
    {
      dataField: 'barcelona',
      text: t('BARCELONA'),
      className: 'barcelona-column'
    },
    {
      dataField: 'miami',
      text: t('MIAMI'),
      className: 'miami-column'
    },
    {
      dataField: 'ComparedPrevDay',
      text: t('COMPARE_TO_PRE_DAY'),
      className: 'compared-prev-day-column'
    },
    {
      text: t('ACTIONS'),
      body: actionTemplate,
      className: 'actions-column'
    }
  ]

  const handleAddButtonClick = () => {
    setRowDetails({})
    setShowDialog(true)
  }

  const handleMarketChange = (event) => {
    setPortWiseReportData([])
    const value = event.target.value

    setSelectedMarket(value)
  }

  const dropdownSelectionHandler = (event) => {
    const value = event.target.value

    if (selectedMarket.idMarket === 1) {
      const portBasedCoffeeReport = coffeeReportsCollection.find((item) => item.port === value.port)

      setSelectedPort(value)
      setPortWiseReportData(portBasedCoffeeReport.report)
    } else {
      const coffeeReportByCountryWise = coffeeReportsCollection.find((item) => item.country === value.country)

      setSelectedCountry(value)
      setPortWiseReportData(coffeeReportByCountryWise.report)
    }
  }

  const handleDismiss = () => {
    setErrorModal({ ...errorModal, showModal: false })
  }

  return (
    <div className="port-wise-stock-report-container">
      <div className="port-header-container">
        <div className="dropdown-wrapper">
          <Dropdown value={selectedMarket} options={marketName} optionLabel="marketName" onChange={handleMarketChange} />
          {selectedMarket.idMarket === 1 ? <Dropdown value={selectedPort} options={portData} optionLabel="port" onChange={dropdownSelectionHandler} placeholder={t('CHOOSE_PORT')} /> : <Dropdown value={selectedCountry} options={portCountries} optionLabel="country" onChange={dropdownSelectionHandler} placeholder={t('SELECT_COUNTRY')} />}
        </div>
        <div className="button-wrapper">
          <ButtonComponent type={'add'} onClick={handleAddButtonClick} />
        </div>
      </div>

      <div className="port-wise-table-data">
        {selectedMarket.idMarket === 1 && (
          <DataTable value={portWiseReportData} paginator={portWiseReportData?.length} rows={rowCount} pageLinkSize={paginationButtons}>
            {robustaPortColumn.map((item, index) => (
              <Column key={index} field={item.dataField} header={item.text} body={item.body} className={item.className} />
            ))}
          </DataTable>
        )}
        {selectedMarket.idMarket === 2 && (
          <DataTable value={portWiseReportData} paginator={portWiseReportData?.length} rows={rowCount} pageLinkSize={paginationButtons}>
            {arabicaPortColumn.map((item, index) => (
              <Column key={index} field={item.dataField} header={item.text} body={item.body} className={item.className} />
            ))}
          </DataTable>
        )}
      </div>
      <DialogModal {...errorModal} onDismiss={handleDismiss} />
      <AddPortWiseStockReport setShowDialog={setShowDialog} showDialog={showDialog} selectedMarket={selectedMarket} rowDetails={rowDetails} portData={portData} coffeeReportData={portCountries} reportName={reportName} />
      <EditPortWiseCertifiedReport openModal={openModal} setOpenModal={setOpenModal} selectedValue={selectedMarket} selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} reportName={reportName} setFetchedData={setPortWiseReportData} portName={selectedPort} countryName={selectedCountry} />
    </div>
  )
}

export default Loader(PortWiseStockReport)
