import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { PickList } from 'primereact/picklist'
import { InputSwitch } from 'primereact/inputswitch'
import { Image } from 'primereact/image'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Validation from 'Utils/Validation'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'

const EditPayment = ({ setLoading, mockEditPaymentData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { state: selectedPaymentData } = location

  const [source, setSource] = useState([])
  const [target, setTarget] = useState(location.state?.countriesEnabled || [])

  const selectedCountryId = target.map((country) => country.id)

  const [submitButtonDisable, setSubmitButtonDisabled] = useState(true)
  const [paymentMaster, setPaymentMaster] = useState([])
  const [duplicatePaymentChannelName, setDuplicatePaymentChannelName] = useState(false)
  const [editPaymentData, setEditPaymentData] = useState({
    paymentName: '',
    imageUrl: '',
    isPlanId: '',
    isActive: '',
    orderBy: ''
  })

  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    onConfirmation: () => {},
    onRetry: () => {},
    softAction: '',
    hardAction: '',
    modalCancel: false
  })

  useEffect(() => {
    setEditPaymentData(location.state)
    getAllCountriesList()
    // testing code
    if (mockEditPaymentData) {
      setEditPaymentData(mockEditPaymentData)
      submitFileToS3Bucket()
      getPaymentMaster()
    }
  }, [])

  const updatePaymentMaster = async (imgUrlLink) => {
    setModalState({ ...modalState, showModal: false })

    setLoading(true)

    const { idPayment, paymentName, imageUrl, isPlanId, isActive, orderBy } = editPaymentData
    const putPaymentData = {
      id: 0,
      idPayment,
      paymentName,
      imageUrl: !imgUrlLink ? imageUrl : imgUrlLink,
      isPlanId,
      isActive,
      orderBy,
      countryId: selectedCountryId.toString(),
      countryIsActive: true
    }

    try {
      const {
        data: { statusCode }
      } = await apiAdapterCoffeeWeb.updatePaymentData(putPaymentData)

      if (statusCode === 200 || statusCode === 201) {
        setModalState({
          ...modalState,
          showModal: true,
          message: t('PAYMENT_UPDATED_SUCCESSFULLY'),
          modalType: 'success',
          softAction: t('DISMISS'),
          modalCancel: true
        })
      }
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        message: t('SORRY_COULD_NOT_EDIT_THE_DATA_PLEASE_TRY_AGAIN_LATER'),
        modalType: 'error',
        modalCancel: false,
        onRetry: updatePaymentMaster
      })
    } finally {
      setLoading(false)
    }
  }

  const getAllCountriesList = async () => {
    setModalState({ ...modalState, showModal: false })

    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getAllCountries()

      let filteredSource

      if (target?.length) {
        filteredSource = data.filter((country) => !target.some((targetCountry) => targetCountry?.nicename === country.nicename))
      }
      setSource(filteredSource || data)
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        message: t('NO_DATA_FOUND'),
        modalType: 'error',
        onRetry: getAllCountriesList
      })
    } finally {
      setLoading(false)
    }
  }

  const submitFileToS3Bucket = async (event) => {
    setLoading(true)
    const selectedFile = event?.files[0]
    const fileData = new FormData()

    fileData.append('file', selectedFile)

    try {
      const uploadImageResponse = await apiAdapterCoffeeWeb.uploadFileToS3Bucket(fileData)

      setEditPaymentData({ ...editPaymentData, imageUrl: uploadImageResponse.data })
      setSubmitButtonDisabled(false)
    } catch {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_UPLOAD_IMAGE'),
        modalType: 'error',
        modalCancel: false,
        onRetry: submitFileToS3Bucket
      })
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }

  const getPaymentMaster = async () => {
    try {
      const { data } = await apiAdapterCoffeeWeb.getAllPaymentType()

      setPaymentMaster(data.returnLst)
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        message: t('NO_DATA_FOUND'),
        modalType: 'error'
      })
    }
  }

  const toggleButton = () => {
    if (editPaymentData.paymentName && editPaymentData.orderBy) {
      setSubmitButtonDisabled(false)
    }
    const { isActive } = editPaymentData

    if (!isActive) {
      setEditPaymentData({ ...editPaymentData, isActive: true })
    } else {
      setEditPaymentData({ ...editPaymentData, isActive: false })
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    if (name === 'paymentName') {
      if (Validation.nameValidation(value) || Validation.isInputFieldEmpty(value)) {
        setEditPaymentData((prevData) => ({
          ...prevData,
          paymentName: value
        }))

        const filteredNames = paymentMaster.filter(({ idPayment: itemId }) => itemId !== +editPaymentData.idPayment).map(({ paymentName }) => paymentName.toLowerCase())
        const allowToSave = filteredNames.some((item) => item.toLowerCase() === value.toLowerCase())

        setDuplicatePaymentChannelName(allowToSave)
      }
    } else if (name === 'orderBy') {
      setEditPaymentData((prevData) => ({
        ...prevData,
        orderBy: value
      }))
    }

    setSubmitButtonDisabled(Validation.isInputFieldEmpty(value))
    setSubmitButtonDisabled(false)
  }

  const onChange = (event) => {
    setSubmitButtonDisabled(false)

    setSource(event.source)
    setTarget(event.target)
  }

  const handleSaveButton = () => {
    updatePaymentMaster()
  }

  const handleDismiss = () => {
    setSubmitButtonDisabled(true)
    setModalState({ ...modalState, showModal: false })
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listPayment)
  }

  const handleDeleteImage = () => {
    setEditPaymentData({ ...editPaymentData, imageUrl: '' })
  }

  const onSubmit = (data) => {
    setEditPaymentData(data)
  }

  const validate = (data) => {
    const errors = {}
    const { paymentName, orderBy } = data

    if (!paymentName) {
      errors.paymentName = t('PAYMENT_IS_REQUIRED')
    } else if (duplicatePaymentChannelName) {
      errors.paymentName = t('DATA_ALREADY_EXISTS')
    }
    if (!orderBy) {
      errors.orderBy = t('ORDER_BY_IS_REQUIRED')
    }

    return errors
  }

  const { paymentName, isPlanId, isActive, orderBy, imageUrl } = editPaymentData
  const { paymentName: initialPaymentName, isPlanId: initialIsPlanId, isActive: initialIsActive, orderBy: initialOrderBy, imageUrl: initialImageUrl } = selectedPaymentData

  const initialCountryId = selectedPaymentData?.countriesEnabled?.map((item) => item.id).sort()
  const selectCountryId = target?.map((item) => item.id).sort()

  const isButton = !paymentName || !orderBy || !imageUrl
  const isButtonCheck = paymentName === initialPaymentName && isPlanId === initialIsPlanId && isActive === initialIsActive && +orderBy === +initialOrderBy && initialCountryId?.toString() === selectCountryId.toString() && imageUrl === initialImageUrl
  const isDisabled = submitButtonDisable || isButton || duplicatePaymentChannelName || isButtonCheck

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const itemTemplate = (item) => (
    <div className="country-list-wrapper">
      <div className="country-flag-container">
        <img className="country-images" src={`${item.flagsUrl}`} alt={item.nicename} />
      </div>
      <div className="country-name-container">
        <span className="country-names">{item.nicename}</span>
      </div>
    </div>
  )

  return (
    <LayoutContainer title={'EDIT_NEW_PAYMENT'} breadCrumbsTitle={'editPayment'}>
      <div className="edit-payment-container" data-testid="edit-payment">
        <Form
          onSubmit={onSubmit}
          initialValues={editPaymentData}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <div className="payment-form-container">
                <div className="payment-input-field-wrapper">
                  <Field
                    name="paymentName"
                    render={({ input, meta }) => (
                      <div className="edit-input-fields">
                        <span className="p-float-label">
                          <InputText id="name" name="paymentName" autoComplete="off" {...input} onChange={handleChange} value={editPaymentData.paymentName} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onFocus={paymentMaster.length === 0 ? getPaymentMaster : null} data-testid="payment-channel-name" />
                          <label htmlFor="name" className={classNames(`${isFormFieldValid(meta) ? 'error-message' : 'edit-payment-label'}`)}>
                            {t('PAYMENT_CHANNEL_NAME')}
                            <span className="star">*</span>
                          </label>
                        </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}
                  />
                  <div className="toggle-button-container">
                    <label htmlFor="toggleButton" className="toggle-button">
                      {t('IS_ACTIVE')}
                    </label>
                    <div className="input-switch-wrapper">
                      <InputSwitch checked={editPaymentData?.isActive} onChange={toggleButton} />
                    </div>
                  </div>
                </div>

                <Field
                  name="orderBy"
                  render={({ input, meta }) => (
                    <div className="edit-input-fields">
                      <span className="p-float-label">
                        <InputText keyfilter="int" id="orderBy" name="orderBy" {...input} onChange={handleChange} value={editPaymentData.orderBy} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} data-testid="order-by" />
                        <label htmlFor="orderBy" className={classNames(`${isFormFieldValid(meta) ? 'error-message' : 'edit-payment-label'}`)}>
                          {t('ORDER_BY')}
                          <span className="star">*</span>
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
              </div>

              <div className="payment-logo-container">
                <label htmlFor="paymentImage" className="image-logo-label">
                  {t('PAYMENT_CHANNEL_LOGO')}
                </label>
                <span className="star">*</span>
                <FileUpload
                  chooseLabel={t('CHOOSE_FILE')}
                  accept="image/*"
                  auto={true}
                  data-testid="file-upload"
                  onSelect={(event) => submitFileToS3Bucket(event)}
                  emptyTemplate={
                    <div className="image-upload-container">
                      {imageUrl ? (
                        <div className="image-file-container">
                          <div className="image-wrapper">
                            <Image src={imageUrl} alt="payment logo" title="fileUpload-image" />
                            <div className="image-cancel-button-container">
                              <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={handleDeleteImage} data-testid="cancel-button" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="logo-choose-title">{t('DRAG_AND_DROP_A_FILE_HERE_TO_UPLOAD')}.</p>
                      )}
                    </div>
                  }
                />
              </div>

              <div className="country-pick-list-container">
                <label htmlFor="paymentImage" className="country-name-label">
                  {t('PAYMENT_CHANNEL_REGION')}
                </label>

                <PickList
                  dataKey="id"
                  data-testid="country-pickList"
                  source={source}
                  target={target}
                  showSourceControls={false}
                  showTargetControls={false}
                  onChange={onChange}
                  itemTemplate={itemTemplate}
                  filter
                  filterBy="nicename"
                  sourceHeader={t('AVAILABLE_COUNTRIES')}
                  targetHeader={t('SELECTED_COUNTRIES')}
                  sourceFilterPlaceholder={t('SEARCH_BY_NAME')}
                  targetFilterPlaceholder={t('SEARCH_BY_NAME')}
                  pt={{
                    moveAllToTargetButton: {
                      root: { className: 'country-mapping-button' }
                    },
                    moveAllToSourceButton: {
                      root: { className: 'country-mapping-button' }
                    },
                    moveToTargetButton: {
                      root: { className: 'country-mapping-button' }
                    },
                    moveToSourceButton: {
                      root: { className: 'country-mapping-button' }
                    }
                  }}
                />
              </div>

              <div className="button-container">
                <ButtonComponent type={'cancel'} onClick={handleSuccess} />
                <ButtonComponent type={'submit'} onClick={handleSaveButton} disabled={isDisabled} />
              </div>
            </form>
          )}
        />
      </div>

      <DialogModal {...modalState} onDismiss={handleDismiss} onHide={modalState.modalCancel ? handleSuccess : handleDismiss} onSuccess={handleSuccess} />
    </LayoutContainer>
  )
}

export default Loader(EditPayment)
