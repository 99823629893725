import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import Validation from 'Utils/Validation'
import { ButtonComponent } from 'Components/UIComponents'

const AddArabicaData = (props) => {
  const { coffeeReportData, setInputFields, inputFields, setDateValue, validatedInputs, setValidatedInputs, selectedOrigin, setSelectedOrigin, filteredOptionsCount, setFilteredOptionsCount, selectedDate, setSelectedDate } = props
  const { t } = useTranslation()

  const [editingIndex, setEditingIndex] = useState(null)
  const [isFormFilled, setIsFormFilled] = useState(false)

  useEffect(() => {
    const filled = inputFields.every((item) => item.origin && item.antwerp && item.barcelona && item.hamburg && item.houston && item.miami && item.newOrleans && item.newYork && item.virginia && item.compareToPreDay)

    setIsFormFilled(filled)
  }, [inputFields])

  const handleDateChange = (event) => {
    const chosenDate = event.value
    const formattedDate = chosenDate ? moment(chosenDate).format('DD-MMM-YYYY') : ''

    setDateValue(formattedDate)
    setSelectedDate(chosenDate)
  }

  const handleDropdownChange = (index, event) => {
    const value = event.value
    const updatedFields = [...inputFields]

    updatedFields[index] = {
      ...updatedFields[index],
      origin: value.origin,
      slNo: value.slNo
    }

    setSelectedOrigin((prevSelectedData) => ({
      ...prevSelectedData,
      [index]: value
    }))

    setInputFields(updatedFields)
  }

  const getFilteredOptions = (index) => {
    const selectedOrigins = inputFields.map((field) => field.origin)
    const filteredOptions = coffeeReportData.filter((option) => !selectedOrigins.includes(option.origin) || selectedOrigin[index]?.origin === option.origin)

    setFilteredOptionsCount(filteredOptions.length)

    return filteredOptions
  }

  const handleInputChange = (index, field, value) => {
    const isValid = Validation.phoneCharValidation(value)

    const updatedFields = [...inputFields]

    if (isValid) {
      updatedFields[index][field] = value
    } else {
      updatedFields[index][field] = ''
    }

    setInputFields(updatedFields)
  }

  const handleBlur = (fieldName) => {
    setValidatedInputs((prev) => ({
      ...prev,
      [fieldName]: true
    }))
  }

  const handleAddFields = () => {
    setEditingIndex(null)

    setInputFields([
      ...inputFields,
      {
        antwerp: '',
        barcelona: '',
        hamburg: '',
        houston: '',
        miami: '',
        newOrleans: '',
        newYork: '',
        virginia: '',
        compareToPreDay: ''
      }
    ])

    setValidatedInputs((prevTouchedFields) => ({
      ...prevTouchedFields,
      origin: false,
      antwerp: false,
      barcelona: false,
      hamburg: false,
      houston: false,
      miami: false,
      newOrleans: false,
      newYork: false,
      virginia: false,
      compareToPreDay: false
    }))
  }

  const isFieldDisabled = (index) => index < inputFields.length - 1 && editingIndex !== index
  const handleEditClick = (index) => {
    setEditingIndex(index)
  }

  const isFieldValid = () => {
    const disable = inputFields.every((item) => item.origin && item.antwerp && item.barcelona && item.hamburg && item.houston && item.miami && item.newOrleans && item.newYork && item.virginia && item.compareToPreDay)

    return disable
  }

  const handleClearRow = (index) => {
    const updatedFields = [...inputFields]
    const updatedSelectedData = { ...selectedOrigin }

    updatedFields.splice(index, 1)

    delete updatedSelectedData[index]

    const newSelectedData = Object.keys(updatedSelectedData).reduce((acc, key) => {
      const currentIndex = parseInt(key, 10)

      if (currentIndex > index) {
        acc[currentIndex - 1] = updatedSelectedData[currentIndex]
      } else {
        acc[currentIndex] = updatedSelectedData[currentIndex]
      }

      return acc
    }, {})

    setInputFields(updatedFields)
    setSelectedOrigin(newSelectedData)
  }

  return (
    <>
      <div className="form-container">
        <div className="input-field-wrapper">
          <span className="p-float-label">
            <Calendar
              id="date"
              name="date"
              value={selectedDate}
              onChange={handleDateChange}
              onBlur={() => {
                handleBlur('date')
              }}
              maxDate={new Date()}
              showIcon
              className={`calendar-field ${validatedInputs.date && !selectedDate ? 'error' : ''}`}
            />
            <label htmlFor="date" className={`field-label ${validatedInputs.date && !selectedDate ? 'error' : ''}`}>
              {t('DATE')} <span className="required-text-field">*</span>
            </label>
          </span>
          {validatedInputs.date && !selectedDate && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
        </div>
      </div>

      <div className="input-container">
        {inputFields.map((item, index) => (
          <div className="field-wrapper" key={index}>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <Dropdown value={selectedOrigin[index] || null} options={getFilteredOptions(index)} onChange={(event) => handleDropdownChange(index, event)} placeholder={t('CHOOSE_ORIGIN')} name="origin" optionLabel="origin" className={`input-field ${validatedInputs.origin && !selectedOrigin[index] ? 'error' : ''}`} onBlur={() => handleBlur('origin')} disabled={isFieldDisabled(index)} />
                <label htmlFor="idMarket" className={`field-label ${validatedInputs.origin && !selectedOrigin[index] ? 'error' : ''}`}>
                  {t('CHOOSE_ORIGIN')} <span className="required-text-field">*</span>{' '}
                </label>
              </span>
              {validatedInputs.origin && !selectedOrigin[index] && <small className="error-message">{t('ORIGIN_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="antwerp"
                  name="antwerp"
                  value={item.antwerp}
                  className={`input-field ${validatedInputs.antwerp && !item.antwerp ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'antwerp', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('antwerp')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="antwerp" className={`field-label ${validatedInputs.antwerp && !item.antwerp ? 'error' : ''}`}>
                  {t('ANTWERP')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.antwerp && !item.antwerp && <small className="error-message">{t('ANTWERP_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="barcelona"
                  name="barcelona"
                  value={item.barcelona}
                  className={`input-field ${validatedInputs.barcelona && !item.barcelona ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'barcelona', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('barcelona')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="barcelona" className={`field-label ${validatedInputs.barcelona && !item.barcelona ? 'error' : ''}`}>
                  {t('BARCELONA')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.barcelona && !item.barcelona && <small className="error-message">{t('BARCELONA_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="hamburg"
                  name="hamburg"
                  value={item.hamburg}
                  className={`input-field ${validatedInputs.hamburg && !item.hamburg ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'hamburg', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('hamburg')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="hamburg" className={`field-label ${validatedInputs.hamburg && !item.hamburg ? 'error' : ''}`}>
                  {t('HAMBURG')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.hamburg && !item.hamburg && <small className="error-message">{t('HAMBURG_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="houston"
                  name="houston"
                  value={item.houston}
                  className={`input-field ${validatedInputs.houston && !item.houston ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'houston', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('houston')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="houston" className={`field-label ${validatedInputs.houston && !item.houston ? 'error' : ''}`}>
                  {t('HOUSTON')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.houston && !item.houston && <small className="error-message">{t('HOUSTON_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="miami"
                  name="miami"
                  value={item.miami}
                  className={`input-field ${validatedInputs.miami && !item.miami ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'miami', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('miami')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="miami" className={`field-label ${validatedInputs.miami && !item.miami ? 'error' : ''}`}>
                  {t('MIAMI')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.miami && !item.miami && <small className="error-message">{t('MIAMI_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="newOrleans"
                  name="newOrleans"
                  value={item.newOrleans}
                  className={`input-field ${validatedInputs.newOrleans && !item.newOrleans ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'newOrleans', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('newOrleans')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="newOrleans" className={`field-label ${validatedInputs.newOrleans && !item.newOrleans ? 'error' : ''}`}>
                  {t('NEW_ORLEANS')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.newOrleans && !item.newOrleans && <small className="error-message">{t('NEW_ORLEANS_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="newYork"
                  name="newYork"
                  value={item.newYork}
                  className={`input-field ${validatedInputs.newYork && !item.newYork ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'newYork', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('newYork')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="newYork" className={`field-label ${validatedInputs.newYork && !item.newYork ? 'error' : ''}`}>
                  {t('NEW_YORK')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.newYork && !item.newYork && <small className="error-message">{t('NEW_YORK_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="virginia"
                  name="virginia"
                  value={item.virginia}
                  className={`input-field ${validatedInputs.virginia && !item.virginia ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'virginia', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('virginia')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="virginia" className={`field-label ${validatedInputs.virginia && !item.virginia ? 'error' : ''}`}>
                  {t('VIRGINIA')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.virginia && !item.virginia && <small className="error-message">{t('VIRGINIA_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="compareToPreDay"
                  name="compareToPreDay"
                  value={item.compareToPreDay}
                  className={`input-field ${validatedInputs.compareToPreDay && !item.compareToPreDay ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'compareToPreDay', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('compareToPreDay')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="compareToPreDay" className={`field-label ${validatedInputs.compareToPreDay && !item.compareToPreDay ? 'error' : ''}`}>
                  {t('PRE_DAY')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validatedInputs.compareToPreDay && !item.compareToPreDay && <small className="error-message">{t('PREVIOUS_DAY_REQUIRED')}</small>}
            </div>

            {inputFields.length === index + 1 && filteredOptionsCount !== 1 && (
              <div className="button-content">
                <ButtonComponent type="custom" label={isFormFilled ? t('SAVE') : t('ADD')} icon={isFormFilled ? '' : 'pi pi-plus'} variant="action" onClick={handleAddFields} disabled={!isFieldValid()} />
              </div>
            )}
            {inputFields.length !== index + 1 && (
              <div className="button-content">
                <Button icon="pi pi-pencil" className="edit-button" onClick={() => handleEditClick(index)} />
                <Button icon="pi pi-trash" className="delete-button" onClick={() => handleClearRow(index)} />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default AddArabicaData
