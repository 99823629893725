import './styles.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const EditPortWiseCertifiedReport = ({ openModal, setOpenModal, selectedValue, selectedRowData, reportName, setFetchedData, countryName, portName }) => {
  const { t } = useTranslation()
  const { marketName, idMarket } = selectedValue
  const [currentDate, setCurrentDate] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isFormEnabled, setIsFormEnabled] = useState({
    editMode: false,
    submitButton: false
  })
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [formFields, setFormFields] = useState({
    date: '',
    valid: '',
    comparedToPreDay: '',
    nonTenderable: '',
    suspended: ''
  })

  const [validFields, setValidFields] = useState({
    valid: false,
    comparedToPreDay: false,
    nonTenderable: false,
    suspended: false
  })

  const [inputFields, setInputFields] = useState({
    date: '',
    antwerp: '',
    hamburg: '',
    houston: '',
    newOrleans: '',
    newYork: '',
    virginia: '',
    barcelona: '',
    miami: '',
    comparedToPreDayValue: ''
  })

  const [validationErrors, setValidationErrors] = useState({
    valid: false,
    comparedToPreDay: false,
    nonTenderable: false,
    suspended: false,
    antwerp: false,
    hamburg: false,
    houston: false,
    newOrleans: false,
    newYork: false,
    virginia: false,
    barcelona: false,
    miami: false,
    comparedToPreDayValue: false
  })

  const { date, valid, comparedToPreDay, nonTenderable, suspended } = formFields
  const { date: reportDate, antwerp, hamburg, houston, newOrleans, newYork, virginia, barcelona, miami, comparedToPreDayValue } = inputFields

  useEffect(() => {
    if (selectedRowData && openModal) {
      const getFormattedDate = (rowData) => {
        const dateValue = rowData.date || rowData.Date

        return dateValue ? moment(dateValue, 'DD-MMM-YYYY').toDate() : null
      }

      const formattedDate = getFormattedDate(selectedRowData)

      setCurrentDate(formattedDate)
      if (idMarket === 1) {
        setFormFields({
          date: selectedRowData.date,
          valid: selectedRowData.valid,
          comparedToPreDay: selectedRowData.comparedToPreDay,
          nonTenderable: selectedRowData.nonTenderable,
          suspended: selectedRowData.suspended
        })
      } else {
        setInputFields({
          date: selectedRowData.Date,
          antwerp: selectedRowData.Antwerp,
          hamburg: selectedRowData.HamburgBremen,
          houston: selectedRowData.Houston,
          newOrleans: selectedRowData.NewOrleans,
          newYork: selectedRowData.NewYork,
          virginia: selectedRowData.Virginia,
          barcelona: selectedRowData.barcelona,
          miami: selectedRowData.miami,
          comparedToPreDayValue: selectedRowData.ComparedPrevDay
        })
      }
    }
  }, [openModal, selectedRowData])

  const updateData = async () => {
    handleDismiss()

    try {
      let data
      let response

      if (idMarket === 1) {
        data = {
          fileName: reportName,
          port: portName.port,
          reportDetails: {
            date,
            valid,
            comparedToPreDay,
            nonTenderable,
            suspended
          }
        }
      } else {
        data = {
          country: countryName.country,
          filename: reportName,
          report: {
            date: reportDate,
            antwerp,
            barcelona,
            hamburgBremen: hamburg,
            houston,
            miami,
            newOrleans,
            newYork,
            virginia,
            comparedPrevDay: comparedToPreDayValue
          }
        }
      }

      if (idMarket === 1) {
        response = await apiAdapterCoffeeWeb.updatePortWiseStocksReportRobusta(data)
      } else {
        response = await apiAdapterCoffeeWeb.updatePortWiseStocksReportArabica(data)
      }

      if (response.status === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_UPDATED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })

        const filteredData = response?.data.filter((data) => (idMarket === 1 ? data.port === portName.port : data.country === countryName.country))

        setFetchedData(filteredData[0].report)
        setOpenModal(false)
        setButtonDisabled(true)
        setIsFormEnabled({
          editMode: false,
          submitButton: false
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_UPDATE_REPORT_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: updateData,
        onHide: handleDismiss
      })
    }
  }

  const handleInputChange = (fieldName, value) => {
    setButtonDisabled(false)

    if (idMarket === 1) {
      setFormFields((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    } else {
      setInputFields((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    }
  }

  const handleBlur = (fieldName) => {
    if (idMarket === 1) {
      setValidFields((previousState) => ({
        ...previousState,
        [fieldName]: true
      }))
    } else {
      setValidationErrors((previousState) => ({
        ...previousState,
        [fieldName]: true
      }))
    }
  }

  const dismissModal = () => {
    setOpenModal(false)
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })

    if (idMarket === 1) {
      setValidFields({
        valid: false,
        comparedToPreDay: false,
        nonTenderable: false,
        suspended: false
      })
    } else {
      setValidationErrors({
        certifiedStocks: false,
        comparedToPreDay: false,
        pendingGradingReport: false,
        grading: false
      })
    }
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const isDisable = () => {
    const fieldsToCheck = [comparedToPreDay, valid, nonTenderable, suspended]
    const fieldValidationCheck = [antwerp, hamburg, houston, newOrleans, newYork, virginia, barcelona, miami, comparedToPreDayValue]

    const fieldsToValidate = idMarket === 1 ? fieldsToCheck : fieldValidationCheck

    return fieldsToValidate.some((field) => field === '' || field === null)
  }

  const isFieldInvalid = (fieldName) => {
    let errorMessage

    if (idMarket === 1) {
      errorMessage = validFields[fieldName] && (formFields[fieldName] === '' || formFields[fieldName] === null)
    } else {
      errorMessage = validationErrors[fieldName] && (inputFields[fieldName] === '' || inputFields[fieldName] === null)
    }

    return errorMessage
  }

  const getInputClassName = (fieldName) => {
    const className = isFieldInvalid(fieldName) ? 'error-fields' : ''

    return className
  }

  const confirmResetForm = () => {
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })
    setShowDialog({ ...showDialog, showModal: false })

    if (idMarket === 1) {
      setFormFields({
        valid: selectedRowData.valid,
        comparedToPreDay: selectedRowData.comparedToPreDay,
        nonTenderable: selectedRowData.nonTenderable,
        suspended: selectedRowData.suspended
      })
    } else {
      setInputFields({
        date: selectedRowData.Date,
        antwerp: selectedRowData.Antwerp,
        hamburg: selectedRowData.HamburgBremen,
        houston: selectedRowData.Houston,
        newOrleans: selectedRowData.NewOrleans,
        newYork: selectedRowData.NewYork,
        virginia: selectedRowData.Virginia,
        barcelona: selectedRowData.barcelona,
        miami: selectedRowData.miami,
        comparedToPreDayValue: selectedRowData.ComparedPrevDay
      })
    }
  }

  const enableFormEditing = () => {
    setIsFormEnabled({
      editMode: true,
      submitButton: true
    })
  }

  const showResetConfirmationDialog = () => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onConfirmation: confirmResetForm,
      onHide: handleDismiss
    })
  }

  const footerContent = () => (
    <div className="footer-button">
      {isFormEnabled.submitButton && (
        <>
          <ButtonComponent type="cancel" onClick={dismissModal} />
          <ButtonComponent type="submit" onClick={updateData} disabled={buttonDisabled || isDisable()} />
        </>
      )}
    </div>
  )

  return (
    <>
      <Dialog visible={openModal} onHide={dismissModal} className="certified-stock-edit-modal" header={`${t('EDIT_PORT_WISE_CERTIFIED_REPORT')} - ${marketName}`} footer={footerContent}>
        <div className="edit-container">
          <div className="action-button">{isFormEnabled.editMode ? <ButtonComponent type={'reset'} onClick={showResetConfirmationDialog} /> : <ButtonComponent type={'edit'} onClick={enableFormEditing} />}</div>

          <div className="edit-form-wrapper">
            <div className="input-form-section">
              <span className="p-float-label">
                <Calendar id="date" name="date" value={currentDate} disabled={true} showIcon={true} className="calendar-input" />
                <label htmlFor="date">
                  {t('DATE')} <span className="required-text-field">*</span>
                </label>
              </span>
            </div>

            {idMarket === 1 && (
              <>
                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText id="portName" name="portName" value={portName.port} className={`input-text ${getInputClassName('portName')}`} disabled={true} />
                    <label htmlFor="portName" className={`input-label ${getInputClassName('portName')}`}>
                      {t('PORT_NAME')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="valid"
                      name="valid"
                      value={valid}
                      onChange={(event) => {
                        handleInputChange('valid', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('valid')
                      }}
                      className={`input-text ${getInputClassName('valid')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="valid" className={`input-label ${getInputClassName('valid')}`}>
                      {t('VALID')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('valid') && <small className="error-message">{t('VALID_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="compare-to-pre-day"
                      name="comparedToPreDay"
                      value={comparedToPreDay}
                      onChange={(event) => {
                        handleInputChange('comparedToPreDay', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('comparedToPreDay')
                      }}
                      className={`input-text ${getInputClassName('comparedToPreDay')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="compare-to-pre-day" className={`input-label ${getInputClassName('comparedToPreDay')}`}>
                      {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('comparedToPreDay') && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="non-tenderable"
                      name="nonTenderable"
                      value={nonTenderable}
                      onChange={(event) => {
                        handleInputChange('nonTenderable', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('nonTenderable')
                      }}
                      className={`input-text ${getInputClassName('nonTenderable')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="non-tenderable" className={`input-label ${getInputClassName('nonTenderable')}`}>
                      {t('NON_TENDERABLE')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('nonTenderable') && <small className="error-message">{t('NON_TENDERABLE_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="suspended"
                      name="suspended"
                      value={suspended}
                      onChange={(event) => {
                        handleInputChange('suspended', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('suspended')
                      }}
                      className={`input-text ${getInputClassName('suspended')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="suspended" className={`input-label ${getInputClassName('suspended')}`}>
                      {t('SUSPENDED')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('suspended') && <small className="error-message">{t('SUSPENDED_ERROR_MESSAGE')}</small>}
                </div>
              </>
            )}

            {idMarket === 2 && (
              <>
                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText id="countryName" name="countryName" value={countryName.country} className={`input-text ${getInputClassName('countryName')}`} disabled={true} />
                    <label htmlFor="countryName" className={`input-label ${getInputClassName('countryName')}`}>
                      {t('COUNTRY')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                </div>
                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="antwerp"
                      name="antwerp"
                      value={antwerp}
                      onChange={(event) => {
                        handleInputChange('antwerp', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('antwerp')
                      }}
                      className={`input-text ${getInputClassName('antwerp')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="antwerp" className={`input-label ${getInputClassName('antwerp')}`}>
                      {t('ANTWERP')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('antwerp') && <small className="error-message">{t('ANTWERP_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="hamburg"
                      name="hamburg"
                      value={hamburg}
                      onChange={(event) => {
                        handleInputChange('hamburg', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('hamburg')
                      }}
                      className={`input-text ${getInputClassName('hamburg')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="hamburg" className={`input-label ${getInputClassName('hamburg')}`}>
                      {t('HAMBURG')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('hamburg') && <small className="error-message">{t('HAMBURG_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="houston"
                      name="houston"
                      value={houston}
                      onChange={(event) => {
                        handleInputChange('houston', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('houston')
                      }}
                      className={`input-text ${getInputClassName('houston')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="houston" className={`input-label ${getInputClassName('houston')}`}>
                      {t('HOUSTON')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('houston') && <small className="error-message">{t('HOUSTON_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="newOrleans"
                      name="houston"
                      value={newOrleans}
                      onChange={(event) => {
                        handleInputChange('newOrleans', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('newOrleans')
                      }}
                      className={`input-text ${getInputClassName('newOrleans')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="newOrleans" className={`input-label ${getInputClassName('newOrleans')}`}>
                      {t('NEW_ORLEANS')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('newOrleans') && <small className="error-message">{t('HOUSTON_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="newYork"
                      name="newYork"
                      value={newYork}
                      onChange={(event) => {
                        handleInputChange('newYork', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('newYork')
                      }}
                      className={`input-text ${getInputClassName('newYork')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="newYork" className={`input-label ${getInputClassName('newYork')}`}>
                      {t('NEW_YORK')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('newYork') && <small className="error-message">{t('NEW_YORK_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="virginia"
                      name="virginia"
                      value={virginia}
                      onChange={(event) => {
                        handleInputChange('virginia', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('virginia')
                      }}
                      className={`input-text ${getInputClassName('virginia')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="virginia" className={`input-label ${getInputClassName('virginia')}`}>
                      {t('VIRGINIA')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('virginia') && <small className="error-message">{t('VIRGINIA_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="barcelona"
                      name="barcelona"
                      value={barcelona}
                      onChange={(event) => {
                        handleInputChange('barcelona', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('barcelona')
                      }}
                      className={`input-text ${getInputClassName('barcelona')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="barcelona" className={`input-label ${getInputClassName('barcelona')}`}>
                      {t('BARCELONA')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('barcelona') && <small className="error-message">{t('BARCELONA_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="miami"
                      name="miami"
                      value={miami}
                      onChange={(event) => {
                        handleInputChange('miami', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('miami')
                      }}
                      className={`input-text ${getInputClassName('miami')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="miami" className={`input-label ${getInputClassName('miami')}`}>
                      {t('MIAMI')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('miami') && <small className="error-message">{t('MIAMI_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-form-section">
                  <span className="p-float-label">
                    <InputText
                      id="comparedToPreDayValue"
                      name="comparedToPreDayValue"
                      value={comparedToPreDayValue}
                      onChange={(event) => {
                        handleInputChange('comparedToPreDayValue', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('comparedToPreDayValue')
                      }}
                      className={`input-text ${getInputClassName('comparedToPreDayValue')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="compare-to-pre-day" className={`input-label ${getInputClassName('comparedToPreDayValue')}`}>
                      {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('comparedToPreDayValue') && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>

      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </>
  )
}

export default EditPortWiseCertifiedReport
