import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import Validation from 'Utils/Validation'
import { ButtonComponent } from 'Components/UIComponents'

const AddRobustaData = (props) => {
  const { fields, setFields, coffeeReportData, setDateValue, touchedFields, setTouchedFields, selectedData, setSelectedData, setFilteredOptionsCount, filteredOptionsCount, setSelectedDate, selectedDate } = props
  const { t } = useTranslation()
  const [editingIndex, setEditingIndex] = useState(null)
  const [isFormFilled, setIsFormFilled] = useState(false)

  useEffect(() => {
    const filled = fields.every((item) => item.valid && item.compareToPreDay && item.NonTenderable && item.suspend && item.origin)

    setIsFormFilled(filled)
  }, [fields])

  const handleDateChange = (event) => {
    const chosenDate = event.value
    const formattedDate = chosenDate ? moment(chosenDate).format('DD-MMM-YYYY') : ''

    setDateValue(formattedDate)
    setSelectedDate(chosenDate)
  }

  const handleDropdownChange = (index, event) => {
    const value = event.value
    const updatedFields = [...fields]

    updatedFields[index] = {
      ...updatedFields[index],
      origin: value.origin,
      slNo: value.slNo
    }

    setSelectedData((prevSelectedData) => ({
      ...prevSelectedData,
      [index]: value
    }))

    setFields(updatedFields)
  }

  const handleInputChange = (index, field, value) => {
    const isValid = Validation.phoneCharValidation(value)

    const updatedFields = [...fields]

    if (isValid) {
      updatedFields[index][field] = value
    } else {
      updatedFields[index][field] = ''
    }

    setFields(updatedFields)
  }

  const handleAddFields = () => {
    setEditingIndex(null)
    setFields([
      ...fields,
      {
        valid: '',
        compareToPreDay: '',
        NonTenderable: '',
        suspend: ''
      }
    ])

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      valid: false,
      compareToPreDay: false,
      NonTenderable: false,
      suspend: false,
      origin: false
    }))
  }

  const handleBlur = (fieldName) => {
    setTouchedFields((prev) => ({
      ...prev,
      [fieldName]: true
    }))
  }

  const handleClearRow = (index) => {
    const updatedFields = [...fields]
    const updatedSelectedData = { ...selectedData }

    updatedFields.splice(index, 1)

    delete updatedSelectedData[index]

    const newSelectedData = Object.keys(updatedSelectedData).reduce((acc, key) => {
      const currentIndex = parseInt(key, 10)

      if (currentIndex > index) {
        acc[currentIndex - 1] = updatedSelectedData[currentIndex]
      } else {
        acc[currentIndex] = updatedSelectedData[currentIndex]
      }

      return acc
    }, {})

    setFields(updatedFields)
    setSelectedData(newSelectedData)
  }

  const isFieldValid = () => {
    const disable = fields.every((item) => item.valid && item.compareToPreDay && item.NonTenderable && item.suspend && item.origin)

    return disable
  }

  const isFieldDisabled = (index) => index < fields.length - 1 && editingIndex !== index

  const getFilteredOptions = (index) => {
    const selectedOrigins = fields.map((field) => field.origin)
    const filteredOptions = coffeeReportData.filter((option) => !selectedOrigins.includes(option.origin) || selectedData[index]?.origin === option.origin)

    setFilteredOptionsCount(filteredOptions.length)

    return filteredOptions
  }

  const handleEditClick = (index) => {
    setEditingIndex(index)
  }

  return (
    <>
      <div className="form-container">
        <div className="input-field-wrapper">
          <span className="p-float-label">
            <Calendar
              id="date"
              name="date"
              value={selectedDate}
              onChange={handleDateChange}
              onBlur={() => {
                handleBlur('date')
              }}
              maxDate={new Date()}
              showIcon
              className={`calendar-field ${touchedFields.date && !selectedDate ? 'error' : ''}`}
            />
            <label htmlFor="date" className={`field-label ${touchedFields.date && !selectedDate ? 'error' : ''}`}>
              {t('DATE')} <span className="required-text-field">*</span>
            </label>
          </span>
          {touchedFields.date && !selectedDate && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
        </div>
      </div>

      <div className="input-container">
        {fields.map((item, index) => (
          <div className="field-wrapper" key={index}>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <Dropdown value={selectedData[index] || null} options={getFilteredOptions(index)} onChange={(event) => handleDropdownChange(index, event)} placeholder={t('CHOOSE_ORIGIN')} name="origin" optionLabel="origin" className={`input-field ${touchedFields.origin && !selectedData[index] ? 'error' : ''}`} onBlur={() => handleBlur('origin')} disabled={isFieldDisabled(index)} />
                <label htmlFor="idMarket" className={`field-label ${touchedFields.origin && !selectedData[index] ? 'error' : ''}`}>
                  {t('CHOOSE_ORIGIN')} <span className="required-text-field">*</span>{' '}
                </label>
              </span>
              {touchedFields.origin && !selectedData[index] && <small className="error-message">{t('ORIGIN_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="valid"
                  name="valid"
                  value={item.valid}
                  className={`input-field ${touchedFields.valid && !item.valid ? 'error' : ''}`}
                  onChange={(event) => {
                    handleInputChange(index, 'valid', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('valid')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="valid" className={`field-label ${touchedFields.valid && !item.valid ? 'error' : ''}`}>
                  {t('VALID')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.valid && !item.valid && <small className="error-message">{t('VALID_IS_REQUIRED')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="compareToPreDay"
                  name="compareToPreDay"
                  value={item.compareToPreDay}
                  onChange={(event) => {
                    handleInputChange(index, 'compareToPreDay', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('compareToPreDay')
                  }}
                  className={`input-field ${touchedFields.compareToPreDay && !item.compareToPreDay ? 'error' : ''}`}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="compareToPreDay" className={`field-label ${touchedFields.compareToPreDay && !item.compareToPreDay ? 'error' : ''}`}>
                  {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.compareToPreDay && !item.compareToPreDay && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="nonTenderable"
                  name="NonTenderable"
                  value={item.NonTenderable}
                  onChange={(event) => {
                    handleInputChange(index, 'NonTenderable', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('NonTenderable')
                  }}
                  className={`input-field ${touchedFields.NonTenderable && !item.NonTenderable ? 'error' : ''}`}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="nonTenderable" className={`field-label ${touchedFields.NonTenderable && !item.NonTenderable ? 'error' : ''}`}>
                  {t('NON_TENDERABLE')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.NonTenderable && !item.NonTenderable && <small className="error-message">{t('NON_TENDERABLE_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="suspend"
                  name="suspend"
                  value={item.suspend}
                  onChange={(event) => {
                    handleInputChange(index, 'suspend', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('suspend')
                  }}
                  className={`input-field ${touchedFields.suspend && !item.suspend ? 'error' : ''}`}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="suspend" className={`field-label ${touchedFields.suspend && !item.suspend ? 'error' : ''}`}>
                  {t('SUSPENDED')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.suspend && !item.suspend && <small className="error-message">{t('SUSPENDED_ERROR_MESSAGE')}</small>}
            </div>

            {fields.length === index + 1 && filteredOptionsCount !== 1 && (
              <div className="button-content">
                <ButtonComponent type="custom" label={isFormFilled ? t('SAVE') : t('ADD')} icon={isFormFilled ? '' : 'pi pi-plus'} variant="action" onClick={handleAddFields} disabled={!isFieldValid()} />
              </div>
            )}

            {fields.length !== index + 1 && (
              <div className="button-content">
                <Button icon="pi pi-pencil" className="edit-button" onClick={() => handleEditClick(index)} />
                <Button icon="pi pi-trash" className="delete-button" onClick={() => handleClearRow(index)} />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default AddRobustaData
