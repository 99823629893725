const coffeeReportJsonLinks = {
  // Certified Stocks Report
  robustaTotalCertifiedStocks: 'coffee-report-data.json',
  arabicaTotalCertifiedStocks: 'total-stock-certified-arabica.json',

  robustaAllCountryCertifiedStocks: 'country-wise-reports.json',
  arabicaAllCountryCertifiedStocks: 'country-warehouse-arabica-data.json',

  robustaPortWiseCertifiedStocks: 'portwise-certified-stock-robusta.json',
  arabicaCountryWiseCertifiedStocks: 'countrywise-certified-stocks-report-arabica.json',

  // Commitment of Traders Report
  robustaWeeklyWiseCotReports: 'robusta-cot-report.json',
  arabicaWeeklyWiseCotReports: 'arabica-weekly-cot.json',

  robustaLotWiseComparison: 'robusta-lot-wise-report.json',
  arabicaLotWiseComparison: 'arabica-lot-wise.json',

  robustaTraderWiseComparison: 'robusta-trader-wise-report.json',
  arabicaTraderWiseComparison: 'arabica-trader-wise.json',

  // Country Wise Exports Report
  brazilCountryWiseExportsReport: 'brazil.json',
  columbiaCountryWiseExportsReport: 'columbia.json',
  indiaCountryWiseExportsReport: 'india.json',
  ugandaCountryWiseExportsReport: 'uganda.json',
  vietnamCountryWiseExportsReport: 'vitenam.json'
}

export default coffeeReportJsonLinks
