import './styles.scss'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast'
import { InputTextarea } from 'primereact/inputtextarea'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import Loader from 'Components/Loader'
import Validation from 'Utils/Validation'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const NewsDeleteModal = ({ setLoading, openRemarkDialog, setOpenRemarkDialog, eachRowData, setDeletedNews }) => {
  const { t } = useTranslation()
  const { id } = getLocalUserDetails()
  const toast = useRef()
  const [selectedReason, setSelectedReason] = useState(null)
  const [isOthersSelected, setIsOthersSelected] = useState(false)
  const [isReasonEmpty, setIsReasonEmpty] = useState(false)
  const [validationState, setValidationState] = useState(false)
  const [customReason, setCustomReason] = useState('')
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })

  const reasons = [{ deleteReason: 'Duplicate' }, { deleteReason: 'Others' }]

  const deleteNews = async () => {
    setOpenRemarkDialog(false)
    setShowDialog({ ...showDialog, showModal: false })

    const { deleteReason } = selectedReason
    const newsId = eachRowData.id

    setLoading(true)
    try {
      const deleteNewsResponse = await apiAdapterCoffeeWeb.deleteNews({ newsId, userId: id, reasonToDelete: customReason || deleteReason })
      const { status: statusCode } = deleteNewsResponse

      if (statusCode === 200) {
        setLoading(false)
        setDeletedNews(newsId)
        handleDismiss()
        toast.current.show({ severity: 'success', summary: 'Success', detail: t('NEWS_DELETED_SUCCESSFULLY'), life: 4000 })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NEWS_DELETE_FAILED'),
        onRetry: () => deleteNews(),
        onHide: handleClose
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    setValidationState(false)
    const selectedValue = e.value.deleteReason

    setSelectedReason(e.value)

    if (selectedValue === 'Others') {
      setIsOthersSelected(true)
    } else {
      setIsOthersSelected(false)
    }
  }

  const handleFieldChange = (e) => {
    setValidationState(false)
    const { name, value } = e.target

    if (name === 'customReason') {
      if (Validation.nameValidation(value) || Validation.isInputFieldEmpty(value)) {
        setCustomReason(value)
      }
    }
  }

  const handleBlur = (e) => {
    const { name } = e.target

    if (name === 'reasonToDelete') {
      setIsReasonEmpty(!e.value)
    } else {
      setValidationState(!e.target.value.trim())
    }
  }

  const handleDismiss = () => {
    setSelectedReason('')
    setCustomReason('')
    setIsReasonEmpty(false)
    setIsOthersSelected(false)
    setOpenRemarkDialog(false)
    setValidationState(false)
  }

  const handleClose = () => {
    handleDismiss()
    setShowDialog({ ...showDialog, showModal: false })
  }

  const footerContent = (
    <div className="delete-news-button-container">
      <ButtonComponent type="cancel" onClick={handleDismiss} />
      <ButtonComponent type="submit" onClick={deleteNews} disabled={!selectedReason || (isOthersSelected && !customReason)} />
    </div>
  )

  return (
    <div className="delete-news-container" data-testid="delete-news-dialog">
      <Dialog header={t('DELETE_REMARK')} visible={openRemarkDialog} footer={footerContent} className="delete-news-dialog" onHide={handleDismiss}>
        <div className="dialog-content-wrapper">
          <div className="drop-down-field">
            <span className="p-float-label">
              <Dropdown id="reasonToDelete" value={selectedReason} onChange={handleChange} onBlur={handleBlur} options={reasons} name="reasonToDelete" className={isReasonEmpty ? 'p-invalid remark-drop-down' : 'remark-drop-down'} optionLabel="deleteReason" placeholder={t('CHOOSE_REASON')} data-testid="delete-reason-dropdown" />
              <label htmlFor="reasonToDelete" className={isReasonEmpty ? 'p-error' : 'field-label'}>
                {t('SELECT_REASON')} <span className="required-star"> *</span>
              </label>
            </span>
            {isReasonEmpty && (
              <div className="error-message">
                <small>{t('PLEASE_CHOOSE_A_REASON')}</small>
              </div>
            )}
          </div>
          <div className="custom-reason-field-wrapper">
            {isOthersSelected && (
              <div className="custom-reason-field-container">
                <span className="p-float-label">
                  <InputTextarea id="customReason" name="customReason" value={customReason} rows={4} cols={30} placeholder={t('ENTER_CUSTOM_REASON')} className={validationState ? 'p-invalid custom-reason-field' : 'custom-reason-field'} onChange={handleFieldChange} onBlur={handleBlur} data-testid="custom-reason-field" />
                  <label htmlFor="customReason" className={validationState ? 'p-error' : 'field-label'}>
                    {t('ENTER_REASON')} <span className="required-star"> *</span>
                  </label>
                </span>

                {validationState && (
                  <div className="error-message">
                    <small>{t('PLEASE_FILL_THIS')}</small>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} position="top-right" />
      <DialogModal {...showDialog} onDismiss={handleClose} />
    </div>
  )
}

export default Loader(NewsDeleteModal)
