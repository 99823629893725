import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { FileUpload } from 'primereact/fileupload'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'
import { REG_EX, ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const EditMenuGroup = ({ setLoading, mockImageUrl, mockEditMenuGroupApiFlag }) => {
  const history = useHistory()
  const { id } = useParams()
  const location = useLocation()
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const { t } = useTranslation()
  const [userMenu, setUserMenu] = useState({
    Menu: '',
    menuName: '',
    menuIcon: '',
    menuUrl: '',
    pageCode: '',
    mobilePath: '',
    parentId: '',
    isadminConsoleMenu: ''
  })

  const [menuUser, setMenuUser] = useState([])
  const [menusForFindDuplicateData, setMenusForFindDuplicateData] = useState([])
  const [menuData, setMenuData] = useState({})
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageUrl, setImageUrl] = useState('')
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [duplicateMenu, setDuplicateMenu] = useState(null)
  const [duplicateMenuUrl, setDuplicateMenuUrl] = useState(null)

  const [errorState, setErrorState] = useState({
    Menu: '',
    menuName: '',
    menuIcon: '',
    menuUrl: '',
    pageCode: '',
    mobilePath: ''
  })

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })
  const { menuName, menuIcon, menuUrl, pageCode, mobilePath, parentId, isadminConsoleMenu } = userMenu
  const { menuName: initialMenuName, menuUrl: initialMenuUrl, mobilePath: initialMobilePath, pageCode: initialPageCode, menuIcon: initialMenuIcon } = menuData

  useEffect(() => {
    init()

    // Testing code: set image URL or create new menu group based on flags
    if (mockImageUrl) {
      setImageUrl(mockImageUrl)
    } else if (mockEditMenuGroupApiFlag) {
      editMenuMaster()
      userData()
      submitFileToS3Bucket()
    }
  }, [])

  const init = () => {
    getallMenuMaster()
    userData()
  }

  const userData = async () => {
    handleDismiss()
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.GetMenuByMenuIds(id)

      const userMenuData = data[0]

      setUserMenu(userMenuData)
      setMenuData(userMenuData)
      setImageUrl(userMenuData.menuIcon)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: userData,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const getallMenuMaster = async () => {
    handleDismiss()
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAllMenu()
      const filteredMenuList = response.data.filter((menu) => menu.isadminConsoleMenu)

      setMenuUser(filteredMenuList)
      if (tab === 'admin_menu') {
        const adminMenuList = response.data.filter((menu) => menu.isadminConsoleMenu)
        const adminMenus = adminMenuList.map((item) => ({ id: item.id, menuName: item.menuName, menuUrl: item.menuUrl }))

        setMenusForFindDuplicateData(adminMenus)
      } else if (tab === 'client_menu') {
        const userMenuList = response.data.filter((menu) => !menu.isadminConsoleMenu)
        const UserMenus = userMenuList.map((item) => ({ id: item.id, menuName: item.menuName, menuUrl: item.menuUrl }))

        setMenusForFindDuplicateData(UserMenus)
      }
    } catch {
      if (tab === 'admin_menu') {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('NO_DATA_FOUND'),
          onRetry: getallMenuMaster,
          onHide: handleDismiss
        })
      }
    }
    setLoading(false)
  }

  const submitFileToS3Bucket = async (event) => {
    handleDismiss()
    setLoading(true)
    const selectedFile = event?.files[0]

    const fileData = new FormData()

    fileData.append('file', selectedFile)

    try {
      const response = await apiAdapterCoffeeWeb.uploadFileToS3Bucket(fileData)

      setSelectedFile(response.data)
      setSubmitButtonDisabled(false)
      setImageUrl(response.data)
      setUserMenu({ ...userMenu, menuIcon: response.data })
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_UPLOAD'),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const editMenuMaster = async () => {
    handleDismiss()
    setLoading(true)
    try {
      const data = {
        id: userMenu.id,
        menuName: menuName,
        menuUrl: menuUrl,
        isActive: true,
        parentId: parentId,
        menuClass: 'string',
        menuIcon: selectedFile || menuIcon,
        isAvailable: true,
        parentDisaplayOrder: 0,
        isWebDisplayEnable: true,
        isMobDisplayEnable: true,
        isPwadisplayEnable: true,
        isDemoMenu: true,
        pageCode: pageCode,
        mobilePath: mobilePath,
        isLaunch: true,
        isMobile: true,
        menulock: true,
        isadminConsoleMenu: isadminConsoleMenu
      }

      const response = await apiAdapterCoffeeWeb.putMenuMaster(data)

      if (response.data.statusCode === 201) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('MENU_UPDATED_SUCCESSFULLY'),
          onHide: handleSuccess,
          onSuccess: handleSuccess
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_UPDATE_MENU'),
        onHide: handleDismiss,
        onRetry: editMenuMaster
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    if (name === 'menuName') {
      const filteredMenu = menusForFindDuplicateData.filter(({ id: itemId }) => itemId !== +id).map(({ menuName }) => menuName.toLowerCase())

      const allowToSave = filteredMenu.some((item) => item.toLowerCase() === value.toLowerCase())

      setDuplicateMenu(!allowToSave)
    } else if (name === 'menuUrl') {
      const filteredMenuUrl = menusForFindDuplicateData.filter(({ id: itemId }) => itemId !== +id).map(({ menuUrl }) => menuUrl.toLowerCase())

      const allowToSave = filteredMenuUrl.some((item) => item.toLowerCase() === value.toLowerCase())

      setDuplicateMenuUrl(!allowToSave)
    }

    let isInvalidValue = value === '' || (!REG_EX.NAME_REG_EX.test(value) && name !== 'menuUrl')

    if (name === 'menuUrl' || name === 'mobilePath') {
      isInvalidValue = value === '' || !REG_EX.URL_REG_EX.test(value)
    }

    setUserMenu((prevData) => ({
      ...prevData,
      [name]: value
    }))

    setErrorState((prevErrState) => ({
      ...prevErrState,
      [name]: isInvalidValue
    }))
    setSubmitButtonDisabled(false)
  }

  const handleDropdown = (event) => {
    const selectedValue = event.value

    setUserMenu({ ...userMenu, parentId: selectedValue })
    setErrorState((prev) => ({
      ...prev,
      Menu: ''
    }))
  }

  const handleBlur = () => {
    const isInvalid = !userMenu.parentId

    setErrorState((prev) => ({
      ...prev,
      Menu: isInvalid ? 'Value is required' : ''
    }))
    setSubmitButtonDisabled(isInvalid)
  }

  const menuItems = [...menuUser.map((item) => ({ label: item.menuName, value: item.id }))]

  const onSubmit = (data) => {
    setUserMenu(data)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleSaveButton = (e) => {
    editMenuMaster(selectedFile)
  }

  const handleDeleteImage = () => {
    setSelectedFile(null)
    setImageUrl('')
    setSubmitButtonDisabled(false)
    setUserMenu({ ...userMenu, menuIcon: '' })
  }

  const handleSuccess = () => {
    if (tab === 'admin_menu') {
      params.set('tab', 'admin_menu')
      history.push({ pathname: `${ROUTE_STRINGS.listmenu}`, search: params.toString() })
    } else {
      params.set('tab', 'client_menu')
      history.push({ pathname: `${ROUTE_STRINGS.listmenu}`, search: params.toString() })
    }
  }

  const hasErrors = Object.values(errorState).some((value) => value)

  const validate = () => {
    const errors = {}

    if (!menuName) {
      errors.menuName = t('VALUE_IS_REQUIRED')
    } else if (duplicateMenu === false) {
      errors.menuName = t('DATA_ALREADY_EXISTS')
    }
    if (!menuUrl) {
      errors.menuUrl = t('VALUE_IS_REQUIRED')
    } else if (duplicateMenuUrl === false) {
      errors.menuUrl = t('DATA_ALREADY_EXISTS')
    }
    if (!pageCode) {
      errors.pageCode = t('VALUE_IS_REQUIRED')
    }
    if (!mobilePath) {
      errors.mobilePath = t('VALUE_IS_REQUIRED')
    }

    return errors
  }
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const isButtonCheck = initialMenuName === menuName && initialMenuUrl === menuUrl && initialPageCode === pageCode && initialMobilePath === mobilePath && initialMenuIcon === menuIcon
  const isDisabled = isButtonCheck || submitButtonDisabled || !menuUrl || !menuName || !pageCode || !mobilePath || (hasErrors && (!duplicateMenu || !duplicateMenuUrl))
  const isSubmitButtonDisable = isDisabled || duplicateMenu === false || duplicateMenuUrl === false

  return (
    <>
      <LayoutContainer title={tab === 'admin_menu' ? t('EDIT_ADMIN_MENU') : t('EDIT_CLIENT_MENU')} breadCrumbsTitle={tab === 'admin_menu' ? 'editAdminMenu' : 'editClientMenu'}>
        <div className="edit-menu" data-testId="editMenu-form">
          <Form
            onSubmit={onSubmit}
            initialValues={userMenu}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {tab === 'admin_menu' && (
                  <Field
                    name="menu"
                    render={({ input }) => (
                      <div className="field">
                        <div className="dropdown">
                          <div className="p-float-label">
                            <Dropdown {...input} id="menu" name="menu" value={userMenu.parentId} options={menuItems} onChange={handleDropdown} onBlur={handleBlur} className={`${errorState.Menu ? 'p-invalid' : 'custom-hover-color'}`} placeholder={t('SELECT_MENU')} data-testId="menu-dropdown" />
                            <label htmlFor="menu" className={errorState.Menu ? 'error-label' : ''}>
                              {t('MENUS')} <span className="star-required">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="error-message">{errorState.Menu && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />
                )}

                <Field
                  name="menuName"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="menuName" {...input} value={userMenu.menuName} onChange={handleChange} className={classNames(`${isFormFieldValid(meta) ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`)} data-testId="menu-name" />

                        <label htmlFor="menuName" className={classNames(`${isFormFieldValid(meta) ? 'p-error error-label' : ''}`)}>
                          {t('MENU_NAME')} <span className="star-required">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />
                <Field
                  name="menuUrl"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="menuUrl" {...input} onChange={handleChange} value={userMenu.menuUrl} className={classNames(`${isFormFieldValid(meta) ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`)} data-testId="menu-url" />

                        <label htmlFor="menuUrl" className={classNames(`${isFormFieldValid(meta) ? 'p-error error-label' : ''}`)}>
                          {t('MENU_URL')} <span className="star-required">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />

                <Field
                  name="pageCode"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="pageCode" {...input} onChange={handleChange} value={userMenu.pageCode} className={classNames(`${isFormFieldValid(meta) ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`)} data-testId="page-code" />

                        <label htmlFor="pageCode" className={classNames(`${isFormFieldValid(meta) ? 'p-error error-label' : ''}`)}>
                          {t('PAGE_CODE')} <span className="star-required">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />
                {tab === 'client_menu' && (
                  <Field
                    className="menu"
                    name="mobilePath"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="mobilePath" {...input} onChange={handleChange} value={userMenu.mobilePath} className={classNames(`${isFormFieldValid(meta) ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`)} data-testId="mobile-path" />

                          <label htmlFor="mobilePath" className={classNames(`${isFormFieldValid(meta) ? 'p-error error-label' : ''}`)}>
                            {t('MOBILE_PATH')} <span className="star-required">*</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                )}
                <div className="file-upload">
                  <label htmlFor="menu" className="menu-upload">
                    {t('MENU_LOGO')}
                  </label>
                  <FileUpload
                    chooseLabel="Choose File"
                    uploadLabel="Upload Now"
                    cancelLabel="Cancel Upload"
                    accept="image/*,.pdf"
                    auto={true}
                    data-testId="file-upload"
                    onSelect={(event) => submitFileToS3Bucket(event)}
                    emptyTemplate={
                      <div>
                        {imageUrl ? (
                          <div className="edit-file-upload">
                            <div className="image-file">
                              <div className="edit-image-container">
                                <img src={imageUrl} alt="new-component" />
                                <div className="cancel-action">
                                  <Button data-testId="cancel-button" type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={handleDeleteImage} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <p className="choose-title">{t('IMAGE_UPLOAD')}</p>
                        )}
                      </div>
                    }
                  />
                </div>

                <div className="button-container">
                  <ButtonComponent type={'cancel'} onClick={handleSuccess} />
                  <ButtonComponent type={'submit'} onClick={handleSaveButton} disabled={isSubmitButtonDisable} />
                </div>
              </form>
            )}
          />
        </div>

        <DialogModal {...showDialog} onDismiss={handleDismiss} />
      </LayoutContainer>
    </>
  )
}

export default Loader(EditMenuGroup)
