const ROUTE_STRINGS = {
  login: '/',
  resetViaPhone: 'authMod=reset_password_phone',
  resetViaEmail: 'authMod=reset_password_email',
  dashboard: '/admin',
  profile: '/admin/profile',
  coffeeentries: '/admin/coffeeentries',
  differentialsentry: '/admin/differentialsentry',
  freightrateentry: '/admin/freightrateentry',
  listNewsFeed: '/admin/listNewsFeed',
  addnewsfeed: '/admin/addnewsfeed',
  editNewsFeed: '/admin/editnewsfeed',
  newsreadmore: '/admin/newsreadmore',
  editadminnewsfeed: '/admin/editadminnewsfeed',
  newsfeedapprovalscreen: '/admin/newsfeedapprovalscreen',
  editmenugroup: '/admin/editmenugroup',
  listUsers: '/admin/listusers',
  addUser: '/admin/adduser',
  edituser: '/admin/edituser',
  userStats: '/admin/user-stats',
  edituserProfile: '/admin/edituser/',
  listmenu: '/admin/listmenu',
  addmenu: '/admin/addmenu',
  addRoles: '/admin/addrole',
  listRoles: '/admin/listroles',
  roleFeature: '/admin/role-features',
  coffeequotes: '/admin/dashboard',
  globalprice: '/admin/globalprice',
  getSubscription: '/admin/getsubscription',
  addSubscription: '/admin/addsubscription',
  editSubscription: '/admin/editsubscription',
  subscriptionfeatures: '/admin/subscriptionfeatures',
  marketdata: '/admin/marketdata',
  approveGlobalData: '/admin/approveglobaldata',
  globaldifferentialpreview: '/admin/globaldifferentialpreview',
  globalrawdatapreview: '/admin/globalrawdatapreview',
  home: '/admin/coffeeweb',
  discover: '/admin/discover',
  connections: '/admin/connections',
  followers: '/admin/followers',
  following: '/admin/following',
  connectionRequests: '/admin/connectionrequests',
  notifications: '/admin/notifications',
  messages: '/admin/messages',
  myprofile: '/admin/myprofile',
  listPayment: '/admin/listpayment',
  mapmenu: '/admin/mapmenu',
  addPayment: '/admin/addpayment',
  editPayment: '/admin/editpayment',
  addmobile: '/admin/addmobile',
  listMobile: '/admin/listmobile',
  addLanguage: '/admin/addlanguage',
  editLanguage: '/admin/editlanguage',
  listLanguage: '/admin/listlanguage',
  listappsetting: '/admin/listappsetting',
  addScreen: '/admin/addscreen',
  listScreen: '/admin/listscreen',
  editScreen: '/admin/editscreen',
  addMapping: '/admin/addMapping',
  editMapping: '/admin/editMapping',
  listMapping: '/admin/listmapping',
  listscreenlanguage: '/admin/listscreenlanguage',
  companyapproved: '/admin/companyapproved',
  coffeepriceupload: '/admin/coffeepriceupload',
  editnews: '/admin/editnews',
  previewpdf: '/admin/previewpdf',
  addPaymentCountry: '/admin/addpaymentcountry',
  listtrendingnews: '/admin/listtrendingnews',
  // firebaseedit: '/admin/firebaseedit',
  subscriptionpaymentmapping: '/admin/subscriptionpaymentmapping',
  addTerminalDetails: '/admin/add-terminal-details',
  editTerminalDetails: '/admin/edit-terminal-details',
  listTerminalDetails: '/admin/list-terminal-details',
  addMobile: '/admin/addmobile',
  listPaymentCountry: '/admin/listpaymentcountry',
  editUser: '/admin/edituser',
  listSubscriptionPaymentMapping: '/admin/list-Subscription-Payment-Mapping',
  addSubscriptionPaymentMapping: '/admin/add-Subscription-Payment-Mapping',
  editSubscriptionPaymentMapping: '/admin/edit-Subscription-Payment-Mapping',
  subscriptionConfig: '/admin/subscription-config',
  paymentReports: '/admin/payment-report',
  coffeeQuotesIceMarket: '/admin/coffee-quotes/ice-market',
  reconciledReports: '/admin/reconciled-reports',
  certifiedStockReport: '/admin/certified-stock-report',

  // USDA-COFFEE-REPORT
  listUSDACoffeeReport: '/admin/usda-coffee-report',
  addUSDACoffeeReport: '/admin/add-usda-coffee-report',
  editUSDACoffeeReport: '/admin/edit-usda-coffee-report'
}

const TAB_MENUS = {
  // App-Settings
  settingsRoute: 'settings',
  optionsRoute: 'options',
  workBooksRoute: 'work-book',
  coffeeQuotesRoute: 'coffee-quotes',

  // List-Users
  clientsRoute: 'clients',
  contributorsRoute: 'contributors'
}

export { ROUTE_STRINGS, TAB_MENUS }
