import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const EditAllCountryWiseStockReport = ({ selectedItem, isModalOpen, setIsModalOpen, tableRowData, reportName, setCoffeeReportData }) => {
  const { rowDetails, date } = tableRowData
  const { marketName, idMarket } = selectedItem
  const { t } = useTranslation()
  const [currentDate, setCurrentDate] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isFormEnabled, setIsFormEnabled] = useState({
    editMode: false,
    submitButton: false
  })
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [editEntityFields, setEditEntityFields] = useState({
    origin: '',
    valid: '',
    compareToPreDay: '',
    NonTenderable: '',
    suspend: ''
  })

  const [formFieldsForEditing, setFormFieldsForEditing] = useState({
    origin: '',
    antwerp: '',
    barcelona: '',
    hamburg: '',
    houston: '',
    miami: '',
    newOrleans: '',
    newYork: '',
    virginia: '',
    compareToPreDay: ''
  })

  const [formValidationStatus, setFormValidationStatus] = useState({
    valid: false,
    compareToPreDay: false,
    NonTenderable: false,
    suspend: false
  })

  const [formErrors, setFormErrors] = useState({
    antwerp: false,
    barcelona: false,
    hamburg: false,
    houston: false,
    miami: false,
    newOrleans: false,
    newYork: false,
    virginia: false,
    compareToPreDay: false
  })

  const { origin: entityOrigin, valid, compareToPreDay: entityCompareToPreDay, NonTenderable, suspend } = editEntityFields
  const { origin: formOrigin, antwerp, barcelona, hamburg, houston, miami, newOrleans, newYork, virginia, compareToPreDay: formCompareToPreDay } = formFieldsForEditing

  useEffect(() => {
    if (rowDetails && isModalOpen) {
      const getFormattedDate = (rowData) => {
        const dateValue = rowData.date || rowData.Date

        return dateValue ? moment(dateValue, 'DD-MMM-YYYY').toDate() : null
      }

      const formattedDate = getFormattedDate(date)

      setCurrentDate(formattedDate)

      if (idMarket === 1) {
        setEditEntityFields({
          origin: rowDetails.origin,
          slNo: rowDetails.slNo,
          valid: rowDetails.valid,
          compareToPreDay: rowDetails.compareToPreDay,
          NonTenderable: rowDetails.NonTenderable,
          suspend: rowDetails.suspend
        })
      } else {
        setFormFieldsForEditing({
          slNo: rowDetails.slNo,
          origin: rowDetails.origin,
          antwerp: rowDetails.antwerp,
          barcelona: rowDetails.barcelona,
          hamburg: rowDetails.hamburg,
          houston: rowDetails.houston,
          miami: rowDetails.miami,
          newOrleans: rowDetails.newOrleans,
          newYork: rowDetails.newYork,
          virginia: rowDetails.virginia,
          compareToPreDay: rowDetails.compareToPreDay
        })
      }
    }
  }, [isModalOpen, tableRowData])

  const updateFormData = async () => {
    handleDismiss()

    try {
      let data
      let response

      if (idMarket === 1) {
        data = {
          date: date.date,
          filename: reportName,
          countryCertifiedData: editEntityFields
        }
      } else {
        data = {
          date: date.date,
          filename: reportName,
          countryCertifiedData: formFieldsForEditing
        }
      }

      if (idMarket === 1) {
        response = await apiAdapterCoffeeWeb.updateAllCountryCertifiedStockRobusta(data)
      } else {
        response = await apiAdapterCoffeeWeb.updateAllCountryCertifiedStockArabica(data)
      }

      const filteredData = response?.data.filter((data) => data?.date === date?.date)

      if (response.status === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_UPDATED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
        setCoffeeReportData(filteredData[0].countryCertifiedData)
        setIsModalOpen(false)
        setButtonDisabled(true)
        setIsFormEnabled({
          editMode: false,
          submitButton: false
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_UPDATE_REPORT_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: updateFormData,
        onHide: handleDismiss
      })
    }
  }

  const handleInputChange = (fieldName, value) => {
    setButtonDisabled(false)

    if (idMarket === 1) {
      setEditEntityFields((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    } else {
      setFormFieldsForEditing((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    }
  }

  const handleBlur = (fieldName) => {
    if (idMarket === 1) {
      setFormValidationStatus((previousState) => ({
        ...previousState,
        [fieldName]: true
      }))
    } else {
      setFormErrors((previousState) => ({
        ...previousState,
        [fieldName]: true
      }))
    }
  }

  const isFieldInvalid = (fieldName) => {
    let errorMessage

    if (idMarket === 1) {
      errorMessage = formValidationStatus[fieldName] && (editEntityFields[fieldName] === '' || editEntityFields[fieldName] === null)
    } else {
      errorMessage = formErrors[fieldName] && (formFieldsForEditing[fieldName] === '' || formFieldsForEditing[fieldName] === null)
    }

    return errorMessage
  }

  const getInputClassName = (fieldName) => {
    const className = isFieldInvalid(fieldName) ? 'error-fields' : ''

    return className
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })

    if (idMarket === 1) {
      setFormValidationStatus({
        valid: false,
        compareToPreDay: false,
        NonTenderable: false,
        suspend: false
      })
    } else {
      setFormErrors({
        antwerp: false,
        barcelona: false,
        hamburg: false,
        houston: false,
        miami: false,
        newOrleans: false,
        newYork: false,
        virginia: false,
        compareToPreDay: false
      })
    }
  }

  const isDisable = () => {
    const fieldsToCheck = [valid, entityCompareToPreDay, NonTenderable, suspend]
    const fieldValidationCheck = [formOrigin, antwerp, barcelona, hamburg, houston, miami, newOrleans, newYork, virginia, formCompareToPreDay]

    const fieldsToValidate = idMarket === 1 ? fieldsToCheck : fieldValidationCheck

    return fieldsToValidate.some((field) => field === '' || field === null)
  }

  const confirmResetForm = () => {
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })
    setShowDialog({ ...showDialog, showModal: false })

    if (idMarket === 1) {
      setEditEntityFields({
        origin: rowDetails.origin,
        slNo: rowDetails.slNo,
        valid: rowDetails.valid,
        compareToPreDay: rowDetails.compareToPreDay,
        NonTenderable: rowDetails.NonTenderable,
        suspend: rowDetails.suspend
      })
    } else {
      setFormFieldsForEditing({
        slNo: rowDetails.slNo,
        origin: rowDetails.origin,
        antwerp: rowDetails.antwerp,
        barcelona: rowDetails.barcelona,
        hamburg: rowDetails.hamburg,
        houston: rowDetails.houston,
        miami: rowDetails.miami,
        newOrleans: rowDetails.newOrleans,
        newYork: rowDetails.newYork,
        virginia: rowDetails.virginia,
        compareToPreDay: rowDetails.compareToPreDay
      })
    }
  }

  const showResetConfirmationDialog = () => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onConfirmation: confirmResetForm,
      onHide: handleDismiss
    })
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const enableFormEditing = () => {
    setIsFormEnabled({
      editMode: true,
      submitButton: true
    })
  }

  const renderFooter = () => (
    <div className="footer-action-container">
      {isFormEnabled.submitButton && (
        <>
          <ButtonComponent type={'cancel'} onClick={handleCloseModal} />
          <ButtonComponent type={'submit'} onClick={updateFormData} disabled={buttonDisabled || isDisable()} />
        </>
      )}
    </div>
  )

  return (
    <>
      <Dialog visible={isModalOpen} onHide={handleCloseModal} className="edit-country-report-modal" header={`${t('EDIT_ALL_COUNTRY_CERTIFIED_STOCK_REPORT')} - ${marketName}`} footer={renderFooter}>
        <div className="edit-form-layout">
          <div className="action-button">{isFormEnabled.editMode ? <ButtonComponent type={'reset'} onClick={showResetConfirmationDialog} /> : <ButtonComponent type={'edit'} onClick={enableFormEditing} />}</div>

          <div className="edit-form-section">
            <div className="input-section">
              <span className="p-float-label">
                <Calendar id="date" name="date" value={currentDate} showIcon={true} className="calendar-field" disabled={true} />
                <label htmlFor="date" className={'input-label'}>
                  {t('DATE')} <span className="required-text-field">*</span>
                </label>
              </span>
            </div>
            {idMarket === 1 && (
              <>
                <div className="input-section">
                  <span className="p-float-label">
                    <InputText id="origin" name="origin" value={entityOrigin} disabled={true} className="input-text" />
                    <label htmlFor="origin" className={'input-label'}>
                      {t('ORIGIN')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="valid"
                      name="valid"
                      value={valid}
                      onChange={(event) => {
                        handleInputChange('valid', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('valid')
                      }}
                      className={`input-text ${getInputClassName('valid')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="valid" className={`input-label ${getInputClassName('valid')}`}>
                      {t('VALID')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('valid') && <small className="error-message">{t('VALID_IS_REQUIRED')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="compareToPreDay"
                      name="compareToPreDay"
                      value={entityCompareToPreDay}
                      onChange={(event) => {
                        handleInputChange('compareToPreDay', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('compareToPreDay')
                      }}
                      className={`input-text ${getInputClassName('compareToPreDay')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="compareToPreDay" className={`input-label ${getInputClassName('compareToPreDay')}`}>
                      {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('compareToPreDay') && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="non-tenderable"
                      name="NonTenderable"
                      value={NonTenderable}
                      onChange={(event) => {
                        handleInputChange('NonTenderable', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('NonTenderable')
                      }}
                      className={`input-text ${getInputClassName('NonTenderable')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="non-tenderable" className={`input-label ${getInputClassName('NonTenderable')}`}>
                      {t('NON_TENDERABLE')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('NonTenderable') && <small className="error-message">{t('NON_TENDERABLE_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="suspend"
                      name="suspend"
                      value={suspend}
                      onChange={(event) => {
                        handleInputChange('suspend', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('suspend')
                      }}
                      className={`input-text ${getInputClassName('suspend')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="suspend" className={`input-label ${getInputClassName('suspend')}`}>
                      {t('SUSPENDED')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('suspend') && <small className="error-message">{t('SUSPENDED_ERROR_MESSAGE')}</small>}
                </div>
              </>
            )}

            {idMarket === 2 && (
              <>
                <div className="input-section">
                  <span className="p-float-label">
                    <InputText id="origin" name="origin" value={formOrigin} disabled={true} className="input-text" />
                    <label htmlFor="origin" className={'input-label'}>
                      {t('ORIGIN')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="antwerp"
                      name="antwerp"
                      value={antwerp}
                      onChange={(event) => {
                        handleInputChange('antwerp', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('antwerp')
                      }}
                      className={`input-text ${getInputClassName('antwerp')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="antwerp" className={`input-label ${getInputClassName('antwerp')}`}>
                      {t('ANTWERP')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('antwerp') && <small className="error-message">{t('ANTWERP_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="barcelona"
                      name="barcelona"
                      value={barcelona}
                      onChange={(event) => {
                        handleInputChange('barcelona', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('barcelona')
                      }}
                      className={`input-text ${getInputClassName('barcelona')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="barcelona" className={`input-label ${getInputClassName('barcelona')}`}>
                      {t('BARCELONA')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('barcelona') && <small className="error-message">{t('BARCELONA_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="hamburg"
                      name="hamburg"
                      value={hamburg}
                      onChange={(event) => {
                        handleInputChange('hamburg', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('hamburg')
                      }}
                      className={`input-text ${getInputClassName('hamburg')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="hamburg" className={`input-label ${getInputClassName('hamburg')}`}>
                      {t('HAMBURG')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('hamburg') && <small className="error-message">{t('HAMBURG_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="houston"
                      name="houston"
                      value={houston}
                      onChange={(event) => {
                        handleInputChange('houston', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('houston')
                      }}
                      className={`input-text ${getInputClassName('houston')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="houston" className={`input-label ${getInputClassName('houston')}`}>
                      {t('HOUSTON')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('houston') && <small className="error-message">{t('HOUSTON_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="miami"
                      name="miami"
                      value={miami}
                      onChange={(event) => {
                        handleInputChange('miami', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('miami')
                      }}
                      className={`input-text ${getInputClassName('miami')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="miami" className={`input-label ${getInputClassName('miami')}`}>
                      {t('MIAMI')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('miami') && <small className="error-message">{t('MIAMI_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="newOrleans"
                      name="newOrleans"
                      value={newOrleans}
                      onChange={(event) => {
                        handleInputChange('newOrleans', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('newOrleans')
                      }}
                      className={`input-text ${getInputClassName('newOrleans')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="newOrleans" className={`input-label ${getInputClassName('newOrleans')}`}>
                      {t('NEW_ORLEANS')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('newOrleans') && <small className="error-message">{t('NEW_ORLEANS_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="newYork"
                      name="newYork"
                      value={newYork}
                      onChange={(event) => {
                        handleInputChange('newYork', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('newYork')
                      }}
                      className={`input-text ${getInputClassName('newYork')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="newYork" className={`input-label ${getInputClassName('newYork')}`}>
                      {t('NEW_YORK')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('newYork') && <small className="error-message">{t('NEW_YORK_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="virginia"
                      name="virginia"
                      value={virginia}
                      onChange={(event) => {
                        handleInputChange('virginia', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('virginia')
                      }}
                      className={`input-text ${getInputClassName('virginia')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="virginia" className={`input-label ${getInputClassName('virginia')}`}>
                      {t('VIRGINIA')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('virginia') && <small className="error-message">{t('VIRGINIA_ERROR_MESSAGE')}</small>}
                </div>

                <div className="input-section">
                  <span className="p-float-label">
                    <InputText
                      id="compareToPreDay"
                      name="compareToPreDay"
                      value={formCompareToPreDay}
                      onChange={(event) => {
                        handleInputChange('compareToPreDay', event.target.value)
                      }}
                      onBlur={() => {
                        handleBlur('compareToPreDay')
                      }}
                      className={`input-text ${getInputClassName('compareToPreDay')}`}
                      disabled={!isFormEnabled.editMode}
                    />
                    <label htmlFor="compareToPreDay" className={`input-label ${getInputClassName('compareToPreDay')}`}>
                      {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {isFieldInvalid('compareToPreDay') && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>

      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </>
  )
}

export default EditAllCountryWiseStockReport
