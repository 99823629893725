import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Dropdown } from 'primereact/dropdown'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { ButtonComponent } from 'Components/UIComponents'
import { Button } from 'primereact/button'
import Validation from 'Utils/Validation'

const AddPortWiseStockRobusta = (props) => {
  const { portData, setValidateField, setFormData, formData, validateField, selectedDate, setSelectedDate, setSelectedPort, selectedPort, filteredOptionsCount, setFilteredOptionsCount, setChosenDate } = props

  const { t } = useTranslation()
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)

  useEffect(() => {
    const filled = formData.every((item) => item.valid && item.comparedToPreDay && item.nonTenderable && item.suspended && item.port)

    setIsFormFilled(filled)
  }, [formData])

  const handleInputChange = (index, field, value) => {
    const isValid = Validation.phoneCharValidation(value)

    const updatedFields = [...formData]

    if (isValid) {
      updatedFields[index][field] = value
    } else {
      updatedFields[index][field] = ''
    }

    setFormData(updatedFields)
  }

  const handleInputBlur = (fieldName) => {
    setValidateField((prev) => ({
      ...prev,
      [fieldName]: true
    }))
  }

  const handleAddFields = () => {
    setEditingIndex(null)

    setFormData([
      ...formData,
      {
        valid: '',
        comparedToPreDay: '',
        nonTenderable: '',
        suspended: '',
        port: ''
      }
    ])

    setValidateField((prevTouchedFields) => ({
      ...prevTouchedFields,
      valid: false,
      comparedToPreDay: false,
      nonTenderable: false,
      suspended: false,
      port: false
    }))
  }

  const getFilteredOptions = (index) => {
    const selectedOrigins = formData.map((field) => field.port)
    const filteredOptions = portData.filter((option) => !selectedOrigins.includes(option.port) || selectedPort[index]?.port === option.port)

    setFilteredOptionsCount(filteredOptions.length)

    return filteredOptions
  }

  const handleDropdownChange = (index, event) => {
    const value = event.value

    const updatedFields = [...formData]

    updatedFields[index] = {
      ...updatedFields[index],
      port: value.port
    }

    setSelectedPort((prevSelectedData) => ({
      ...prevSelectedData,
      [index]: value
    }))

    setFormData(updatedFields)
  }

  const handleDateChange = (event) => {
    const chosenDate = event.value
    const formattedDate = chosenDate ? moment(chosenDate).format('DD-MMM-YYYY') : ''

    setChosenDate(formattedDate)
    setSelectedDate(chosenDate)
  }

  const isFieldValid = () => {
    const disable = formData.every((item) => item.valid && item.comparedToPreDay && item.nonTenderable && item.suspended && item.port)

    return disable
  }

  const isFieldDisabled = (index) => index < formData.length - 1 && editingIndex !== index

  const handleEditClick = (index) => {
    setEditingIndex(index)
  }

  const handleClearRow = (index) => {
    const updatedFields = [...formData]
    const updatedSelectedData = { ...selectedPort }

    updatedFields.splice(index, 1)

    delete updatedSelectedData[index]

    const newSelectedData = Object.keys(updatedSelectedData).reduce((acc, key) => {
      const currentIndex = parseInt(key, 10)

      if (currentIndex > index) {
        acc[currentIndex - 1] = updatedSelectedData[currentIndex]
      } else {
        acc[currentIndex] = updatedSelectedData[currentIndex]
      }

      return acc
    }, {})

    setFormData(updatedFields)
    setSelectedPort(newSelectedData)
  }

  return (
    <>
      <div className="calendar-input">
        <div className="form-fields">
          <span className="p-float-label">
            <Calendar
              id="date"
              name="date"
              value={selectedDate}
              onChange={handleDateChange}
              onBlur={() => {
                handleInputBlur('date')
              }}
              showIcon
              className={`calendar-field ${validateField.date && !selectedDate ? 'error-field' : ''}`}
            />
            <label htmlFor="date" className={`field-label ${validateField.date && !selectedDate ? 'error-label' : ''}`}>
              {t('DATE')} <span className="required-text-field">*</span>
            </label>
          </span>
          {validateField.date && !selectedDate && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
        </div>
      </div>

      <div className="report-data-form">
        {formData.map((item, index) => (
          <div className="input-fields" key={index}>
            <div className="form-fields">
              <span className="p-float-label">
                <Dropdown
                  id="port"
                  name="port"
                  value={selectedPort[index] || null}
                  onChange={(event) => handleDropdownChange(index, event)}
                  options={getFilteredOptions(index)}
                  optionLabel="port"
                  className={`dropdown-field ${validateField.port && !selectedPort[index] ? 'error-field' : ''}`}
                  onBlur={() => {
                    handleInputBlur('port')
                  }}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="port" className={`field-label ${validateField.port && !selectedPort[index] ? 'error-label' : ''}`}>
                  {t('SELECT_PORT')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.port && !selectedPort[index] && <small className="error-message">{t('PORT_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="valid"
                  name="valid"
                  value={item.valid}
                  onChange={(event) => handleInputChange(index, 'valid', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('valid')
                  }}
                  className={`text-field ${validateField.valid && !item.valid ? 'error-field' : ''}`}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="valid" className={`field-label ${validateField.valid && !item.valid ? 'error-label' : ''}`}>
                  {t('VALID')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.valid && !item.valid && <small className="error-message">{t('VALID_IS_REQUIRED')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="comparedToPreDay"
                  name="comparedToPreDay"
                  value={item.comparedToPreDay}
                  onChange={(event) => handleInputChange(index, 'comparedToPreDay', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('comparedToPreDay')
                  }}
                  className={`text-field ${validateField.comparedToPreDay && !item.comparedToPreDay ? 'error-field' : ''}`}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="comparedToPreDay" className={`field-label ${validateField.comparedToPreDay && !item.comparedToPreDay ? 'error-label' : ''}`}>
                  {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.comparedToPreDay && !item.comparedToPreDay && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="nonTenderable"
                  name="nonTenderable"
                  value={item.nonTenderable}
                  onChange={(event) => handleInputChange(index, 'nonTenderable', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('nonTenderable')
                  }}
                  className={`text-field ${validateField.nonTenderable && !item.nonTenderable ? 'error-field' : ''}`}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="nonTenderable" className={`field-label ${validateField.nonTenderable && !item.nonTenderable ? 'error-label' : ''}`}>
                  {t('NON_TENDERABLE')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.nonTenderable && !item.nonTenderable && <small className="error-message">{t('NON_TENDERABLE_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="suspended"
                  name="suspended"
                  value={item.suspended}
                  onChange={(event) => handleInputChange(index, 'suspended', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('suspended')
                  }}
                  className={`text-field ${validateField.suspended && !item.suspended ? 'error-field' : ''}`}
                  disabled={isFieldDisabled(index)}
                />
                <label htmlFor="suspended" className={`field-label ${validateField.suspended && !item.suspended ? 'error-label' : ''}`}>
                  {t('SUSPENDED')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.suspended && !item.suspended && <small className="error-message">{t('SUSPENDED_ERROR_MESSAGE')}</small>}
            </div>

            {formData.length === index + 1 && filteredOptionsCount !== 1 && (
              <div className="button-content">
                <ButtonComponent type="custom" label={isFormFilled ? t('SAVE') : t('ADD')} icon={isFormFilled ? '' : 'pi pi-plus'} variant="action" onClick={handleAddFields} disabled={!isFieldValid()} />
              </div>
            )}

            {formData.length !== index + 1 && (
              <div className="button-content">
                <Button icon="pi pi-pencil" className="edit-button" onClick={() => handleEditClick(index)} />
                <Button icon="pi pi-trash" className="delete-button" onClick={() => handleClearRow(index)} />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default AddPortWiseStockRobusta
