import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import Loader from 'Components/Loader'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const SubscriptionMaster = ({ setLoading, mockSubscriptionListData }) => {
  const history = useHistory()
  const { t } = useTranslation()
  let paginationButtons = TABLE_CONST.paginationButtonCount
  let rowCount = TABLE_CONST.rowsCount

  const params = new URLSearchParams()
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [subscriptionMasterList, setSubscriptionMasterList] = useState([])
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {} })

  useEffect(() => {
    subscriptionMaster()

    // For testing purposes, use a mock subscription list
    if (mockSubscriptionListData) {
      setSubscriptionMasterList(mockSubscriptionListData)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
    rowCount = TABLE_CONST.mobileRowCount
  }

  const subscriptionMaster = async () => {
    setShowDialog({ ...showDialog, showModal: false })
    setLoading(true)
    setShowTableMessage(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAllSubscriptionTypes()

      setSubscriptionMasterList(response.data.returnLst)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        onRetry: () => subscriptionMaster(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const putSubscriptionMaster = async (item) => {
    setShowDialog({ ...showDialog, showModal: false })
    setLoading(true)
    try {
      if (!item.isActive) {
        await apiAdapterCoffeeWeb.activateSubscription({ toggleId: item.id })
      } else {
        await apiAdapterCoffeeWeb.deactivateSubscription({ toggleId: item.id })
      }
      const subscriptionModifiedData = subscriptionMasterList.map((element) => {
        if (element.id === item.id) {
          return {
            ...element,
            isActive: !item.isActive
          }
        }

        return element
      })

      setSubscriptionMasterList(subscriptionModifiedData)
    } catch {
      setShowDialog({
        showModal: true,
        modalType: 'error',
        message: 'FAILED_TO_ACTIVATE_INACTIVATE_LANGUAGE',
        onHide: handleDismiss,
        onRetry: () => putSubscriptionMaster()
      })
    } finally {
      setLoading(false)
    }
  }

  const confirmDeactivateToggle = (item) => {
    if (item.isActive) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'info',
        message: t('ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_SUBSCRIPTION'),
        onConfirmation: () => putSubscriptionMaster(item),
        onHide: handleDismiss
      })
    } else {
      putSubscriptionMaster(item)
    }
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleAddSubscription = () => {
    history.push({
      pathname: ROUTE_STRINGS.addSubscription
    })
  }

  const handleEditSubscription = (index) => {
    history.push({
      pathname: `${ROUTE_STRINGS.editSubscription}/${index.id}`,
      state: { index }
    })
  }

  const handleConfig = (rowData) => {
    params.set('tab', 'subscription_features')

    history.push({ pathname: `${ROUTE_STRINGS.subscriptionConfig}`, search: params.toString(), state: rowData })
  }

  const handleDismiss = () => {
    setShowDialog(false)
  }

  const toggleActivate = (id) => (
    <div className="toggle-switch">
      <InputSwitch checked={id.isActive} onChange={() => confirmDeactivateToggle(id)} />
    </div>
  )

  const editScreenRedirect = (index, rowData) => (
    <div className="action-container">
      <div>
        <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditSubscription(index)} data-testId="edit-button" />
      </div>
      <div>
        <Button type="button" icon="pi pi-cog" className="config-button" tooltip={t('CONFIG')} tooltipOptions={{ position: 'top' }} onClick={() => handleConfig(index, rowData)} data-testId="config-button" />
      </div>
    </div>
  )

  const columns = [
    { dataField: 'subscriptionCode', text: t('SUBSCRIPTION_CODE'), className: 'code-column' },
    { dataField: 'name', text: t('SUBSCRIPTION'), className: 'subscription-column' },
    { dataField: 'basePriceForIos', text: t('BASE_PRICE_FOR_IOS'), className: 'ios-column' },
    { dataField: 'basePrice', text: t('BASE_PRICE'), className: 'country-column' },
    { dataField: 'igst', text: t('GST'), className: 'currency-column' },
    { dataField: 'amount', text: t('AMOUNT'), className: 'amount-column' },
    { body: toggleActivate, text: t('IS_ENABLED'), className: 'toggle-column' },
    { body: editScreenRedirect, text: t('ACTIONS'), className: 'action-column' }
  ]

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="admin-subscription">
      <LayoutContainer showAddButton={true} title={'SUBSCRIPTION_TYPES'} breadCrumbsTitle={'subscriptionType'} handleAddButtonClick={handleAddSubscription}>
        <div className="subscription-table-container">
          <DataTable value={subscriptionMasterList} paginator={subscriptionMasterList.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage}>
            {columns.map((column, index) => (
              <Column key={index} field={column.dataField} header={column.text} className={column.className} body={column.body} />
            ))}
          </DataTable>
        </div>

        <DialogModal {...showDialog} onDismiss={handleDismiss} />
      </LayoutContainer>
    </div>
  )
}

export default Loader(SubscriptionMaster)
